import { useState, useEffect } from 'react';
import React from 'react';
import Sidebar_Progress from '../layouts/Operational_Sidebar_Progress.component';
import ManagerLoginHeader from '../operational-model/Manager/ManagerLoginHeader.component';
import moment from 'moment';
import swal from "sweetalert";
import { API_URL } from '../../constant/API_Settings';
import Loader from '../operational-model/Employee/Loader.component';
import PaginationComponent from '../layouts/PaginationComponent';
const token = localStorage.getItem("AUTH_TOKEN")
const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);
const Loosebarrelmachine = (props) => {
    const permissions = localStorage.getItem("PERMISSIONS")?.split(",");
    const [Loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [machineDetails, setMachineDetails] = useState([{ machine: '', quantity: '' }]);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [machineData, setMachineData] = useState([]);
    const [proDatabarrel, setProDatabarrel] = useState('');
    const [formValues, setFormValues] = useState({
        numberOfBarrel: '',numberOfBarrelliter:'',pckofliter:''
    });
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [dataList, setDataList] = useState([]);
    const [pagination, setPagination] = useState({});
    const [pages, setPages] = useState({
        current_page: 1,
        last_page: 0,
        total_pages: 0,
        pagination_item: [],
    });
    console.log(pages,"kkkkkkkkkkkkkkkkk")
    const getmachinestck = () => {
         const requestOptions = {
            method: 'GET',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
                "Accept": "application/json"
            },
        };
        fetch(API_URL + "/loosetransfer", requestOptions)
            .then(response => response.json())
            .then(responseData => {
                setProducts(responseData.data); 
            })
            .catch(e => {
                console.log(e);
            });
    };

 
 const [ids, setEditids] = useState({
    selectedProducts: "",
  
});
const getlist = (pages) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
            "Accept": "application/json"
        },
    };

    fetch(`${API_URL}/loosetransferdata?page=${pages}`, requestOptions)
        .then(response => response.json())
        .then(responseData => {
            setDataList(responseData.data);
            setPages({
                current_page: responseData.current_page || 1,
                last_page: responseData.last_page || 1,
                total_pages: responseData.total || 0,
                pagination_item: Array.from(
                    { length: responseData.last_page || 1 }, 
                    (_, i) => i + 1
                ),
            });

            setLoading(true);
        })
        .catch(e => {
            console.error('Error fetching data:', e);
            setLoading(false);
        });
};


    console.log(products,"kkkkkkkkkkkkk")

    const handleAddMachine = () => {
        if (machineDetails.length < machineData.length) {
            setMachineDetails([...machineDetails, { machine: '', quantity: '' }]);
        } else {
            swal("Limit Reached", "You can only add as many machines as available in the dropdown.", "warning");
        }
    };
    console.log(machineDetails,"machineDetails")
    const handleMachineChange = (index, field, value) => {
       
        if (field === 'quantity') {
            const selectedMachine = machineData.find(
                (machine) => machine.name_field === machineDetails[index].machine
            );
    
            if (selectedMachine) {
              
                const currentStock = parseFloat(selectedMachine.stock_field) || 0;
                const machineCapacity = parseFloat(selectedMachine.capacity_field) || 0;
                const enteredQuantity = parseFloat(value) || 0;
    
                const totalStock = currentStock + enteredQuantity;
   
                if (totalStock > machineCapacity) {
                   
                    swal("Exceeds Capacity", `The total stock (${totalStock}) cannot exceed the machine capacity of ${machineCapacity}.`, "warning");
                    return;
                }
            }
        }
    
        const updatedMachines = machineDetails.map((machine, i) =>
            i === index ? { ...machine, [field]: value } : machine
        );
        setMachineDetails(updatedMachines);
    };
    
    useEffect(() => {
        const newTotalQuantity = machineDetails.reduce(
            (total, machine) => total + (parseFloat(machine.quantity) || 0),
            0
        );
        setTotalQuantity(newTotalQuantity);
    }, [machineDetails]);
  
    const handleRemoveMachine = (index) => {
        setMachineDetails(machineDetails.filter((_, i) => i !== index));
    };

    const toggleModal = () => {
   
        if (isModalOpen) {
            setMachineDetails([{ machine: '', quantity: '' }]);
            setSelectedProduct('');
            setFormValues({
                numberOfBarrel: '',
                numberOfBarrelliter: '',
                pckofliter: ''
            });
            setTotalQuantity(0);
            setFormErrors('')
        }
        getmachinestck();
        setIsModalOpen(!isModalOpen);
    };
    useEffect(() => {
        getlist(pages.current_page);
      
    }, [pages.current_page]);

    const handlePagination = (number) => {
        setPages(prevState => ({
          ...prevState,
          current_page: number
        }));
        
        getlist(number); 
      };
      
    const toggleModal1 = () => {
   
        if (editisModalOpen) {
            setMachineDetails([{ machine: '', quantity: '' }]);
            setSelectedProduct('');
            setFormValues({
                numberOfBarrel: '',
                numberOfBarrelliter: '',
                pckofliter: ''
            });
            setTotalQuantity(0);
            setFormErrors('')
        }
        getmachinestck();
        seteditIsModalOpen(!editisModalOpen);
        setForceRender(forceRender + 1);
    };

    useEffect(() => {
        getlist(pages.current_page);
        if (selectedProduct) {
            const requestOptions = {
                method: 'GET',
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
                    "Accept": "application/json"
                },
            };

            fetch(`${API_URL}/loosetransfer?id=${selectedProduct}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.data) {
                        setMachineData(data.data.machine_data);
                        setProDatabarrel(data.data.product_data[0].number_of_barrel);
                        setFormValues(prevValues => ({
                            ...prevValues,
                            numberOfBarrel: data.data.product_data[0].number_of_barrel,
                            numberOfBarrelliter:data.data.product_data[0].number_of_barrel*data.data.product_data[0].packing_quantity_in_litre,
                            pckofliter:data.data.product_data[0].packing_quantity_in_litre,
                        }));
                    }
                })
                .catch(e => {
                    console.log("Error fetching product details:", e);
                });
        }
    }, [selectedProduct]);


let quotient = 0;
let remainder = 0;
let brlbalance = 0;
let available = 0;


let bar1 = formValues.numberOfBarrelliter || 0;  
let totq = totalQuantity || 0;                   
let litr = formValues.pckofliter || 1;           

if (bar1 >= 0 && totq >= 0 && litr > 0) {
    const remainingStck = bar1 - totq;

    if (remainingStck >= 0) {
        quotient = Math.floor(remainingStck / litr); 
        remainder = remainingStck % litr;  
        brlbalance = remainingStck - (quotient * litr);  
        available = (quotient * litr) + brlbalance; 
    }
}

console.log(quotient, remainder, brlbalance, available);
const [formErrors, setFormErrors] = useState({});
const validateForm = () => {
    let errors = {};

    if (!selectedProduct) {
        
        errors.selectedProduct = "Please select a product.";
    }

    if (machineDetails.some(machine => !machine.machine)) {
        errors.machine = "Please select a machine for each entry.";
    }

    if (machineDetails.some(machine => !machine.quantity || isNaN(machine.quantity))) {
        errors.quantity = "Please enter a valid quantity for each machine.";
    }

    setFormErrors(errors); 

    return Object.keys(errors).length === 0; 
};

const handleSave = () => {
    if (validateForm()) {

        const requestOptions = {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
                "Accept": "application/json"
            },
            body: JSON.stringify({
                machine: machineDetails,
                product_id: selectedProduct,
                barrelbalance: brlbalance,
                StockBalanceafterMachineStockTransfer: available,
                fullBarrelsRemaining: quotient,
            })
        };

        fetch(`${API_URL}/loosetransfer`, requestOptions)
        .then(response => response.json())
        .then(data => {
            if (data.status) {  
                swal("Success", data.message || "Data saved successfully!", "success");
                getlist(pages.current_page);
                toggleModal();
            } else {
                swal("Error", data.message || "An error occurred while saving data.", "error");
            }
        })
        .catch(e => {
            console.log("Error during the request:", e);
            swal("Error", "An error occurred while saving data.", "error");
        });
    
    }
};
const [forceRender, setForceRender] = useState(0);
// const handleEdit = (id) => {
//     const requestOptions = {
//         method: 'GET',
//         headers: {
//             "Access-Control-Allow-Origin": "*",
//             "Content-Type": "application/json",
//             "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
//             "Accept": "application/json"
//         },
//     };

  
//     fetch(`${API_URL}/loosetransferdata?id=${id}`, requestOptions)
//         .then(response => response.json())
//         .then(responseData => {
//             // Handle the response data here
//             console.log(responseData);
//             // You can populate your form or state with the data from responseData
//         })
//         .catch(e => {
//             console.log(e);
//         });
// };
const [editisModalOpen, seteditIsModalOpen] = useState(false);
const [editformValues, setEditFormValues] = useState({
    selectedProduct: "",
    numberOfBarrel: "",
    numberOfBarrelliter: "",
    machineDetails: [],
    remainder: "",
    quotient: "",
    available: ""
});
console.log(editformValues,"ooooo")
const [updateId, setUpdateId] = useState(null)
const [sta, setstatus] = useState(null)
const handleEdit = (id,status) => {
    getmachinestck();
    setUpdateId(id)
    setstatus(status)
    const requestOptions = {
        method: 'GET',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
            "Accept": "application/json"
        },
    };

    fetch(`${API_URL}/loosetransferdata?id=${id}`, requestOptions)
        .then(response => response.json())
        .then(responseData => {
            const { data } = responseData;

            if (data.length > 0) {
                const item = data[0];

                if (item.looseoil_id) {
                    const innerRequestOptions = {
                        method: 'GET',
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
                            "Accept": "application/json"
                        },
                    };

                    fetch(`${API_URL}/loosetransfer?id=${item.looseoil_id}`, innerRequestOptions)
                        .then(response => response.json())
                        .then(data => {
                            if (data.data) {
                                setMachineData(data.data.machine_data);

                                const productData = data.data.product_data[0];
                                
                                const updatedFormValues = {
                                    ...formValues,
                                    numberOfBarrel: productData.number_of_barrel,
                                    numberOfBarrelliter: productData.number_of_barrel * productData.packing_quantity_in_litre,
                                    pckofliter: productData.packing_quantity_in_litre,
                                };
                                
                                setFormValues(updatedFormValues);
                                
                                setEditids(prevValues => ({
                                    ...prevValues,
                                    selectedProducts: item.looseoil_id,
                                }));

                                setEditFormValues(prevValues => ({
                                    ...prevValues,
                                    selectedProduct: item.looseoil_id,
                                    numberOfBarrel: updatedFormValues.numberOfBarrel,
                                    numberOfBarrelliter: updatedFormValues.numberOfBarrelliter,
                                    pckofliter:productData.packing_quantity_in_litre,
                                    machineDetails: item.machine_details.map(machine => ({
                                        machine: machine.id,
                                        quantity: machine.quantity
                                    })),
                                    remainder: item.barrelbalance,
                                    quotient: item.fullBarrelsRemaining,
                                    available: item.stock_after_transfer
                                }));
                                
                                
                                seteditIsModalOpen(true);
                            }
                        })
                        .catch(e => console.log("Error fetching product details:", e));
                }
            }
        })
        .catch(e => {
            console.log("Error fetching loose transfer data:", e);
        });
};

const productchange=(e) => {

    if(editformValues.selectedProduct == e) {
       
        // handleEdit(ids.selectedProducts)
    }
    
    else if (editformValues.selectedProduct && (editformValues.selectedProduct != e)) {
        
       
        const requestOptions = {
            method: 'GET',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
                "Accept": "application/json"
            },
        };

        fetch(`${API_URL}/loosetransfer?id=${e}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.data) {
                    setMachineData(data.data.machine_data);
                    const productData = data.data.product_data[0];
console.log(productData,"productData")
                    setEditFormValues(prevValues => ({
                        ...prevValues,
                        numberOfBarrel: productData.number_of_barrel,
                        numberOfBarrelliter: productData.number_of_barrel * productData.packing_quantity_in_litre,
                        pckofliter: productData.packing_quantity_in_litre,
                        machineDetails: data.data.machine_data.map(item => ({
                            ...item,
                            machine:'',
                            quantity: ''     
                        })),   
                        totalQuantity: 0,       
                        quotient: 0,           
                        remainder: 0,          
                        available: 0           
                    }));
                    setProDatabarrel(productData.number_of_barrel);
                }
            })
            .catch(e => console.log("Error fetching product details:", e));
    }
}

const [stateUpdated, setStateUpdated] = useState(false);
const handleMachineChange1 = (index, field, value) => {
    const updatedMachineDetails = [...editformValues.machineDetails];
    updatedMachineDetails[index] = { ...updatedMachineDetails[index], [field]: value };

    let totalQuantity = updatedMachineDetails.reduce((acc, machine) => {
        return acc + (parseFloat(machine.quantity) || 0);  
    }, 0);

    let bar11 = editformValues.numberOfBarrelliter || 0;  
    let totqq = totalQuantity || 0;                    
    let litrr = editformValues.pckofliter || 1; 

    let quotient = editformValues.quotient || 0;  
    let remainder = editformValues.remainder || 0;  
    let available = editformValues.available || 0;

    console.log('bar11:', bar11, 'totqq:', totqq, 'litrr:', litrr);

    if (bar11 >= 0 && totqq >= 0 && litrr > 0) {
        const remainingStck1 = bar11 - totqq;
        console.log('remainingStck1:', remainingStck1); 

        if (remainingStck1 >= 0) {
            quotient = Math.floor(remainingStck1 / litrr);
            remainder = remainingStck1 % litrr; 


            const brlbalance1 = remainingStck1 - (quotient * litrr);  

            available = (quotient * litrr) + brlbalance1;

            console.log('available:', available);

            setEditFormValues((prevValues) => ({
                ...prevValues,
                machineDetails: updatedMachineDetails,
                totalQuantity,  
                quotient,       
                remainder,      
                available,      
            }));
        } else {
            console.log('Remaining stock is less than 0');
        }
    } else {
        console.log('Invalid values for bar11, totqq, or litrr');
    }

    setStateUpdated(true);
};
const handleSaveedit = () => {
  

        const requestOptions = {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
                "Accept": "application/json"
            },
            body: JSON.stringify({
                edit_id:updateId,
                machine:editformValues.machineDetails,
                product_id: editformValues.selectedProduct,
                barrelbalance: editformValues.remainder,
                StockBalanceafterMachineStockTransfer:editformValues.available,
                fullBarrelsRemaining: editformValues.quotient,
            })
        };

        fetch(`${API_URL}/loosetransfer`, requestOptions)
        .then(response => response.json())
        .then(data => {
            if (data.status) { 
                swal("Success", data.message || "Data saved successfully!", "success");
               getlist(pages.current_page);
                toggleModal1();
            } else {
                swal("Error", data.message || "An error occurred while saving data.", "error");
            }
        })
        .catch(e => {
            console.log("Error during the request:", e);
            swal("Error", "An error occurred while saving data.", "error");
        });
    
    
};


useEffect(() => {
    if (stateUpdated) {
        console.log('Updated editformValues:', editformValues);
        setStateUpdated(false);  
    }
}, [stateUpdated, editformValues]);

const handleAddMachine1= () => {
    // Check if the current number of machines is less than the total available machines
    if (editformValues.machineDetails.length < machineData.length) {
        setEditFormValues((prevValues) => ({
            ...prevValues,
            machineDetails: [
                ...prevValues.machineDetails,
                { machine: "", quantity: "" }
            ]
        }));
    } else {
        swal("Limit Reached", "You can only add as many machines as available in the dropdown.", "warning");

    }
};
const handleRemoveMachine1 = (indexToRemove) => {
    setEditFormValues((prevValues) => ({
        ...prevValues,
        machineDetails: prevValues.machineDetails.filter((_, index) => index !== indexToRemove)
    }));
};


const handleDelete = (id) => {
    swal({
        title: "Are you sure?",
        text: "Do you want to delete this item?",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
    }).then((willDelete) => {
        if (willDelete) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    id: id 
                })
            };

            fetch(`${API_URL}/deletetransfer`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.status) {
                        swal("Success", data.message || "Data deleted successfully!", "success");
                        getlist(pages.current_page); 
                    } else {
                        swal("Error", data.message || "An error occurred while deleting data.", "error");
                    }
                })
                .catch(e => {
                    console.log("Error during the request:", e);
                    swal("Error", "An error occurred while deleting data.", "error");
                });
        } else {
            swal("Cancelled", "Your item was not deleted", "info");
        }
    });
};
const [disabledProducts, setDisabledProducts] = useState({}); 

const handleapprove = (id) => {
    swal({
        title: "Are you sure?",
        text: "Do you want to approve this item?",
        icon: "warning",
        buttons: ["Cancel", "Approve"],
        dangerMode: false,
    }).then((willApprove) => {
        if (willApprove) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    id: id
                })
            };

            fetch(`${API_URL}/loosetransferdata`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.status) {
                        swal("Success", data.message || "Item approved successfully!", "success");
                        
                        setDisabledProducts(prevState => ({
                            ...prevState,
                            [id]: true 
                        }));
                        
                        getlist(pages.current_page); 
                    } else {
                        swal("Error", data.message || "An error occurred while approving the item.", "error");
                    }
                })
                .catch(e => {
                    console.log("Error during the request:", e);
                    swal("Error", "An error occurred while approving the item.", "error");
                });
        } else {
            swal("Cancelled", "Your item was not approved", "info");
        }
    });
};




    return (
        <>
            <div className="main-container page-wrap">
                <div className="d-md-flex">
                    <Sidebar_Progress props={props} progress={20} operation={1} />
                    <div className="content-area">
                        <ManagerLoginHeader props={props} page_title="" />
                        <div className="container pb-3 tab-view">
                            <div className="row mt-3 form-container">
                                <div className="col-5">
                                    <div className="row"></div>
                                </div>
                                {permissions.includes("Customer Attendant") ? (
                                    <div className="form-wrap mt-5">
                                        <div className="text-center">
                                            You don't have permission to access this page
                                        </div>
                                    </div>
                                ) : (
                                    
                                    <div className="form-wrap">
                                       {Loading ? getlist :
													
													<Loader />
												}
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="packedoil" role="tabpanel" aria-labelledby="packedoil-tab">
                                                    <div>
                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <h3 className="table-title">Oil Barrel Machine Stock</h3>
                                                                <h4>{moment(localStorage.getItem("op_date")).format("DD-MM-YYYY")}</h4>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <button
                                                                    className="btn btn-primary px-3 mb-md-0 mb-2"
                                                                    onClick={toggleModal}
                                                                >
                                                                    Oil Barrel Machine Stock
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="table-widget">
                                                            <table className="table border">
                                                            <thead>
    <tr>
      
        <th>Product Name</th>
        <th>Stock After Transfer</th>
        <th>Barrel Balance</th>
        <th>Machine Name</th>
        <th>Quantity</th>
        <th>Action</th>
    </tr>
</thead>
<tbody>
    {dataList.map((item) => (
        item.machine_details.map((machine, index) => (
            <tr key={`${item.id}-${machine.id}-${index}`}>
                {index === 0 && (
                    <>
                    
                        <td rowSpan={item.machine_details.length}>{item.product_full_name}</td>
                        <td rowSpan={item.machine_details.length}>{item.stock_after_transfer}</td>
                        <td rowSpan={item.machine_details.length}>{item.barrelbalance}</td>
                    </>
                )}
                <td>{machine.machine_name}</td>
                <td>{machine.quantity}</td>
                {/* {index === 0 && (
                    <td rowSpan={item.machine_details.length}>
                        <button onClick={() => handleEdit(item.id,item.status)}>
    {item.status === 3 ? (
        <i class="fa fa-eye" aria-hidden="true"></i> 
    ) : (
        <i class="fa fa-pencil" aria-hidden="true"></i> 
    )}
</button>


                        {item.status !== 3 && (
                            <button 
                                onClick={() => handleDelete(item.id)} 
                                style={{
                                    background: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                    fontSize: '20px',
                                    color: '#333',
                                }}
                                class="text-dark"
                            >
                                <i className="fa fa-trash-o me-2" aria-hidden="true"></i> 
                            </button>
                        )}

                        <button 
                            onClick={() => handleapprove(item.id)} 
                            disabled={item.status === 3}
                            style={{
                                background: 'transparent',
                                border: 'none',
                                cursor: item.status === 3 ? 'not-allowed' : 'pointer',
                                fontSize: '20px',
                                color: item.status === 3 ? 'green' : 'orange',
                            }}
                        >
                            <i 
                                className={`fa ${item.status === 3 ? 'fa-check text-success' : 'text-warning fa fa-check'}`} 
                                aria-hidden="true"
                            ></i>
                        </button>
                    </td>
                )} */}
                {index === 0 && (
  <td rowSpan={item.machine_details.length}>
    <button
      onClick={() => handleEdit(item.id, item.status)}
      style={{ background: 'none', border: 'none', padding: 0, marginRight: '4px' }}
    >
      {item.status === 3 ? (
        <i className="fa fa-eye" aria-hidden="true" title="View"></i>
      ) : (
        <i className="fa fa-pencil-square-o  pe-2" aria-hidden="true" title="edit"></i>
      )}
    </button>
    
    {item.status !== 3 && (
      <button
        onClick={() => handleDelete(item.id)}
        style={{
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          fontSize: '20px',
          color: '#333',
          padding: 0,
          marginRight: '4px',
        }}
        className="text-dark"
      >
        <i className="fa fa-trash-o pe-2" title="delete" aria-hidden="true"></i>
      </button>
    )}

    <button
      onClick={() => handleapprove(item.id)}
      disabled={item.status === 3}
      style={{
        background: 'none',
        border: 'none',
        cursor: item.status === 3 ? 'not-allowed' : 'pointer',
        fontSize: '20px',
        color: item.status === 3 ? 'green' : 'orange',
        padding: 0,
      }}
    >
      <i
        className={`fa ${item.status === 3 ? 'fa-check text-success' : 'fa fa-check text-warning'}`}
        aria-hidden="true"
      ></i>
    </button>
  </td>
)}

            </tr>
        ))
    ))}
</tbody>




                                                            </table>
                     
            
             <PaginationComp
                        pages={pages}
                        handlePagination={handlePagination}
                    />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <>
                    <div className="modal-overlay"></div>
                    <div className="modal-wrapper" aria-modal tabIndex={-1} role="dialog">
                        <div className="modal modalxl">
                            <div className="modal-body">
                                <div className="modal-header">
                                    <h5 className="modal-title">MACHINE STOCK</h5>
                                    <button type="button" className="close" onClick={toggleModal}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-content">
                                    <div className="form-group">
                                        <label>Product</label>
                                        <select
                                            id="products"
                                            className="form-control"
                                            value={selectedProduct}
                                            onChange={e => setSelectedProduct(e.target.value)}
                                        >
                                            <option value="">-- Select Product --</option>
                                            {products.map(product => (
                                                <option key={product.id} value={product.id}>
                                                    {product.product_name_with_unit}
                                                </option>
                                            ))}
                                         
                                              
                                        </select>
                                        {formErrors.selectedProduct && <div className="errorMsg">{formErrors.selectedProduct}</div>}
                                        
                                    </div>
                                    <div className="form-group">
                                        <label>Total Barrel Stock Available</label>
                                        <input
                                            type="number"
                                            id="totalBarrelStock"
                                            className="form-control"
                                            value={formValues.numberOfBarrel}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Total Barrel Stock Available in litre</label>
                                        <input
                                            type="number"
                                            id="totalBarrelStockliter"
                                            className="form-control"
                                            value={formValues.numberOfBarrelliter}
                                            disabled
                                        />
                                    </div>

                                    <div className="machine-details-section">
                                        <h5>Machine Details</h5>
                                        {machineDetails.map((machine, index) => (
                                            <div key={index} className="row align-items-center mb-2">
                                                <div className="col-md-5">
                                                <label>Machine Name</label>
                                                    <select
                                                        className="form-control"
                                                        value={machine.machine}
                                                        onChange={(e) => handleMachineChange(index, 'machine', e.target.value)}
                                                    >
                                                        <option value="">-- Select machine --</option>
                                                        {machineData.map(machine => (
                                                            <option key={machine.id} value={machine.id}>
                                                                {`${machine.name_field} - capacity: ${machine.capacity_field} - stock: ${machine.stock_field}`}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {formErrors.machine && <div className="errorMsg">{formErrors.machine}</div>}
                                                </div>
                                                <div className="col-md-5">
                                                <label>Moving Quantity</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Quantity in litre"
                                                        value={machine.quantity}
                                                        onChange={(e) => handleMachineChange(index, 'quantity', e.target.value)}
                                                    />
                                                     {formErrors.quantity && <div className="errorMsg">{formErrors.quantity}</div>}
                                                </div>
                                                <div className="col-md">
    <button
        className="btn btn-danger btn-round"
        onClick={() => handleRemoveMachine(index)}
    >
        -
    </button>
</div>

                                            </div>
                                        ))}
                                       <button className="btn btn-success mt-2" onClick={handleAddMachine}>Add Machine</button>

                                    </div>

                                    <div className="barrel-balance-section">
                                        <div className="form-group">
                                            <label>Barrel Balance in litre</label>
                                            <input type="text" className="form-control" value={remainder} readOnly />
                                        </div>
                                        <div className="form-group">
                                <label>Full Barrels Remaining</label>
                                <input type="text" className="form-control" value={quotient} readOnly />
                            </div>
                            <div className="form-group">
                                <label>Available Stock Balance after Machine Stock Transfer</label>
                                <input type="text" className="form-control" value={available} readOnly />
                            </div>
                            <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={toggleModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

{editisModalOpen && machineData.length > 0 && sta != 3 && (

    <>
        <div className="modal-overlay"></div>
        <div className="modal-wrapper" aria-modal tabIndex={-1} role="dialog">
            <div className="modal modalxl">
                <div className="modal-body">
                    <div className="modal-header">
                        <h5 className="modal-title">MACHINE STOCK</h5>
                        <button type="button" className="close" onClick={toggleModal1}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-content">
                        <div className="form-group">
                            <label>Product</label>
                            <select
                                id="products"
                                className="form-control"
                                value={editformValues.selectedProduct}
                                onChange={e => {
                                    setEditFormValues({ ...editformValues, selectedProduct: e.target.value });
                                    productchange(e.target.value); // Call the function with the selected value
                                }}                            >
                                <option value="">-- Select Product --</option>
                                {products.map(product => (
                                    <option key={product.id} value={product.id}>
                                        {product.product_name_with_unit}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Total Barrel Stock Available</label>
                            <input
                                type="number"
                                id="totalBarrelStock"
                                className="form-control"
                                value={editformValues.numberOfBarrel}
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label>Total Barrel Stock Available in litre</label>
                            <input
                                type="number"
                                id="totalBarrelStockliter"
                                className="form-control"
                                value={editformValues.numberOfBarrelliter}
                                disabled
                            />
                        </div>

                        <div className="machine-details-section">
                            <h5>Machine Details</h5>
                            {editformValues.machineDetails.map((machine, index) => (
                                
                                <div key={index} className="row align-items-center mb-2">
                                    <div className="col-md-5">
                                        <label>Machine Name</label>
                                        <select
    className="form-control"
    value={machine.machine}
    onChange={(e) => {
        console.log("Selected machine ID", e.target.value); // Log the selected machine ID
        handleMachineChange1(index, 'machine', e.target.value);
    }}
>
    <option value="">-- Select machine --</option>
    {machineData.map(machine => (
        <option key={machine.id} value={machine.id}>
            {`${machine.name_field} - capacity: ${machine.capacity_field} - stock: ${machine.stock_field}`}
        </option>
    ))}
</select>


                                    </div>
                                    <div className="col-md-5">
                                        <label>Moving Quantity</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Quantity in litre"
                                            value={machine.quantity}
                                            onChange={(e) => handleMachineChange1(index, 'quantity', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md">
                                        <button
                                            className="btn btn-danger btn-round"
                                            onClick={() => handleRemoveMachine1(index)}
                                        >
                                            -
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <button className="btn btn-success mt-2" onClick={handleAddMachine1}>Add Machine</button>
                        </div>

                        <div className="barrel-balance-section">
                            <div className="form-group">
                                <label>Barrel Balance in litre</label>
                                <input type="text" className="form-control" value={editformValues.remainder} readOnly />
                            </div>
                            <div className="form-group">
                                <label>Full Barrels Remaining</label>
                                <input type="text" className="form-control" value={editformValues.quotient} readOnly />
                            </div>
                            <div className="form-group">
                                <label>Available Stock Balance after Machine Stock Transfer</label>
                                <input type="text" className="form-control" value={editformValues.available} readOnly />
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button className="btn btn-secondary" onClick={toggleModal1}>Cancel</button>
                            <button className="btn btn-primary" onClick={handleSaveedit}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)}

{editisModalOpen && machineData.length > 0 && sta === 3 &&  (
 

<>
    <div className="modal-overlay"></div>
    <div className="modal-wrapper" aria-modal tabIndex={-1} role="dialog">
        <div className="modal modalxl">
            <div className="modal-body">
                <div className="modal-header">
                    <h5 className="modal-title">MACHINE STOCK</h5>
                    <button type="button" className="close" onClick={toggleModal1}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="form-group">
                        <label>Product</label>
                        <select
                        disabled
                            id="products"
                            className="form-control"
                            value={editformValues.selectedProduct}
                            onChange={e => setEditFormValues({ ...editformValues, selectedProduct: e.target.value })}
                        >
                            <option value="">-- Select Product --</option>
                            {products.map(product => (
                                <option key={product.id} value={product.id}>
                                    {product.product_name_with_unit}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Total Barrel Stock Available</label>
                        <input
                            type="number"
                            id="totalBarrelStock"
                            className="form-control"
                            value={editformValues.numberOfBarrel}
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <label>Total Barrel Stock Available in litre</label>
                        <input
                            type="number"
                            id="totalBarrelStockliter"
                            className="form-control"
                            value={editformValues.numberOfBarrelliter}
                            disabled
                        />
                    </div>

                    <div className="machine-details-section">
                        <h5>Machine Details</h5>
                        {editformValues.machineDetails.map((machine, index) => (
                            
                            <div key={index} className="row align-items-center mb-2">
                                <div className="col-md-5">
                                    <label>Machine Name</label>
                                    <select
                                    disabled
className="form-control"
value={machine.machine}
onChange={(e) => {
    console.log("Selected machine ID", e.target.value); 
    handleMachineChange1(index, 'machine', e.target.value);
}}
>
<option value="">-- Select machine --</option>
{machineData.map(machine => (
    <option key={machine.id} value={machine.id}>
        {`${machine.name_field} - capacity: ${machine.capacity_field} - stock: ${machine.stock_field}`}
    </option>
))}
</select>


                                </div>
                                <div className="col-md-5">
                                    <label>Moving Quantity</label>
                                    <input
                                        type="text"
                                        disabled
                                        className="form-control"
                                        placeholder="Enter Quantity in litre"
                                        value={machine.quantity}
                                        onChange={(e) => handleMachineChange1(index, 'quantity', e.target.value)}
                                    />
                                </div>
                                {/* <div className="col-md">
                                    <button
                                        className="btn btn-danger btn-round"
                                        onClick={() => handleRemoveMachine1(index)}
                                    >
                                        -
                                    </button>
                                </div> */}
                            </div>
                        ))}
                        {/* <button className="btn btn-success mt-2" onClick={handleAddMachine1}>Add Machine</button> */}
                    </div>

                    <div className="barrel-balance-section">
                        <div className="form-group">
                            <label>Barrel Balance in litre</label>
                            <input type="text" className="form-control" value={editformValues.remainder} readOnly />
                        </div>
                        <div className="form-group">
                            <label>Full Barrels Remaining</label>
                            <input type="text" className="form-control" value={editformValues.quotient} readOnly />
                        </div>
                        <div className="form-group">
                            <label>Available Stock Balance after Machine Stock Transfer</label>
                            <input type="text" className="form-control" value={editformValues.available} readOnly />
                        </div>
                    </div>

                   
                </div>
            </div>
        </div>
    </div>
</>
)}
        </>
    );
};

export default Loosebarrelmachine;
