import React, { useState, useMemo, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import swal from 'sweetalert';
import { Modal, ModalFooter } from 'react-bootstrap';
import Sidebar_Progress from '../layouts/Operational_Sidebar_Progress.component';
import ManagerLoginHeader from '../operational-model/Manager/ManagerLoginHeader.component';
import Loader from '../operational-model/Employee/Loader.component';
import customer_review from '../../img/customer-review.png';
import museum from '../../img/museum.svg';
import { API_URL } from '../../constant/API_Settings';

const GasCylinderPurchaseConfig = (props) => {
  const [Loading, setLoading] = useState(false);
  const [listGas, setListGas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const[inputValues,setInputValues] = useState([]);
  let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");
  const [permission, setPermission] = useState([])

  const handleChange = (e) => {
   
    setInputValues((prevValues) => {
      const newValues = {
        ...prevValues,
        [e.target.name]: e.target.value,
        
      };
      console.log(newValues,"mmm")
      
      if (e.target.name === "type") {
     
        // Reset the state only when 'type' field changes
        setFieldGroups([
          {
            product_id: '',
            quantity: '',
            pp_inc_gst_aft_disc: '',
            price_change: '',
            new_price: '',
            price_change_empty: 0,
            price_change_gas: 0,
            total_pp_inc_gst_aft_disc: '',
            gst: '',
            pp_per_piece_exc_gst: '',
            gstamount: '',
            cgst: '',
            sgst: '',
            mrp: '',
            gst_return: '',
          },
        ]);
      }
    
    
 
      if (newValues.fromValue === '0') {
        // alert(2)
        getVendor();
        
      }
      if(newValues.fromValue === "1"){
        // alert(3)
        getCustomer();
      }
      if(newValues.purchase_type ==="2"){
        // alert(4)
        getPayments();
        
      }
      if(newValues.purchase_type ==="3"){
        getbank();
      }

      
      return newValues;
    });
};


useEffect(() => {
    gaspurchase_list();
 }, [''])

const UseModal = () =>{
    
    const [isShowing,setIsShowing] =  useState(false);
    // const [isviewShowing,setisviewShowing] =  useState(false);
    function toggle() {
      gaspurchase_list();
      setIsShowing(!isShowing)
      // setisviewShowing(!isviewShowing)
      setInputValues('');
      getProducts();
      seterrors('');
      settotal('');
      setFieldGroups([{
        product_id: '',
        quantity: '',
        pp_inc_gst_aft_disc: '',
        price_change: '',
        new_price: '',
        price_change_empty: 0,
        price_change_gas: 0,
        total_pp_inc_gst_aft_disc: '',
        gst: '',
        pp_per_piece_exc_gst: '',
        gstamount: '',
        cgst: '',
        sgst: '',
        mrp: '',
        gst_return: '',
      }]);
   
    }
    return {
        isShowing,
        // isviewShowing,
        toggle,
      }
  
  }
const { isShowing, toggle } = UseModal();
// const { isviewShowing, toggle } = UseModal();
const [isviewShowing,setisviewShowing] =  useState(false);
const [vendor_data, setVendor] = useState([])
const [customer_data, setCustomer] = useState([])
const [productsdropdown,Setproductsdropdown] = useState([]);
const [dropdownDatas, setDropdownDatas] = useState([])
const [banks, setBanks] = useState([]);
const [total, settotal] = useState([]);
const [productprice, setproductprice] = useState([]);
const [list_gas, setListgas] = useState([]);
const [list_view, setListview] = useState([]);
const [fieldGroups, setFieldGroups] = useState([{ product_id: '',
                                                  quantity:'',
                                                  pp_inc_gst_aft_disc:'',
                                                  price_change:'',
                                                  new_price:'',
                                                  price_change_empty:0,
                                                  price_change_gas:0,
                                                  total_pp_inc_gst_aft_disc:'',
                                                  gst:'',
                                                  pp_per_piece_exc_gst:'',
                                                  gstamount:'',
                                                  cgst:'',
                                                  sgst:'',
                                                  mrp:'',
                                                  gst_return:'',
                                                }]);
                                                console.log(fieldGroups,"fieldGroupsfieldGroupsfieldGroups")
                                                
const [errors,seterrors] =useState({fromValue:"",
                                    vendor_id:"",
                                    customer_id:"",
                                    type:"",
                                    invoice_number:"",
                                    product_id:"",
                                    quantity:"",
                                    pp_inc_gst_aft_disc:"",
                                    total_pp_inc_gst_aft_disc:"",
                                    gst:"",
                                    pp_per_piece_exc_gst:"",
                                    gstamount:"",
                                    mrp:"",
                                    purchase_type:"",
                                    Digital_Type:"",
                                    bank:"",                                
});



const viewproduct = (id) => {
  gaspurchase_view(id)
  setisviewShowing(true)
}
const viewproductclose = (id) => {
  setisviewShowing(false)
  
}

function validation(){
    let errors=[];
    let formIsValid = true
    if ((!inputValues.fromValue)) {
         
        formIsValid = false;
        errors["fromValue"] = "Sales Form Type"
    } else {
        errors["fromValue"] = ""
    }

    if ((!inputValues.invoice_number)) {
         
      formIsValid = false;
      errors["invoice_number"] = "Invoice Number Is needed"
  } else {
      errors["invoice_number"] = ""
  }
    

    if (inputValues.fromValue == 0) {
        if (!inputValues.vendor_id) {
          formIsValid = false;
          errors["vendor_id"] = "Select Vendor";
        }
      } else if (inputValues.fromValue == 1) {
        if (!inputValues.customer_id) {
          formIsValid = false;
          errors["customer_id"] = "Select Customer";
        }
      }

      if (inputValues.purchase_type === "Select" || 
      !["0", "1", "2", "3"].includes(inputValues.purchase_type)) {
      formIsValid = false;
      errors["purchase_type"] = "Select Transaction Method";
  } else {
      errors["purchase_type"] = "";
  }

  

  if (inputValues.purchase_type == 2) {
    if (!inputValues.Digital_Type) {
        formIsValid = false;
        errors["Digital_Type"] = "Select Digital Payment";
    } else {
        errors["Digital_Type"] = "";
    }
  } else if (inputValues.purchase_type == 3) {
    if (!inputValues.bank) {
      formIsValid = false;
      errors["bank"] = "Select Bank";
    }
  }
    if (!Array.isArray(fieldGroups) || fieldGroups.length === 0) {
        formIsValid = false;
        errors["product_id"] = "At least one product is required";
    } else {
        errors["product_id"] = "";
        fieldGroups.forEach((product, index) => {
            console.log(product,"pppppppppppp")
            if (!product.product_id) {
               
                formIsValid = false;
                errors[`product_${index}_id`] = "Select Product";
            } else {
                errors[`product_${index}_id`] = "";
            }
            if (!product.quantity) {
                formIsValid = false;
                errors[`quantity${index}_id`] = "Enter Quantity";
            } else {
                errors[`quantity${index}_id`] = "";
            }
            // if (!product.pp_inc_gst_aft_disc) {
            //     formIsValid = false;
            //     errors[`pp_inc_gst_aft_disc${index}_id`] = "Enter Purchase Price";
            // } else {
            //     errors[`pp_inc_gst_aft_disc${index}_id`] = "";
            // }
            if (!product.gst) {
                formIsValid = false;
                errors[`gst${index}_id`] = "Please Enter Gst%";
            } else {
                errors[`gst${index}_id`] = "";
            }
            if (!product.mrp) {
                formIsValid = false;
                errors[`mrp${index}_id`] = "Please Enter Mrp ";
            } else {
                errors[`mrp${index}_id`] = "";
            }
            if (product.price_change == 1 && !product.new_price) {
                formIsValid = false;
                errors[`new_price${index}_id`] = "Please enter new purchase price";
            } else {
                errors[`new_price${index}_id`] = "";
            }
            if (product.price_change == 1 && inputValues.fromValue == 0 && !product.new_price &&!product.price_change_empty) {
              // alert(1)
                formIsValid = false;
                errors[`price_change_empty${index}_id`] = "Please enter the price";
            } else {
                errors[`price_change_empty${index}_id`] = "";
            }
            if (product.price_change == 1 &&  inputValues.fromValue == 0 && !product.new_price &&!product.price_change_gas) {
                formIsValid = false;
                errors[`price_change_gas${index}_id`] = "Please enter Gas price";
            } else {
                errors[`price_change_gas${index}_id`] = "";
            }

            if (
              product.price_change == 1 &&
              inputValues.fromValue == 0 &&
              product.new_price &&
              product.price_change_empty &&
              product.price_change_gas
            ) 
            // alert(2)
            {
             
              const total = parseFloat(product.price_change_empty) + parseFloat(product.price_change_gas);

              if (total !== parseFloat(product.new_price)) {
                // alert(1)
                console.log(total, "calculated_total");
                formIsValid = false;
                errors[`price_sum_mismatch${index}_id`] = "The sum of empty price and gas price must be equal to the new price.";
              }
            }

            // if (product.price_change == 1 && product.new_price && product.price_change_empty && product.price_change_gas) {
            //     const emptyPrice = parseFloat(product.price_change_empty);
            //     const gasPrice = parseFloat(product.price_change_gas);
            //     const newPrice = parseFloat(product.new_price);
                
            //     if (isNaN(emptyPrice) || isNaN(gasPrice)) {
            //         formIsValid = false;
            //         errors[`price_sum_mismatch${index}_id`] = "Empty price and Gas price must be valid numbers.";
            //     } else if (emptyPrice + gasPrice !== newPrice) {
            //         formIsValid = false;
            //         errors[`price_sum_mismatch${index}_id`] = "The sum of empty price and Gas price must be equal to the new price.";
            //     } else {
            //         errors[`price_sum_mismatch${index}_id`] = "";
            //     }
            // } else {
            //     // Handle the case where `product.price_change`, `product.new_price`, `product.price_change_empty`, or `product.price_change_gas` is missing
            //     if (product.price_change == 1 || product.new_price || !product.price_change_empty || !product.price_change_gas) {
            //         formIsValid = false;
            //         errors[`price_sum_mismatch${index}_id`] = "Empty and Gas price fields must be filled and equal to New Price.";
            //     }

            // }
            
       
    
            
        });
    }
  
    // alert(formIsValid)
    seterrors(errors)
    return formIsValid;

}

  const handleAdd = () => {
    setFieldGroups([...fieldGroups, { name: '',product_id: '',
    quantity:'',
    pp_inc_gst_aft_disc:'',
    price_change:'',
    new_price:'',
    price_change_empty:'',
    price_change_gas:'',
    total_pp_inc_gst_aft_disc:'',
    gst:'',
    pp_per_piece_exc_gst:'',
    gstamount:'',
    cgst:'',
    sgst:'',
    mrp:'',
    gst_return:'', }]);
  };

  const handleRemove = (index) => {
    const updatedFieldGroups = [...fieldGroups];
    updatedFieldGroups.splice(index, 1);
    setFieldGroups(updatedFieldGroups);
  };

  const handleChanges = (index, event) => {
    const updatedFieldGroups = [...fieldGroups];
    updatedFieldGroups[index][event.target.name] = event.target.value;
    setFieldGroups(updatedFieldGroups);
    if(updatedFieldGroups[index]["product_id"]){
        gasprice();
    }
    console.log(updatedFieldGroups,"inputs1")
  };
  console.log(inputValues,"inputs")

  function getVendor() {
    const requestOptions = {
        method: 'get',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"
        },
    };
    fetch(API_URL + "/get_vendors", requestOptions)
        .then(response => { return response.json(); })
        .then(responseData => {
            var datas = []

            responseData.forEach(elem => {
                datas.push([elem.id, elem.name])
            })

            setVendor(datas);
            return responseData;
        })
        .catch(e => {
            console.log(e);

        });
}

function getCustomer() {
    const requestOptions = {
        method: 'get',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"

        },
    };
    fetch(API_URL + "/get_customers", requestOptions)
        .then(response => { return response.json(); })
        .then(responseData => {
            var datas = []

            responseData.forEach(elem => {
                datas.push([elem.id, elem.name])
            })

            setCustomer(datas);

            return responseData;
        })
        .catch(e => {
            console.log(e);

        });
}

function getbank() {
    const requestOptions = {
        method: 'get',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"

        },
    };
    fetch(API_URL + "/bank_dd", requestOptions)
        .then(response => { return response.json(); })
        .then(responseData => {
            var datas = []

            responseData.forEach(elem => {
                datas.push([elem.id, elem.method_name])
            })

            setBanks(datas);

            return responseData;
        })
        .catch(e => {
            console.log(e);

        });
}
function getProducts() {
    const requestOptions = {
        method: 'get',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"

        },
    };
    fetch(API_URL + "/gas_products", requestOptions)
        .then(response => { return response.json(); })
        .then(responseData => {
            var PRODUCTdatas = []

            responseData.data.forEach(elem1 => {
                PRODUCTdatas.push([elem1.id, elem1.product_name])
            })

            Setproductsdropdown(PRODUCTdatas);
           
           

            return responseData;
        })
        .catch(e => {
            console.log(e);

        });
}

function getPayments() {
    const requestOptions = {
        method: 'get',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"
        },
    };
    fetch(API_URL + "/get_digital_payment", requestOptions)
        .then(response => { return response.json(); })
        .then(responseData => {
            var datas = []
            
            
            let newData = [];
             newData = responseData.map(elem => ({
                value: elem.id,
                label: elem.method_name,
            }));

            setDropdownDatas(dropdownDatas => [
                ...newData
            ]);

        })
        .catch(e => {
            console.log(e);

        });
}

// function gasprice() {
//     let products = fieldGroups; 
//     let type = inputValues.type;
  
//     products.forEach((product, index) => {
//         const product_id = product.product_id;
        
//         const requestOptions = {
//             method: 'POST',
//             headers: {
//                 "Access-Control-Allow-Origin": "*",
//                 "Content-Type": "application/json",
//                 "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
//                 "Accept": "application/json"
//             },
//             body: JSON.stringify({
//                 id: product_id,
//                 sales_type: type,
//                 index: index,
//             })
//         };

//         fetch(API_URL + "/gas_pp", requestOptions)
//             .then(response => response.json())
//             .then(responseData => {
//                 console.log(responseData.data.purchase_price_full,"ggggggg")
//                 if (responseData.status === true) {
                    
//                     if (type == 0 && product_id) {
                       
//                         setFieldGroups(prevFieldGroups => {
                            
//                             const updatedFieldGroups = [...prevFieldGroups];
//                             updatedFieldGroups[index]['pp_inc_gst_aft_disc'] = responseData?.data?.purchase_price_full;
                            
                           
//                             // setFieldGroups(updatedFieldGroups);
//                             return updatedFieldGroups;
//                         });
//                     }
//                     if (type == 1 && product_id) {
                       
//                         setFieldGroups(prevFieldGroups => {
                            
//                             const updatedFieldGroups = [...prevFieldGroups];
//                             updatedFieldGroups[index]['pp_inc_gst_aft_disc'] = responseData?.data?.purchase_price_exchange;
                            
                           
//                             // setFieldGroups(updatedFieldGroups);
//                             return updatedFieldGroups;
//                         });
//                     }
//                     if (type == 2 && product_id) {
                       
//                         setFieldGroups(prevFieldGroups => {
                            
//                             const updatedFieldGroups = [...prevFieldGroups];
//                             updatedFieldGroups[index]['pp_inc_gst_aft_disc'] = responseData?.data?.purchase_price_empty;
                            
                           
//                             // setFieldGroups(updatedFieldGroups);
//                             return updatedFieldGroups;
//                         });
//                     }
//                     setFieldGroups(prevFieldGroups => {
                        
//                         const updatedFieldGroups = [...prevFieldGroups];
//                         let nettotal = 0;
//                         updatedFieldGroups[index]['total_pp_inc_gst_aft_disc'] = updatedFieldGroups[index]['quantity'] * updatedFieldGroups[index]['pp_inc_gst_aft_disc'];
//                         updatedFieldGroups[index]['pp_per_piece_exc_gst'] = 
//                         Number((updatedFieldGroups[index]['pp_inc_gst_aft_disc'] / (1 + (updatedFieldGroups[index]['gst'] / 100))).toFixed(2));
//                         updatedFieldGroups[index]['gstamount'] =  Number((updatedFieldGroups[index]['pp_per_piece_exc_gst']) * ((updatedFieldGroups[index]['gst'])/100)).toFixed(2)
//                         updatedFieldGroups[index]['cgst'] = (updatedFieldGroups[index]['gstamount'] / 2).toFixed(2);
//                         updatedFieldGroups[index]['sgst']=(updatedFieldGroups[index]['gstamount']/2).toFixed(2);
                       
//                        let returnGst = ((updatedFieldGroups[index]['mrp'] * (100 / (100 + updatedFieldGroups[index]['gst']))).toFixed(2)) - (updatedFieldGroups[index]['pp_per_piece_exc_gst']).toFixed(2);
//                        returnGst = parseFloat(returnGst);
//                        updatedFieldGroups[index]['gst_return'] = (returnGst * (updatedFieldGroups[index]['gst'] / 100)).toFixed(2);

                       
//                     //    const updatedNetTotal = nettotal + updatedFieldGroups[index]['quantity'] * updatedFieldGroups[index]['pp_inc_gst_aft_disc'];
//                     //    settotal(updatedNetTotal);

//                     const quantity = parseFloat(updatedFieldGroups[index]['quantity']) || 0;
//                     const ppIncGstAftDisc = parseFloat(updatedFieldGroups[index]['pp_inc_gst_aft_disc']) || 0;
//                     const updatedNetTotal = nettotal + quantity * ppIncGstAftDisc;
//                      settotal(updatedNetTotal);
//                         console.log(nettotal,"nettotal")

//                         return updatedFieldGroups;
//                     });
                    
                    
//                 } 
//                 // else {
//                 //     console.error('Error in response data:', responseData.message);
//                 // }
//             })
//             .catch(e => {
//                 // console.error('Fetch error:', e);
//             });
//     });
// }



function gasprice() {
    const products = fieldGroups;
    const type = inputValues.type;

    // Create an array of promises for each fetch call
    const fetchPromises = products.map((product, index) => {
        const product_id = product.product_id;

        const requestOptions = {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
                "Accept": "application/json"
            },
            body: JSON.stringify({
                id: product_id,
                sales_type: type,
                index: index,
            })
        };

        return fetch(API_URL + "/gas_pp", requestOptions)
            .then(response => response.json())
            .then(responseData => {
                if (responseData.status === true) {
                    const updatedFieldGroups = [...fieldGroups];
                    console.log(updatedFieldGroups,"updatedFieldGroupsupdatedFieldGroups")
                    let purchasePrice;
                    let newpurchaseprice;

                    if (type == 0) {
                        purchasePrice = responseData?.data?.purchase_price_full;
                    } else if (type == 1) {
                        purchasePrice = responseData?.data?.purchase_price_exchange;
                    } else if (type == 2) {
                        purchasePrice = responseData?.data?.purchase_price_empty;
                    }
                    // if (updatedFieldGroups[index]['price_change'] === 1) {
                    //     updatedFieldGroups[index]['new_price'] = newpurchaseprice;
                    //     updatedFieldGroups[index]['pp_inc_gst_aft_disc'] = newpurchaseprice; 
                    // } else {
                        
                    //     updatedFieldGroups[index]['pp_inc_gst_aft_disc'] = updatedFieldGroups[index]['pp_inc_gst_aft_disc'];
                    // }
                    

                    if (updatedFieldGroups[index]['price_change'] == 1) {
                        if (product_id) {
                          
                        
                        
                            updatedFieldGroups[index]['total_pp_inc_gst_aft_disc'] = Number((updatedFieldGroups[index]['quantity'] * updatedFieldGroups[index]['new_price']).toFixed(2));
                        
                           
                            updatedFieldGroups[index]['pp_per_piece_exc_gst'] = Number((updatedFieldGroups[index]['new_price'] / (1 + (updatedFieldGroups[index]['gst'] / 100))).toFixed(2));
                        
                          
                            updatedFieldGroups[index]['gstamount'] = Number((updatedFieldGroups[index]['pp_per_piece_exc_gst'] * (updatedFieldGroups[index]['gst'] / 100)).toFixed(2));
                            
                            
                            updatedFieldGroups[index]['cgst'] = Number((updatedFieldGroups[index]['gstamount'] / 2).toFixed(2));
                            updatedFieldGroups[index]['sgst'] = Number((updatedFieldGroups[index]['gstamount'] / 2).toFixed(2));
                        
                        //   
                            // let returnGst = updatedFieldGroups[index]['mrp'] * (100 / (100 + updatedFieldGroups[index]['gst'])) - updatedFieldGroups[index]['pp_per_piece_exc_gst'].toFixed(2);
                            // updatedFieldGroups[index]['gst_return'] = Number(returnGst * (updatedFieldGroups[index]['gst'] / 100)).toFixed(2);
                            // console.log(returnGst,"returnGstreturnGst")
                            // let returnGst =((updatedFieldGroups[index]['mrp'] * (100/(100+updatedFieldGroups[index]['gst']))) - (updatedFieldGroups[index]['pp_per_piece_exc_gst']))
                            //   console.log(returnGst,"returnGstreturnGst")
                            //   console.log(updatedFieldGroups[index]['mrp'],"returnGstreturnGst")
                            //   console.log(updatedFieldGroups[index]['gst'],"returnGstreturnGst")
                            //   console.log(updatedFieldGroups[index]['pp_per_piece_exc_gst'],"returnGstreturnGst")

                            const returngst1 =Number(updatedFieldGroups[index]['mrp'])*(100/(100+(Number(updatedFieldGroups[index]['gst']))))
                            const returngst2=Number(updatedFieldGroups[index]['new_price'])*(100/(100+(Number(updatedFieldGroups[index]['gst']))))
                            const returngst3 =returngst1 -returngst2
                            updatedFieldGroups[index]['gst_return'] = parseFloat((returngst3 * (Number(updatedFieldGroups[index]['gst']) / 100)).toFixed(2));
                            // let returnGst1 =updatedFieldGroups[index]['mrp'] / (100 + (updatedFieldGroups[index]['gst'] / 100));
                            // let returnGst2 =returnGst1-updatedFieldGroups[index]['pp_per_piece_exc_gst']
                            // updatedFieldGroups[index]['gst_return']=returnGst2*(updatedFieldGroups[index]['gst'] / 100)
                            //     console.log(returnGst1,"returnGstreturnGst")
                            //   console.log(returnGst2,"returnGstreturnGst")
                              console.log(updatedFieldGroups[index]['gst_return'],"returnGstreturnGst")
                        }
                        
                        return {
                            updatedFieldGroups,
                            netTotal: Number((updatedFieldGroups[index]['quantity'] * updatedFieldGroups[index]['new_price']).toFixed(2))
                        };
                        
                    } else if (product_id) {
                    
                        updatedFieldGroups[index]['pp_inc_gst_aft_disc'] = purchasePrice;
                        updatedFieldGroups[index]['total_pp_inc_gst_aft_disc'] = Number((updatedFieldGroups[index]['quantity'] * purchasePrice).toFixed(2));
                        updatedFieldGroups[index]['pp_per_piece_exc_gst'] = Number((purchasePrice / (1 + (updatedFieldGroups[index]['gst'] / 100))).toFixed(2));
                        updatedFieldGroups[index]['gstamount'] = Number((updatedFieldGroups[index]['pp_per_piece_exc_gst'] * (updatedFieldGroups[index]['gst'] / 100)).toFixed(2));
                        updatedFieldGroups[index]['cgst'] = Number((updatedFieldGroups[index]['gstamount'] / 2).toFixed(2));
                        updatedFieldGroups[index]['sgst'] = Number((updatedFieldGroups[index]['gstamount'] / 2).toFixed(2));
                    
                        const returngst1 =Number(updatedFieldGroups[index]['mrp'])*(100/(100+(Number(updatedFieldGroups[index]['gst']))))
                        const returngst2=Number(updatedFieldGroups[index]['pp_inc_gst_aft_disc'])*(100/(100+(Number(updatedFieldGroups[index]['gst']))))
                        const returngst3 =returngst1 -returngst2
                        updatedFieldGroups[index]['gst_return'] = parseFloat((returngst3 * (Number(updatedFieldGroups[index]['gst']) / 100)).toFixed(2));
                    }
                    
                    return {
                        updatedFieldGroups,
                        netTotal: Number((updatedFieldGroups[index]['quantity'] * (updatedFieldGroups[index]['pp_inc_gst_aft_disc'] || updatedFieldGroups[index]['new_price'])).toFixed(2))
                    };
                    
                } else {
                    throw new Error('Response status is not true');
                }
            })
            .catch(e => {
                console.error('Fetch error:', e);
                return { updatedFieldGroups: fieldGroups, netTotal: 0 };
            });
    });

    Promise.all(fetchPromises)
        .then(results => {
            let totalNetTotal = 0;
            let updatedFieldGroups = [...fieldGroups];

            results.forEach(result => {
                updatedFieldGroups = result.updatedFieldGroups;
                totalNetTotal += result.netTotal;
            });


            setFieldGroups(updatedFieldGroups);
            settotal(totalNetTotal);
        })
        .catch(error => {
            console.error('Error processing fetch results:', error);
        });
}

console.log(fieldGroups,"fieldgroup")


console.log(total,"nettotal1")

function SendGasCylinderSave() {
    let requestBody = {
        id: inputValues.id,
        from:inputValues.fromValue,
        invoice_number: inputValues.invoice_number,
        invoice_date: document.getElementById("m_op_date").value,
        invoice_amount: total,
        note: inputValues.note,
        purchase_type: inputValues.purchase_type,
        net_amount: inputValues.net_amount,
        vendor_id : inputValues.fromValue==0? inputValues.vendor_id:'',
        sales_type : inputValues.type,
        cust_id : inputValues.fromValue==1 ? inputValues.customer_id : "",
        digital_payment_id: inputValues.purchase_type == 2 ? inputValues.Digital_Type : "",
        bank_id: inputValues.purchase_type == 3 ? inputValues.bank : "",
        product: fieldGroups
    };

    console.log('requestBody:', requestBody);

    const requestOptions = {
        method: 'POST',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
            "Accept": "application/json"
        },
        body: JSON.stringify(requestBody)
    };

    fetch(API_URL + "/cylinder_pc", requestOptions)
        .then(response => response.json())
        .then(responseData => {
          
           if (responseData.status === true) {
                swal("", responseData.message, "success");
                if (responseData.message) {
                    swal("Success", responseData.message, "success").then(() => {
                        swal("Success!", "Your changes have been saved and approved.");
                       
                    });
                }
                gaspurchase_list();
                toggle();         
                // ListNonfuelProductPurchase(pages.current_page);
            }
           
         // Check response data status and show appropriate alert
if (responseData.status === false) {

  swal({
      title: "",
      text: responseData.message.pp_inc_gst_aft_disc1,
      icon: "warning",  // Use "warning" for a warning alert
      buttons: true,
  });
} else {
  swal({
      title: "",
      text: responseData.message.pp_inc_gst_aft_disc1,
      icon: "success",  // Use "success" for a success alert
      buttons: true,
  });
}




            if(responseData.status === false)
             
              {
          
               swal("",responseData.message.quantity0,responseData.status? "warning":"warning")
              } 
        
 
            return responseData;
        })
        .catch(e => {
            console.log(e);
        });
}

function gaspurchase_list() {
    
    const requestOptions = {
        method: 'GET',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
            "Accept": "application/json"
        }
    };

    fetch(`${API_URL}/c_purchase_list`, requestOptions)
        .then(response => response.json())
        .then(responseData => {
            setListgas(responseData.cylinder_listing);
            setLoading(true);
            return responseData;      
        })
        .catch(e => {
            console.log(e);
        });
}

function gaspurchase_view(id) {
  // alert(id);
  

  const requestOptions = {
    method: 'get',
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
      "Accept": "application/json"
    },
  };



  fetch(`${API_URL}/c_purchase_list?id=${id}`, requestOptions) 
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(responseData => {
      setListview(responseData.cylinder_listing);
    })
    .catch(error => {
      console.error("There was an error with the fetch operation:", error);
      setLoading(false); 
    });
}




console.log(list_gas,"list_gaslist_gas")

   

  

  const handleDeleteGas = async (id) => {
    // Delete gas product with the given id
    // try {
    //   await axios.delete(`${API_URL}/path-to-delete-gas/${id}`);
    //   setListGas(listGas.filter((item) => item.id !== id));
    //   swal('Deleted!', 'Gas product has been deleted.', 'success');
    // } catch (error) {
    //   console.error('Error deleting gas product:', error);
    //   swal('Error!', 'An error occurred while deleting the gas product.', 'error');
    // }
  };

  const handleSubmit = () => {
    // SendGasCylinderSave();
    if (validation()) { 
        
        swal({
          title: "Do you want to save and approve?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willApprove) => {
          if (willApprove) {
            SendGasCylinderSave();
    
          } else {
            swal("Your changes are not saved!");
          }
        });
      }
   
  }  
  
console.log(fieldGroups,"fieldGroupsfieldGroups")

  

  return (
    <>
      <div className="main-container page-wrap">
        <div className="d-md-flex">
          <Sidebar_Progress props={props} progress={29} operation={1} />
          <div className="content-area">
            <ManagerLoginHeader />
            <div className="container pb-3 tab-view">
              <div className="row mt-3 form-container">
                <div className="row mt-3">
                  {/* Uncomment and customize if needed */}
                  {/* <div className="col-md-12 mb-3 px-4 ">
                    <Link to={backPage || "/operational-date"}>
                      <a href="#" className="float-end btn btn-gray">Go Back</a>
                    </Link>
                  </div> */}
                </div>
                <div className="form-wrap">
                {Loading ? gaspurchase_list : <Loader />}
                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="packedoil" role="tabpanel" aria-labelledby="packedoil-tab">
                        <div className="">
                          <div className="row mb-3">
                            <div className="col-4">
                              <img src={museum} width="25px" className="img-fluid" alt=".." />
                              <h3 className="table-title">Gas Cylinder Purchase</h3><h4>{moment(localStorage.getItem("m_op_date")).format("DD-MM-YYYY")}</h4>
                            </div>
                            <div className="col-4">
                              <button
                                className="btn btn-primary px-3 mb-md-0 mb-2"
                                onClick={toggle}
                              >
                                Add GAS PRODUCTS
                              </button>
                            </div>
                            <div className="col-md-4">
                              <div hidden className="input-group mb-3 search">
                                <span className="input-group-text" id="basic-addon1">
                                  <i className="fa fa-search" aria-hidden="true"></i>
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  value={''}
                                  onChange={''}
                                />
                                <button className="btn btn-primary bt-search btn-sm" onClick={''}>
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="table-widget" style={{ padding: '10px', backgroundColor: '#f8f9fa' }}>
    <div className="table border" style={{ border: '1px solid #dee2e6', borderRadius: '5px' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '14px' }}>
            <thead style={{ backgroundColor: '#343a40', color: '#ffffff' }}>
                <tr>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Date</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Product Name</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Quantity</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Gst%</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Purchase Price (exc-gst)</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Gst Return</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Mrp</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Transaction Type</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Customer</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Vendor</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Note</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Total</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Action</th>
                </tr>
            </thead>
            <tbody>
                {list_gas.map((pro, proindex) => (
                    <React.Fragment key={proindex}>
                        {pro.m.map((submeta, submetaindex) => (
                            <tr key={submetaindex}>
                                {submetaindex === 0 && (
                                    <td
                                        rowSpan={pro.m.length}
                                        style={{ padding: '8px', border: '1px solid #dee2e6' }}
                                    >
                                        {moment(pro.op_date).format("DD-MM-YYYY")}
                                    </td>
                                )}
                                <td style={{ padding: '8px', border: '1px solid #dee2e6' }}>{submeta.product_name}</td>
                                <td style={{ padding: '8px', border: '1px solid #dee2e6' }}>{submeta.quantity}</td>
                                <td style={{ padding: '8px', border: '1px solid #dee2e6' }}>{submeta.gst_percentage}</td>
                                <td style={{ padding: '8px', border: '1px solid #dee2e6' }}>{submeta.pp_per_piece_exc_gst}</td>
                                <td style={{ padding: '8px', border: '1px solid #dee2e6' }}>{submeta.return_gst}</td>
                                <td style={{ padding: '8px', border: '1px solid #dee2e6' }}>{submeta.mrp}</td>
                                {submetaindex === 0 && (
                                    <>
                                        <td
                                            rowSpan={pro.m.length}
                                            style={{ padding: '8px', border: '1px solid #dee2e6' }}
                                        >
                                            {pro.trans_type === 2 ? (
                                                pro.digital_payment
                                            ) : pro.trans_type === 3 ? (
                                                pro.bank_name
                                            ) : pro.trans_type === 1 ? (
                                                "Credit"
                                            ) : pro.trans_type === 0 ? (
                                                "Cash"
                                            ) : (
                                                pro.digital_payment === null ? "--" : pro.digital_payment
                                            )}
                                        </td>
                                        <td
                                            rowSpan={pro.m.length}
                                            style={{ padding: '8px', border: '1px solid #dee2e6' }}
                                        >
                                            {pro.cust_name === null ? "--" : pro.cust_name}
                                        </td>
                                        <td
                                            rowSpan={pro.m.length}
                                            style={{ padding: '8px', border: '1px solid #dee2e6' }}
                                        >
                                            {pro.name === null ? "--" : pro.name}
                                        </td>
                                        <td
                                            rowSpan={pro.m.length}
                                            style={{ padding: '8px', border: '1px solid #dee2e6' }}
                                        >
                                            {pro.note ? pro.note : null}
                                        </td>
                                        <td
                                            rowSpan={pro.m.length}
                                            style={{ padding: '8px', border: '1px solid #dee2e6' }}
                                        >
                                            {pro.total ? pro.total : null}
                                        </td>
                                        <td
                                            className="py-2"
                                            rowSpan={pro.m.length}
                                            style={{ padding: '8px', border: '1px solid #dee2e6' }}
                                        >
                                            <a
                                                href="#"
                                                className="text-dark"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title="Edit"
                                                onClick={() => viewproduct(pro.id)}
                                                style={{ textDecoration: 'none', color: '#000000' }}
                                            >
                                                <i className="fa fa-eye" aria-hidden="true"></i>
                                            </a>
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))}
                    </React.Fragment>
                ))}
            </tbody>
        </table>
    </div>
</div>



                        </div>
                      </div>
                    </div>
                  
                </div>
              </div>
              {/* Uncomment and customize if needed */}
              {/* {path == "/productsconfig/productsconfig" ? "" :
                <div className="text-end mt-4">
                  <button className="btn btn-primary px-3 mb-md-0 mb-2" onClick={''}>Continue</button>
                </div>
              } */}
            </div>
          </div>
        </div>
      </div>
      {/* Modal for adding gas products */}
      {isShowing && (
        <React.Fragment>

        <div class="container pb-3">
  
  
          <div class="row mb-3 justify-content-center">
            <div className="modal-overlay" />
            <div className="modal-wrapper  modal-style" aria-modal aria-hidden tabIndex={-1} role="dialog">
              <div className="modal  modalxxl col-12 ">
                <div class="modal-header mb-3">
                  <h5 class=" text-center" id="staticBackdropLabel">Gas Cylinder Purchase purchase</h5>
                  <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={toggle}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
  
  
                <div >

                  <div class="row mb-3 justify-content-center py-3">
  
                    <div class=" col-md-6 pe-0 ps-4">
                      <div class="row g-3 justify-content-center mb-2">
                        <div class="col-4">
                          <label class="col-form-label">From<sup class="star">*</sup></label>
                        </div>
                        <div class="col-6">
                          <div class="select-container">
                            <select id="" class="form-control " name="fromValue" value={inputValues.fromValue} onChange={handleChange}>
                              <option>Select</option>
                              <option value='0'>Vendor</option>
                              <option value='1'>Customer</option>
                            
                            </select>
                          </div> 
                          <div className="errorMsg">{errors.fromValue}</div>
                        </div>
                        <div class="col-2"></div>
                      </div>
                      {/* 
                       */}

{inputValues.fromValue === '0' && (
        <div className="row g-3 justify-content-center mb-2">
          <div className="col-4">
            <label className="col-form-label">Select Vendor<sup className="star">*</sup></label>
          </div>
          <div className="col-6">
            <div className="select-container">
              <select className="form-control" name="vendor_id" value={inputValues.vendor_id} onChange={handleChange}>
                <option>Select</option>
                {vendor_data.map((data) => (
                  <option key={data[0]} value={data[0]}>{data[1]}</option>
                ))}
              </select>
              
            </div>
            <div className="errorMsg">{errors.vendor_id}</div>
          </div>
          <div className="col-2"></div>
        </div>
      )}
      {inputValues.fromValue === '1' && (
        <div className="row g-3 justify-content-center mb-2">
          <div className="col-4">
            <label className="col-form-label">Select Customer<sup className="star">*</sup></label>
          </div>
          <div className="col-6">
            <div className="select-container">
              <select className="form-control" name="customer_id" value={inputValues.customer_id} onChange={handleChange}>
                <option>Select</option>
                {customer_data.map((data) => (
                  <option key={data[0]} value={data[0]}>{data[1]}</option>
                ))}
              </select>
            </div>
            <div className="errorMsg">{errors.customer_id}</div>
          </div>
          <div className="col-2"></div>
        </div>
      )}
      {inputValues.fromValue === '0' && (
        <div className="row g-3 justify-content-center mb-2">
          <div className="col-4">
            <label className="col-form-label">Select Type<sup className="star">*</sup></label>
          </div>
          <div className="col-6">
            <div className="select-container">
              <select className="form-control" name="type" onChange={handleChange}>
                <option>Select</option>
                <option value='0'>Full Cylinder</option>
                <option value='1'>Empty to Full Cylinder Exchange</option>
                {/* {vendor_data.map((data) => (
                  <option key={data[0]} value={data[0]}>{data[1]}</option>
                ))} */}
              </select>
            </div>
            {/* <div className="errorMsg">{errors.vendor_id}</div> */}
          </div>
          <div className="col-2"></div>
        </div>
      )}
       {inputValues.fromValue === '1' && (
        <div className="row g-3 justify-content-center mb-2">
          <div className="col-4">
            <label className="col-form-label">Select Type<sup className="star">*</sup></label>
          </div>
          <div className="col-6">
            <div className="select-container">
              <select className="form-control" name="type" value={inputValues.type}onChange={handleChange}>
                <option>Select</option>
                <option value='2'>Empty Cylinder</option>
                {/* {vendor_data.map((data) => (
                  <option key={data[0]} value={data[0]}>{data[1]}</option>
                ))} */}
              </select>
            </div>
            <div className="errorMsg">{errors.type}</div>
          </div>
          <div className="col-2"></div>
        </div>
      )}
                      <div class="row g-3 justify-content-center mb-2">
                        <div class="col-4">
                          <label class="col-form-label">Invoice Number<sup class="star">*</sup></label>
                        </div>
                        <div class="col-6">
                          <input type="text" class="form-control hide-arrow" name="invoice_number" value={inputValues.invoice_number} onChange={handleChange} ></input>
                          <div className="errorMsg">{errors.invoice_number}</div>
  
                        </div>
                        <div class="col-2"></div>
                      </div>
                      <div class="row g-3 justify-content-center mb-2">
                        <div class="col-4">
                          <label class="col-form-label">Invoice Date<sup class="star">*</sup></label>
                        </div>
                        <div class="col-6">
                          <input type="date" class="form-control " name="m_op_datem_op_date" id={"m_op_date"} value={inputValues.m_op_date || localStorage.getItem("m_op_date") || ""} min={''} max={''} onChange={handleChange} disabled></input>
                          {/* <div className="errorMsg">{errors.invoice_date}</div> */}
                        </div>
                        <div class="col-2"></div>
                      </div>
                      <>
                      {fieldGroups.map((group, idx) => (
        <div key={idx}>
{console.log(group,"groupgroup")}

{(inputValues.type === '1' || inputValues.type === '0' || inputValues.type === '2') && (
  <div className="row g-3 justify-content-center mb-2">
    <div className="col-4">
      <label className="col-form-label">Product Name<sup className="star">*</sup></label>
    </div>
    <div className="col-6">
      <div className="select-container">
        <select
          className="form-control rackids product"
          name="product_id"
          value={group.product_id || ''}
          onChange={(e) => handleChanges(idx, e)}
        >
          <option>Select</option>
          {productsdropdown.map((data) => (
            <option key={data[0]} value={data[0]}>{data[1]}</option>
          ))}
          {/* Render product options here */}
        </select>
      </div>
      <div className="errorMsg">{errors[`product_${idx}_id`]}</div>
    </div>
    <div className="col-2">
      <button className="btn-add me-2" onClick={handleAdd}>+</button>
      {fieldGroups.length > 1 && (
        <button className="btn-add" onClick={() => handleRemove(idx)}>-</button>
      )}
    </div>
  </div>
)}
 
          <div className="row g-3 justify-content-center mb-2">
            <div className="col-4">
              <label className="col-form-label">Number of Purchase Quantity<sup className="star">*</sup></label>
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control hide-arrow"
                name="quantity"
                value={group.quantity || ''}
                onChange={(e) => handleChanges(idx, e)}
              />
            <div className="errorMsg">{errors[`quantity${idx}_id`]}</div>

            </div>
            <div className="col-2"></div>
          </div>
          <div className="row g-3 justify-content-center mb-2">
            <div className="col-4">
              <label className="col-form-label">Purchase Price/piece inc gst after discount</label>
            </div>
            <div className="col-6">
              <input
              readOnly
                type="text"
                className="form-control hide-arrow"
                name="pp_inc_gst_aft_disc"
                value={group.pp_inc_gst_aft_disc || ''}
                onChange={(e) => handleChanges(idx, e)}
              />
            {/* <div className="errorMsg">{errors[`pp_inc_gst_aft_disc${idx}_id`]}</div> */}
            </div>
            <div className="col-2"></div>
          </div>
          {group.product_id ? (
  <div className="row g-3 justify-content-center mb-2">
    <div className="col-4">
      <label className="col-form-label">Do You Want To Change Price</label>
    </div>
    <div className="col-6">
      <select
        className="form-control"
        name="price_change"
        value={group.price_change || ''}
        onChange={(e) => handleChanges(idx, e)}
      >
        <option value="">Select</option>
        <option value="0">No</option>
        <option value="1">Yes</option>
      </select>
      {/* <div className="errorMsg">{errors[`pp_inc_gst_aft_disc${idx}_id`]}</div> */}
    </div>
    <div className="col-2"></div>
  </div>
) : null}

{group.price_change == '1' && (
  // alert(1),
        <div className="row g-3 justify-content-center mb-2">
          <div className="col-4">
            <label className="col-form-label">
              New Purchase Price<sup className="star">*</sup>
            </label>
          </div>
          <div className="col-6">
            <input
              type="ṭext"
              className="form-control"
              name="new_price"
              value={group.new_price || ''}
              onChange={(e) => handleChanges(idx, e)}
            />
            <div className="errorMsg">{errors[`new_price${idx}_id`]}</div>
          </div>
          <div className="col-2"></div>
        </div>
      )}
     {(group.price_change == '1' && (inputValues.fromValue == 0 )) ? (
      //  alert(2),
  <div className="row g-3 justify-content-center mb-2">
    <div className="col-6">
      <div className="row">
        <div className="col-6">
          <label className="col-form-label">
            Empty Cylinder Price<sup className="star">*</sup>
          </label>
        </div>
        <div className="col-6">
          <input
            type="text"
            className="form-control form-control-sm"
            name="price_change_empty"
            value={group.price_change_empty}
            // value={group.price_change_empty || ''}
            onChange={(e) => handleChanges(idx, e)}
          />
          <div className="errorMsg">{errors[`price_change_empty${idx}_id`]}</div>
          <div className="errorMsg">{errors[`price_sum_mismatch${idx}_id`]}</div>
        </div>
      </div>
    </div>
    <div className="col-4">
      <div className="row">
        <div className="col-4">
          <label className="col-form-label">
            Gas Price<sup className="star">*</sup>
          </label>
        </div>
        <div className="col-8">
          <input
            type="text"
            className="form-control form-control-sm"
            name="price_change_gas"
            value={group.price_change_gas || ''}
            onChange={(e) => handleChanges(idx, e)}
          />
          <div className="errorMsg">{errors[`price_change_gas${idx}_id`]}</div>
        </div>
      </div>
    </div>
  </div>
) : 

<div hidden className="row g-3 justify-content-center mb-2">

<div className="col-6">
  <div className="row">
    <div hidden className="col-6">
      <label className="col-form-label">
        Empty Cylinder Price<sup className="star">*</sup>
      </label>
    </div>
    <div className="col-6">
      <input
      hidden
        type="text"
        className="form-control form-control-sm"
        name="price_change_empty"
        value={group.price_change_empty || ''}
        onChange={(e) => handleChanges(idx, e)}
      />
    
    </div>
  </div>
</div>
<div hidden className="col-4">
  <div className="row">
    <div hidden className="col-4">
      <label className="col-form-label">
        Gas Price<sup className="star">*</sup>
      </label>
    </div>
    <div className="col-8">
      <input
      hidden
        type="text"
        className="form-control form-control-sm"
        name="price_change_gas"
        value={group.price_change_gas || ''}
        onChange={(e) => handleChanges(idx, e)}
      />
      <div className="errorMsg">{errors[`price_change_gas${idx}_id`]}</div>
    </div>
  </div>
</div>
</div>}




          <div className="row g-3 justify-content-center mb-2">
            <div className="col-4">
              <label className="col-form-label">Total Purchase Price inc gst aft disc</label>
            </div>
            <div className="col-6">
              <input
              readOnly
                type="text"
                className="form-control hide-arrow"
                name="total_pp_inc_gst_aft_disc"
                value={group.total_pp_inc_gst_aft_disc || ''}
                onChange={(e) => handleChanges(idx, e)}
              />
            </div>
            <div className="col-2"></div>
          </div>
          {/* Add other fields in a similar way */}
          <div className="row g-3 justify-content-center mb-2">
            <div className="col-4">
              <label className="col-form-label">GST%<sup className="star">*</sup></label>
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control hide-arrow"
                name="gst"
                value={group.gst || ''}
                onChange={(e) => handleChanges(idx, e)}
              />
            <div className="errorMsg">{errors[`gst${idx}_id`]}</div>
            </div>
            <div className="col-2"></div>
          </div>
          <div className="row g-3 justify-content-center mb-2">
            <div className="col-4">
              <label className="col-form-label">Purchase price/piece exc gst</label>
            </div>
            <div className="col-6">
              <input
              readOnly
                type="text"
                className="form-control hide-arrow"
                name="pp_per_piece_exc_gst"
                value={group.pp_per_piece_exc_gst || ''}
                onChange={(e) => handleChanges(idx, e)}
              />
              {/* Error message for GST */}
            </div>
            <div className="col-2"></div>
          </div>
          <div className="row g-3 justify-content-center mb-2">
            <div className="col-4">
              <label className="col-form-label">gstamount</label>
            </div>
            <div className="col-6">
              <input
              readOnly
                type="text"
                className="form-control hide-arrow"
                name="gstamount"
                value={group.gstamount || ''}
                onChange={(e) => handleChanges(idx, e)}
              />
              {/* Error message for GST */}
            </div>
            <div className="col-2"></div>
          </div>
          <div className="row g-3 justify-content-center mb-2">
            <div className="col-4">
              <label className="col-form-label">cgst</label>
            </div>
            <div className="col-6">
              <input
              readOnly
                type="text"
                className="form-control hide-arrow"
                name="cgst"
                value={group.cgst || ''}
                onChange={(e) => handleChanges(idx, e)}
              />
              {/* Error message for GST */}
            </div>
            <div className="col-2"></div>
          </div>
          <div className="row g-3 justify-content-center mb-2">
            <div className="col-4">
              <label className="col-form-label">sgst</label>
            </div>
            <div className="col-6">
              <input
              readOnly
                type="text"
                className="form-control hide-arrow"
                name="sgst"
                value={group.sgst || ''}
                onChange={(e) => handleChanges(idx, e)}
              />
              {/* Error message for GST */}
            </div>
            <div className="col-2"></div>
          </div>
          <div className="row g-3 justify-content-center mb-2">
            <div className="col-4">
              <label className="col-form-label">Mrp/piece<sup className="star">*</sup></label>
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control hide-arrow"
                name="mrp"
                value={group.mrp || ''}
                onChange={(e) => handleChanges(idx, e)}
              />
            <div className="errorMsg">{errors[`mrp${idx}_id`]}</div>
            </div>
            <div className="col-2"></div>
          </div>
          <div className="row g-3 justify-content-center mb-2">
            <div className="col-4">
              <label className="col-form-label">GST return</label>
            </div>
            <div className="col-6">
              <input
              readOnly
                type="text"
                className="form-control hide-arrow"
                name="gst_return"
                value={group.gst_return || ''}
                onChange={(e) => handleChanges(idx, e)}
              />
              {/* Error message for GST */}
            </div>
            <div className="col-2"></div>
          </div>
        </div>
        
      ))}
  </>
                        
                    </div>
                    <div class="col-md-6">
                      <div class="row g-3 justify-content-center mb-2">
                        <div class="col-5   ">
                          <label class="col-form-label ">Net Total</label>
                        </div>
                        <div class="col-6">
                          <input readOnly type="text" class="form-control hide-arrow" onKeyPress={''} name="total" id={"net_amount"}  value={total} onChange={handleChange}></input>
                        </div>
  
                      </div>
                      <div className="row g-3 justify-content-center mb-2">
  <div className="col-5">
    <label className="col-form-label">
      Transaction Type<sup className="star">*</sup>
    </label>
  </div>
  <div className="col-6">
    <div className="select-container">
      <select
        id="transaction-type"
        className="form-control"
        name="purchase_type"
        value={inputValues.purchase_type}
        onChange={handleChange}
      >
        <option>Select</option>
        <option value="0">Cash</option>
        <option value="1">Credit</option>
        <option value="2">Digital Payment</option>
        <option value="3">Bank</option>
      </select>
      <div className="errorMsg">{errors.purchase_type}</div>
    </div>
  </div>
</div>

                      {inputValues.purchase_type === '2' && (
        <div className="row g-3 justify-content-center mb-2">
          <div className="col-5">
            <label className="col-form-label">Select Digital Type<sup className="star">*</sup></label>
          </div>
          <div className="col-6">
            <div className="select-container">
              <select className="form-control" name="Digital_Type" onChange={handleChange}>
                <option>Select</option>
                {dropdownDatas.map(item => (
                <option key={item.value} value={item.value}>
                    {item.label}
                </option>
            ))}
              </select>
            </div>
            <div className="errorMsg">{errors.Digital_Type}</div>
          </div>
          <div className="col-2"></div>
        </div>
        )}
       {inputValues.purchase_type === '3' && (
        <div className="row g-3 justify-content-center mb-2">
          <div className="col-5">
            <label className="col-form-label">Select Bank<sup className="star">*</sup></label>
          </div>
          <div className="col-6">
            <div className="select-container">
              <select className="form-control" name="bank" onChange={handleChange}>
                <option>Select</option>
                {banks.map(([id, method_name]) => (
          <option key={id} value={id}>
            {method_name}
          </option>
        ))}
              </select>
            </div>
            <div className="errorMsg">{errors.bank}</div>
          </div>
          <div className="col-2"></div>
        </div>
      )}
    
                      <div class="row g-3 justify-content-center mb-2">
                        <div class="col-5">
                          <label class="col-form-label">Description</label>
                        </div>
                        <div class="col-6">
                          <textarea type="text" class="form-control " name="note" value={inputValues.note} onChange={handleChange}></textarea>
                        </div>
  
                      </div>
                      {/* <div class="row g-3 justify-content-center mb-2">
                        <div class="col-5">
                          <label class="col-form-label">Invoice Amount</label>
                        </div>
                        <div class="col-6">
                          <input type=" number" class="form-control" name="invoice_amount" id={"invoice_amount"} value={inputValues.invoice_amount}  onChange={handleChange}></input>
                        </div>
  
                      </div>
                   */}
  
                    </div>
                    <div class="modal-footer text-center">
  
                      <a href="#" class="btn btn-danger w-auto" onClick={toggle}>Cancel</a>
                       <a href="#" class="btn btn-primary  w-auto " onClick={handleSubmit}>Save&Approve</a> 
                    </div>
                  </div>
                  <div className="text-end">
                    {/* <Link to="/NonFuel-product-Selling-Price-Change"> <a href="/NonFuel-product-Selling-Price-Change" class=" text-danger text-end mx-3">Changing Selling Price</a></Link> */}
                    </div>
  
                </div>
              </div>
            </div>
          </div>
        </div>
  
  
  
      </React.Fragment>
      )}

{console.log(list_view,"vvvvvvvvvvvvvvvvvvvvvv")}

       Modal for adding gas products
       {isviewShowing && (
        <React.Fragment>

{/* {console.log(tank_data)} */}
    <div className="modal-overlay" />
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)' }}>
  <div className="modal modal-dialog-xl" style={{ maxWidth: '90vw', width: '90vw', maxHeight: '80vh', height: 'auto', overflow: 'auto' }}>
    <div className="modal-header mb-3">
      <h5 className="text-center" id="staticBackdropLabel">Gas Purchase Report</h5>
      <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={viewproductclose}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div className="modal-body" style={{ overflow: 'auto' }}>
      <div className="table-widget">
        <div className="table border">
          <div style={{ overflowX: 'auto' }}>
            <table style={{ minWidth: '100%' }}>
            {list_view.map((pro, proindex) => (
              <thead>
              
                  
                <tr>
                  <td>Date</td>
                  <th>{pro.type === 0 ? "Vendor" : "Customer"}</th>
                  <th>Note</th>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Purchase Price(inc-gst)</th>
                  <th>Gst%</th>
                  <th>Purchase Price(exc-gst)</th>
                  <th>Gst Return</th>
                  <th>Mrp</th>
                  <th>Transaction Type</th>
                  <th>Total</th>
                 
                </tr>
              
              </thead>
            ))}
              <tbody>
                {list_view.map((pro, proindex) => (
                 <React.Fragment key={proindex}>
                 {pro.m.map((submeta, submetaindex) => {
const totalPriceChange = (
  parseFloat(submeta.price_change_empty) +
  parseFloat(submeta.price_change_gas)
).toFixed(2);

console.log(submeta.price_change_empty, "vvvvvvvvvvvvvvvvvvvvvv1");
console.log(submeta.price_change_gas, "vvvvvvvvvvvvvvvvvvvvvv2");
console.log(totalPriceChange, "vvvvvvvvvvvvvvvvvvvvvv3");
               
                   return (
                     <tr key={submetaindex}>
                       {submetaindex === 0 && (
                         <td rowSpan={pro.m.length}>
                           {moment(pro.op_date).format("DD-MM-YYYY")}
                         </td>
                       )}
                       {submetaindex === 0 && (
                         <td rowSpan={pro.m.length}>
                           {pro.type === 0 ? pro.name : pro.cust_name}
                         </td>
                       )}
                       {submetaindex === 0 && (
                         <td rowSpan={pro.m.length}>
                           {pro.note ? pro.note : null}
                         </td>
                       )}
                       <td>{submeta.product_name}</td>
                       <td>{submeta.quantity}</td>
                       <td>{submeta.price_change == 1? totalPriceChange :submeta.pp_inc_gstanddisc}</td>
                       <td>{submeta.gst_percentage}</td>
                       <td>{submeta.pp_per_piece_exc_gst}</td>
                       <td>{submeta.return_gst}</td>
                       <td>{submeta.mrp}</td>
                       {submetaindex === 0 && (
                         <td rowSpan={pro.m.length}>
                           {pro.trans_type === 2 ? (
                             pro.digital_payment
                           ) : pro.trans_type === 3 ? (
                             pro.bank_name
                           ) : pro.trans_type === 1 ? (
                             "Credit"
                           ) : pro.trans_type === 0 ? (
                             "Cash"
                           ) : (
                             pro.digital_payment === null ? "--" : pro.digital_payment
                           )}
                         </td>
                       )}
                       {submetaindex === 0 && (
                         <td rowSpan={pro.m.length}>
                           {pro.total ? pro.total : null}
                         </td>
                       )}
                     </tr>
                   );
                 })}
               </React.Fragment>
               
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>





  </React.Fragment>
      )}
    </>
  );
};

export default GasCylinderPurchaseConfig;
