import React, { useState } from 'react';
import LoginHeader from "../../../layouts/LoginHeader.component";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import ManagerLoginHeader from '../ManagerLoginHeader.component';
import NonFuelProductModal from './NonFuelProductModal.component';
import Loader from '../../Employee/Loader.component';
import ManagerApprovalConfig from '../ManagerApprovalConfig.component'
import axios from 'axios';
import TabHeader from '../../Employee/TabHeader.component';
import SummarySheet from '../../Employee/SummarySheet.component';
import NonFuelEdit from './NonFuelEdit.component';
import { convertAmountToWords } from "../../../layouts/Operational_Sidebar_Progress.component";
import PaginationComponent from '../../../layouts/PaginationComponent';
import moment from 'moment';
import { useLocation } from 'react-router-dom';


// import Loader from '../Loader.component'

const token = localStorage.getItem("AUTH_TOKEN")

const FuelProductModal = (props) => ReactDOM.createPortal(
	<NonFuelProductModal  {...props} />, document.body
);
const NonFuelModal = (props) => ReactDOM.createPortal(
	<NonFuelEdit  {...props} />, document.body
);
const ApprovalModal = (props) => ReactDOM.createPortal(
	<ManagerApprovalConfig  {...props} />, document.body
);
const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);
const NonFuelProductConfigEmployee = (props) => {
	let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");
	const location = useLocation();
	const [approval_id, approvalId] = useState([])
	const [approval_status, approvalStatus] = useState([])
	const [summary, setSummary] = useState([])
	const [delete_id, deleteId] = useState([])
	const [inputValues, setInputValues] = useState({});
	const [fields,] = useState(['']);
	const history = useHistory()
	const [double, setDouble] = useState(true);
	const [list_data, setList] = useState([])
	const [rackedit, setrackedit] = useState([])
	const [errors, setErrors] = useState({})
	const [err, setErr] = useState([])
	const [er, setEr] = useState([])
	const [success, setSuccess] = useState([])
	const [value, setValue] = useState([])
	const [edit_list, setProductList] = useState([])
	const [permission, setPermission] = useState([])
	const [product_data, setProductData] = useState([''])
	const [quantity_data, setQuantity] = useState([])
	const [selling_price, setPrice] = useState({})
	const [selling_mode, setSellingMode] = useState({})
	const [mode, setMode] = useState([''])
	const [rack_details, setRack] = useState([])
	const [sales_details, setSales] = useState({})
	const [dropdownData, setDropdownData] = useState([])
	const [selected_options, setSelectedOptions] = useState([])
	// const [productData, setProduct] = useState([])
	const [search, setSearch] = useState('')
	const [startDate, setDate] = useState([])
	const [c_Date,c_setDate] = useState([])
	const [input, setInputs] = useState([])
	const [total, setTotal] = useState([])
	const [selected_products, setSelected] = useState([]);
	const [godown_error, setgodownErrr] = useState([]);
	const [quantity_error, setquantityErrr] = useState([]);
	const [mrp_error, setmrpErrr] = useState([]);
	const [product_error, setproductErrr] = useState([]);
	const [rack_error, setrackErrr] = useState([]);
	const[rack_error_single, setrackErrrSingle] = useState([]);
	const [product, setProduct] = useState([]);


	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})

	// const [chek_rk, 2] = useState(['']);
	const [forms, setForms] = useState([
		{
			product_data: "",
			rack_data: [{ rack_id: "", rack_q: "" }],
			sales_from_godown: "",
			mrp: "",
			// rack: [{ value: "", label: "" }],
			rack: [],
			chek_rk: [],
			quantity_error_data: [],
		},
	]);
	const [day_closing_date, setDayclosedate] = useState({ date: location?.state || "" })

	//loader
	const [Loading, setLoading] = useState(false);
	// const reload=()=>window.location.reload();
	//---On Load---//		
	useEffect(() => {
		check_url();
		is_day_closed();
	}, []);

	useEffect(() => {
		gettotal();
		selectedproducts();

	}, [forms]);

	function getSummerySheet() {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				op_emp_id: localStorage.getItem('op_emp_id'),
				emp_id: localStorage.getItem('emp_id'),
				op_date: localStorage.getItem('op_date'),
			}),

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/getSummerySheet", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setSummary(responseData)
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {

				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					getSummerySheet()
					ListPackedOil(pages.current_page)
				}
				else {
					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	function check_meta_date() {

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			}
		};
		fetch(`${API_URL}/check_meta_date`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				//	console.log(responseData.op_date)
				if (!responseData.op_date || !localStorage.getItem('emp_id')) {
					history.push("/nozzle-employee-list");
				}
				else {

					setInputs({ ...input, op_date: responseData.op_date })

				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	const handleInputs = (e) => {

	};
	const handleDate = (e) => {

		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,


		});


	};
	const handleInput = (e) => {

	};
	const handleFieldInput = (e) => {

	};

	const handle = (e) => {
		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,
		});

	}
	// function handleproductAdd() {
	// 	const values = [...fields];
	// 	values.push('');

	// 	setValue(values);


	// 	if (value.storage_id == "" || value.storage_id == undefined) {

	// 	}
	// 	else {
	// 		values.push('');
	// 	}
	// 	(values);
	// 	disable_rk();


	// }

	console.log(fields, "fiiiiiiiiiiiiiiii")


	function handleDelete(i) {
		// 	const values = [...product_data];

		// 	values.splice(i, 1);

		// 	(values);
		// 	setProductData(values)
		// 	setInputValues({ ...inputValues, nozzle_id: values })
	}
	function handleEditRemove(i) {
		// const values = [...product_data];

		// values.splice(i, 1);

		// (values);
		// setProductData(values)
		// setMode(values)
		// let product = []
	}





















	const handleChangeType = (e) => {
		if (e.target.name = "sales_type" || e.target.value == 0) {
			inputValues.customer_id = ""

		}
		if (e.target.value == 1) {
			getCustomer()
		}

		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value
		});


	};

	const handleChange = (e) => {
		console.log(e.target.value)
		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value
		});


	};

	function getCustomer() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/get_customers", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datas = []

				responseData.forEach(elem => {
					//    console.log(elem.method_name);
					datas.push([elem.id, elem.name])
				})

				setDropdownData(datas);

				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}

	function is_day_closed() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/is_day_closed", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				console.log(responseData,"kok")
				 c_setDate(responseData)
				

			})
			.catch(e => {
				console.log(e);

			});
	}

	const getProducts = () => {
		axios({
			url: API_URL + "/get_products",
			method: "get",
			headers: {
				"X-Id-Token": "abc123abc123",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			},
		})
			.then((response) => {
				let data = [];
				response?.data?.packed_fule?.map((item) => {
					data?.push({
						value: item?.id,
						label: item?.product_name,
					});
				});
				// const updatedForms = [...forms];
				// updatedForms[formIndex].products = data;
				// setForms(updatedForms);
				// console.log(updatedForms,"updatedForms")
				setProduct(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};


	function getStorage() {

		const requestOptions = {
			method: 'post',
			body: JSON.stringify({ id: localStorage.getItem("id") }),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};

		fetch(API_URL + "/get_rack_details", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setSuccess({ status: responseData.status })
				var datas = []

				responseData.forEach(elem => {
					datas.push([elem.id, elem.rack_name, elem.stock])
				})
				setRack(datas)
				var data = []

				setRack(datas)
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}

	function ListPackedOil(page) {
	//   const	emp_id=localStorage.getItem('op_emp_id')
		const requestOptions = {      //---employee listing---//
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};
		fetch(`${API_URL}/nonfuel_product_sales?&search_keyword=${search}&page=${page}&list_item=nf&employeesection=emp_nf&emp_id=${localStorage.getItem('emp_id')}&op_emp_id=${localStorage.getItem('op_emp_id')}&day_close_date=${day_closing_date?.date}` , requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (responseData.status == 3) {
					setPermission({ ...permission, status: responseData.status, message: responseData.message })
				}
				console.log(responseData.data, "datassss")
				setList(responseData.data.data)
				setDate(responseData.start_date)
				setTotal({
					...total,
					totalamount: responseData.totalamt,
				})
				setDouble(true)



				setpages({
					...pages,
					current_page: responseData.data.current_page,
					last_page: responseData.data.last_page,
					per_pages: responseData.data.per_page,
					total_pages: responseData.data.total,

				})
				setLoading(true);
				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}
	const UseEditModal = () => {

		const [isShow, setShowing] = useState(false);

		function toggleModal() {
			//edit 
			getCustomer();
			getProducts();
			if (isShow) {
                     
					 setForms([{
						product_data: "",
						rack_data: [{ rack_id: "", rack_q: "" }],
						sales_from_godown: "",
						mrp: "",
						// rack: [{ value: "", label: "" }],
						rack: [],
						chek_rk: [],
						quantity_error_data: [],
					}])
				 setInputValues([])
				 setmrpErrr([])
				 setgodownErrr([])
				 setDouble(true)
					// setErrors({})
					// setErr([])
					// setEr([])
					// setPrice({})
					// setSales({})
					// setRack([])
					//setSuccess({})
					//([''])
			}
			if (!isShow) {
				
				setDouble(true)
				setErrors({})

			}
			setShowing(!isShow);
		}

		return {
			isShow,
			toggleModal,
		}
	};
	const { isShow, toggleModal } = UseEditModal();

	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {

			if(c_Date == 1){
				swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false? "success":"warning")
			}else{
			getCustomer();
			getProducts();
			if (isShowing) {
				
				setInputValues([])
				setmrpErrr([])
				setgodownErrr([])
				// setSelectedOptions([])
				// setErrors({})
				// setErr([])
				// setEr([])
				// setPrice({})
				// setSales({})
				// setSuccess({})
				// ([''])
				// 2([''])
				setRack([])
				setForms([{
					product_data: "",
					rack_data: [{ rack_id: "", rack_q: "" }],
					sales_from_godown: "",
					mrp: "",
					// rack: [{ value: "", label: "" }],
					rack: [],
					chek_rk: [],
					quantity_error_data: [],
				}])
		// setErr("")
		// setEr("")
		// setErrors("")
		// setquantityErrr("")
		// setErrors("")
		// setmrpErrr([])
		// setproductErrr([])
		// setgodownErrr({})
			}
			if (!isShowing) {
				
				setDouble(true)
				setErrors({})

			}
			setIsShowing(!isShowing);
		}
	}
		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();

	const [isOpen, setIsOpen] = useState(false);
	function toggling() {
		setIsOpen(!isOpen);

	}
	//---Delete popup function---//






	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		event.preventDefault();
		// Process the form data, for example, you can log them or send them to an API
		console.log("Form data:", forms);
		console.log("datas", inputValues)

		setDouble(false);

		//  if (validateForm())
			submitdata()
		//sendPackedOil()
	}


	// ---handlesubmit function---//
	const handleeditSubmit = (event) => {
		event.preventDefault();
		// Process the form data, for example, you can log them or send them to an API
		console.log("Form datas:", forms);
		console.log("datas", inputValues)

		setDouble(false);

		//  if (validateForm())
			submitdata()
		
		//sendPackedOil()
	}


	function submitdata() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				Form_data: forms,
				// inputValues: inputValues,
				sale_type: "3",
				sales_type: inputValues.sales_type,
				customer_id: inputValues.customer_id,
				note: inputValues.note,
				id:inputValues.id?inputValues.id:"",
				date: document.getElementById("m_op_date").value,
				subtotal: inputValues.subtotal,
				dis_amount: inputValues.discount,
				// Number(document.getElementById("discount").value),
				total: inputValues.total,
				amt_aft_discound: inputValues.amt_aft_discound,
				roundoff: inputValues.roundoff,
				op_emp_id: localStorage.getItem('op_emp_id'),
				emp_id: localStorage.getItem('emp_id'),
			})
		};
		console.log(requestOptions,"requestOptionsrequestOptions")
		fetch(API_URL + "/nonfuel_product_gstsales", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				// setSuccess({
				// 	...success,
				// 	disabled: responseData.status === true ? true : false,
				// })
				let errors = []

				if (responseData.status == false) {
					 var rackerror01 = "okk";
					 rack_error["reackrror00"]=rackerror01;
					// setrackErrr(rack_error);

					//swal("Someting went wrong", "", "warning")
					console.log(responseData?.response?.sales_type?.[0])

					if(responseData?.response?.sales_type?.[0]){
						//swal("Sales type is required", "", "warning")
						 errors["sales_type"] = "Sales type is required"
					}else{
						
						 errors["sales_type"] = ""
					}

					if(responseData?.response?.customer_id?.[0]){
						//swal("Sales type is required", "", "warning")
						errors["customer_id"] = "Customer Name is required"
					}else{
						
						 errors["customer_id"] = ""
					}

					// godown_error[i]

					forms?.map((item,index) => {
						 
						 console.log(responseData?.message?.["rack_error" + index],"errorr")
						 
						 if(responseData?.message?.message?.["godown_error" + index]){
				godown_error[index]= responseData?.message?.message?.["godown_error" + index]
						 }else if(responseData?.message?.["godown_error" + index]){
				godown_error[index]= responseData?.message?.["godown_error" + index]
						 }else{
							godown_error[index]=""
						 }

			if(responseData?.message?.message?.["rack_error" + index]){
				
		for(var i = 0; i < (responseData?.message?.message?.["rack_error" + index]).length; i++)
		                    {
			console.log(responseData?.message?.message?.["rack_error" + index]?.[i],"opp")					
			if(responseData?.message?.message?.["rack_error" + index][i])
			{
				rack_error[index]=responseData?.message?.message?.["rack_error" + index][i];
				console.log(rack_error[index],"opp")
			}
			else{
				// rack_error[index] = ""
			}
							}
						 }else{
							rack_error[index] = ""
						 }
						 
			 if(responseData?.message?.["rack_error" + index]){
				
				for(var j = 0; j < (responseData?.message?.["rack_error" + index]).length; j++)
				{
					// console.log(responseData?.message?.["rack_error" + index][i],"msg")
					if(responseData?.message?.["rack_error" + index][j]){
						rack_error_single[index]=responseData?.message?.["rack_error" + index][j];
					}else{
						rack_error_single[index] = ""
					}
				
				}		 
			}else{
				rack_error_single[index] = ""
			}




						
						//  if(responseData?.message?.["godown_error" + index]){
							
						// 	godown_error[index]= responseData?.message?.["godown_error" + index]
						// }else{
						// 	godown_error[index]=""
						// }

						if(responseData?.message?.["product_data" + index]){
							product_error[index] = responseData?.message?.["product_data" + index]
						}else{
							product_error[index]=""
						}

						if(responseData?.message?.["rack_error" + index]){

						}



					})
					
					 setErrors(errors)
					 setgodownErrr(godown_error)
					 setproductErrr(product_error)
					 setrackErrr(rack_error)
					 setrackErrrSingle(rack_error_single)
				}
				if (responseData.status == "DENY") {
					//	toggle();
					swal("", responseData.message, responseData.status ? "warning" : "warning")
					//setDouble(true)
				}
				if (responseData.status == 2) {
					swal("", responseData.message, responseData.status ? "warning" : "warning")
					//setDouble(true)
				}
				if (responseData.status == 3) {
					swal("", responseData.message, "warning")
					//setDouble(true)
				}
				if (responseData.status == 5) {
					swal("", responseData.message)
					//setDouble(true)
				}
				if (responseData.status == 4) {
					swal("", responseData.message)
					//setDouble(true)
				}
				if (responseData.status == 6) {
					swal("", responseData.message)
					//setDouble(true)
				}
				if (responseData.status == 7) {
					swal("", responseData.message)
					//setDouble(true)
				}
				if (responseData.status == 10) {
					swal("", responseData.message)
					//	setDouble(true)
				}
				if (responseData.status == 11) {
					swal("", responseData.message)
					//setDouble(true)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					getSummerySheet()
					history.push("/nonfuel-product-direct-employee")
					
					if(inputValues.id){
						toggleModal()
					}else{
						toggle();
					}
					setInputValues(inputValues)

				

				setInputValues([]);
				setForms([{
					product_data: "",
					rack_data: [{ rack_id: "", rack_q: "" }],
					sales_from_godown: "",
					mrp: "",
					// rack: [{ value: "", label: "" }],
					rack: [],
					chek_rk: [],
					quantity_error_data: [],
				}]);
				setErrors([])
					 setgodownErrr([])
					 setproductErrr([])
					 setrackErrr([])
					 setrackErrrSingle([])
				
				ListPackedOil(pages.current_page);
				setErrors(errors)
				return responseData;

				}
				
			})
			.catch(e => {
				swal("Someting went wrong", "", "warning")
				console.log(e,"OOKK")
				//setDouble(true)
			});
	}
	


	const handleEditSubmit = (event) => {
		event.preventDefault();

		if (validateForm())
			sendEditNonFuelDirectSales()
	}


	//---Create EmployeeList---//

	function sendEditNonFuelDirectSales() {
		var values = [...mode]
		let quantity_array = []

		for (var i = 0; i < values.length; i++) {
			if (success.status != false) {
				if (document.getElementById("storage_id" + i).value == 'Select' || document.getElementById("storage_id" + i).value == undefined) {
					quantity_array = []
				}
				else {
					quantity_array.push({

						storage_id: document.getElementById("storage_id" + i).value,
						quantity: document.getElementById("quantity" + i).value,

					})

				}
			}
		}
		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				customer_id: inputValues.customer_id,
				dis_amount: inputValues.dis_amount,
				note: inputValues.note,
				date: document.getElementById("m_op_date").value,
				quantity_array: quantity_array,
				// price: inputValues.price,
				price: document.getElementById("price").value,
				sales_from_godown: inputValues.sales_from_godown,
				product_id: inputValues.product_id,
				sales_price: inputValues.sales_price,
				sales_type: inputValues.sales_type,
				sales_value: inputValues.sales_value,
				quantity: quantity_data,
				id: inputValues.id,
				sale_type: "3",
				// selling_mode : selling_mode,
				// op_date: inputValues.op_date,

				// emp_id: localStorage.getItem('emp_id'),

			})
		};
		fetch(API_URL + "/nonfuel_product_sales", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				let errors = []
				if (responseData.status == false) {
					errors["product"] = responseData.response.product_id
					errors["product_quantity"] = responseData.response.quantity
					errors["sales"] = responseData.response.sales_type
					errors["sales_value"] = responseData.response.sales_value

				}
				if (responseData.status == 2) {
					swal("", responseData.message, responseData.status ? "warning" : "warning")
				}
				if (responseData.status == 3) {
					swal("", responseData.message, "warning")
				}
				if (responseData.status == 5) {
					swal("", responseData.message)
				}
				if (responseData.status == 4) {
					swal("", responseData.message)
				}
				if (responseData.status == 6) {
					swal("", responseData.message)
				}
				if (responseData.status == 7) {
					swal("", responseData.message)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/nonfuel-product-direct")

					setErrors([''])
					setSuccess([])
					setInputValues([])
					// reload();
					toggleModal();
					getSummerySheet()
					ListPackedOil(pages.current_page);
					// useEffect

				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {
				console.log(e)
			});
	}
	const handleEdit = (id, status) => {

		if(c_Date == 1 && status < 3){
			swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false ?"success":"warning")
		}else{
		console.log(id, "d")
		setProductList({
			id: id,
			status: status
		})
		edit_PackedOil(id, status)
	}
	};
	//---Edit employee list---//	
	function edit_PackedOil(id, status) {
		setErrors([])

		const requestOptions = {
			method: 'get',
			data: {
				id: id,

			},
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/nonfuel_product_sales/" + id + "/edit", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setInputValues({
					...inputValues,
					id: responseData[0].id,
					discount: responseData[0].discount,
					op_date: responseData[0].op_date,
					sales_type: responseData[0].customer_id ? 1 : 0,
					customer_id: responseData[0].customer_id,
					note: responseData[0].note

				})

				console.log(responseData, "...")
		let array_data = responseData?.[0]?.metedata;
        let forms_array = [];
        array_data?.map((item,index) => {
          forms_array.push({
            product_data: item?.product_id,
            rack_data: item?.rack_quantitys?.map((item) => ({
              rack_id: item?.rack_id,
              rack_q: item?.rack_q,
            })),
            rack: [
              {
                value: "",
                label: "",
				// id:71,rack_name:"a",stock:41
              },
            ],
            sales_from_godown: item?.from_godown,
            mrp: item?.selling_price,
			chek_rk:item?.rack_quantitys?.map((item)=> parseInt(item?.rack_id))
           
          });
		//   editracks(index, item?.product_id)
           getRacks(index,item?.product_id,forms_array)
		

		// console.log(d,"ddrack")
		  
        });
	
console.log(forms_array,"noo")
        setForms(forms_array);
				

				// setForms(forms)


				console.log(responseData, "opdataaa")
				

				//setInputValues({discount:responseData[0].discount})

				console.log(inputValues.discount, "opdataa")



				toggleModal()
				return responseData;
			})
			.catch(e => {

				console.log(e)
			});
	}
	console.log(inputValues, "opdata")
	console.log(forms, "opdate1")

	//---Delete EmployeeList---//	
	function delete_packedOil(id) {
		if (c_Date == 1) { 
			swal("", "Your Current Operational Date has already been Day-Closed, Please change Operational Date", false ? "success" : "warning")
		}else{

		deleteId(id)

		toggling()
	}
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListPackedOil(number)
	}
	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	function handlePrice() {
		// setSales(sales_details.sales_price)
		//  setInputValues(inputValues.quantity)

		// console.log(sales_details.sales_price)
		// 			if(inputValues.quantity!=undefined || sales_details.sales_price!=undefined)	 
		// 			{
		// 			var values = inputValues.quantity*sales_details.sales_price?inputValues.quantity*sales_details.sales_price:0
		// 	  console.log(values)
		// 			} 
		// 			setSales({...sales_details,
		// 				sales_value:values})

	}
	function deletePackedOil() {
		setDouble(false)

		const requestOptions = {
			method: 'DELETE',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify(delete_id)
		};
		fetch(API_URL + "/nonfuel_product_gstsales/" + delete_id, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				getSummerySheet()
				ListPackedOil(pages.current_page);
				toggling()
				setDouble(true)
				return responseData;


			})

			.catch(err => {
				swal("Something went wrong", "", "warning")
				console.log(err)

			})
	}
	/*****approval******/
	const UseApproveModal = () => {
		const [isApprove, setApprove] = useState(false);
		function toggleModal2() {
			setApprove(!isApprove);
		}
		return {
			isApprove,
			toggleModal2,
		}
	};
	const { isApprove, toggleModal2 } = UseApproveModal();
	function handleManagerAproval(id, status) {
		if (c_Date == 1) { 
			swal("", "Your Current Operational Date has already been Day-Closed, Please change Operational Date", false ? "success" : "warning")
		}else{

		approvalId(id)
		approvalStatus(status)
		toggleModal2()
	}
	}
	function handleManagerAproval(id, status) {

		if (c_Date == 1) { 
			swal("", "Your Current Operational Date has already been Day-Closed, Please change Operational Date", false ? "success" : "warning")
		}else{

		approvalId(id)
		approvalStatus(status)
		toggleModal2()
	}
	}
	const handleSelectProductChange = (formIndex, value) => {
		console.log("test")
		const updatedForms = [...forms];
		updatedForms[formIndex].product_data = value;
		setForms(updatedForms);


	};

	const getRack = (formIndex, selectedProductId) => {
		//  for fetching products mrp
		fetchMRP(selectedProductId, formIndex);

		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token + "",
			},
			body: JSON.stringify({
				id: selectedProductId,
			}),
		};
		fetch(API_URL + `/get_rack_details`, requestOptions)
			.then((response) => {
				// setSuccess({ status: responseData.status })
				if (!response.ok) {
					throw Error("Could not fetch the data for that resource");
				}
				return response.json();
			})
			.then((responseData) => {

				if (responseData?.status == false) {

					setRack([]);
					const updatedForms = [...forms];
					updatedForms[formIndex].rack = [];
					setForms(updatedForms);
				} else {
					
					let data = [];
					responseData?.map((item) => {
						data?.push({
							value: item?.id,
							label: item?.rack_name,
						});
					});
				    const updatedForms = [...forms];
					//const updatedForms = [];
					// console.log(responseData,"rackk")
					updatedForms[formIndex].rack = responseData;
					setForms(updatedForms);
				}


				return responseData;
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const getRacks = (formIndex, selectedProductId,forms_array) => {

		//  for fetching products mrp
		//fetchMRP(selectedProductId, formIndex);

		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token + "",
			},
			body: JSON.stringify({
				id: selectedProductId,
			}),
		};
		fetch(API_URL + `/get_rack_details`, requestOptions)
			.then((response) => {
				// setSuccess({ status: responseData.status })
				if (!response.ok) {
					throw Error("Could not fetch the data for that resource");
				}
				return response.json();
			})
			.then((responseData) => {

				if (responseData?.status == false) {

					setRack([]);
					const updatedForms = [...forms_array];
					updatedForms[formIndex].rack = [];
					//setForms(updatedForms);
				} else {
					
					let data = [];
					responseData?.map((item) => {
						data?.push({
							value: item?.id,
							label: item?.rack_name,
						});
					});
				    const updatedForms = [...forms_array];
					//const updatedForms = [];
					updatedForms[formIndex].rack = responseData;
					//updatedForms[formIndex].chek_rk=[71,72]
					setForms(updatedForms);
				}

				return responseData;
			})
			.catch((e) => {
				console.log(e);
			});
	};




	const selectedproducts = () => {
		var productdata = []
		var selectedproducts = forms;

		for (var i = 0; i < selectedproducts.length; i++) {
			if (selectedproducts[i].product_data != "") {
				productdata[i] = parseInt(selectedproducts[i].product_data)
			}
		}

		// console.log(productdata,"productdataproductdata")
		setSelected(productdata);
		// setSelected(productdata)


	}
	// console.log(selected_products,"selected_productsselected_products")

	function validateForm() {
		
		let formIsValid = true
		var mrp_error = []
		var product_error = []
console.log(inputValues,"editform")

		if (forms.length > 0) {

			// console.log(forms,"forloop")
			for (var i = 0; i < forms.length; i++) {
				var rack_error_msg = []
				var racks_data = forms[i].rack_data;

				if (forms[i].product_data == "") {
					product_error[i] = "Please select a product"
					formIsValid = false
					// swal("Please select a product", "", "warning")
					
				}
				if (forms[i].product_data) {
					if (forms[i].mrp == "") {
						mrp_error[i] = "Mrp required"
						formIsValid = false
					}
					if (forms[i].mrp <= 0) {
						mrp_error[i] = "Mrp should be greater than 0"
						formIsValid = false
					}
				}

				if (forms[i].chek_rk.length == 0) {
					  console.log(forms[i].sales_from_godown,"nooooooooooooo")
					quantity_error[0] = "okkkkk"
					if (forms[i].sales_from_godown == "" || forms[i].sales_from_godown <= 0) {
						formIsValid = false;
						godown_error[i] = "Godown quantity required when racks are not selected"
					} else {
						godown_error[i] = "";
					}

					if (forms[i].sales_from_godown != "" && forms[i].sales_from_godown <= 0) {
						formIsValid = false;
						godown_error[i] = "Godown quantity should be greater than 0"
					}
					if (forms[i].sales_from_godown > 0) {
						godown_error[i] = "";
					}

				} else {

					if (forms[i].sales_from_godown) {

						if (forms[i].sales_from_godown <= 0) {
							formIsValid = false;
							godown_error[i] = "Godown quantity should be greater than 0"
						} else {
							godown_error[i] = ""
						}

					} else {
						godown_error[i] = "";
					}

					if(forms[i].rack_data){
						
						forms[i].rack_data?.map((item) => {
                             if(item['rack_id'] && item['rack_id'] > 0){
								if(!item['rack_q']){
									formIsValid = false;
									swal("Please enter rack quantity", "", "warning")
								}else if(item['rack_q'] <= 0){
									formIsValid = false;
									swal("Quantity should be greater than 0", "", "warning")
								}
								
							 }
						})
					}
				}

				// console.log(forms[i].sales_from_godown,"forms[i]forms[i]")


				// console.log(mrp_error,"mrperror")

			}
		}
console.log(inputValues.sales_type,"arun1")
		if ((inputValues.sales_type != 0 && inputValues.sales_type != 1)) {
			
			formIsValid = false;
			// setDouble(true)
			errors["sales_type"] = "Sales type is required"
		} else {
			errors["sales_type"] = ""
		}
		// console.log(inputValues.customer_id,"inputValues.customer_id")
		if (inputValues.sales_type == 1 && !inputValues.customer_id) {
			formIsValid = false;
			// setDouble(true)
			errors["customer_id"] = "Customer Name is required"
		} else {
			errors["customer_id"] = "";
		}

		setquantityErrr(quantity_error)
		setErrors(errors)
		setmrpErrr(mrp_error)
		setproductErrr(product_error)
		setgodownErrr(godown_error)
		return formIsValid;

	}

	const handleRemoveForm = (formIndex) => {
		const updatedForms = [...forms];
		updatedForms.splice(formIndex, 1);
		setForms(updatedForms);
		gettotal();
		// selectedproducts();
	};

	const handleSelectBoxChange = (formIndex, optionIndex, value, name) => {

		const updatedForms = [...forms];
		if (!updatedForms[formIndex].rack_data[optionIndex]) {
			// Check if the object exists in 'rack_data' array
			updatedForms[formIndex].rack_data[optionIndex] = {}; // Initialize the object if it doesn't exist
		}
		updatedForms[formIndex].rack_data[optionIndex][name] = value;
		setForms(updatedForms);

		selectedracks(formIndex)

	};

	const handleRemoveSelectBox = (formIndex, optionIndex) => {
		const updatedForms = [...forms];
		updatedForms[formIndex].rack_data.splice(optionIndex, 1);

		setForms(updatedForms);


		selectedremovedracks(formIndex, forms[formIndex].rack_data)


	};
	const handleAddSelectBox = (formIndex) => {
		const updatedForms = [...forms];
		updatedForms[formIndex].rack_data.push("");
		setForms(updatedForms);
	};
	const handlemrpChange = (formIndex, value,a) => {
		const updatedForms = [...forms];
		updatedForms[formIndex].mrp = value;
		setForms(updatedForms);
		convertAmountToWords(a,value)
		//  console.log(convertAmountToWords(a,value),"kok")
		


	}

	const fetchMRP = (selectedProductId, formIndex) => {

		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				id: selectedProductId,
				type: "po"
			}),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};

		fetch(API_URL + "/get_price", requestOptions)
			.then(response => { return response.json(); })

			.then((responseData) => {
				if (responseData.length == 0) {
					const updatedForms = [...forms];
					updatedForms[formIndex].mrp = '';
					setForms(updatedForms);
				}

				// console.log(responseData, "mrp")
				const updatedForms = [...forms];
				updatedForms[formIndex].mrp = responseData[0].selling_price == null ? "" : responseData[0].selling_price || '';
				setForms(updatedForms);
			})
			.catch(e => {
				console.log(e);

			});
	}


	const handleInputChange = (formIndex, value) => {
		const updatedForms = [...forms];
		updatedForms[formIndex].sales_from_godown = value;
		setForms(updatedForms);
	};

	const handlediscount = (e) => {
		//  alert(1111)
		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,
		});
		console.log(e.target.name, e.target.value, "e")



	}

	useEffect(() => {
		gettotal();
		 selectedproducts();

	}, [inputValues.discount]);

	console.log(inputValues, "disss")

	const handleAddForm = () => {
		setForms([
			...forms,
			{
				rack: [],
				rack_data: [{ rack_q: null, rack_id: null }],
				inputText: "",
				product_data: "",
				chek_rk: [],
				quantity_error_data: [],
			},
		]);
	};


	
	function gettotal(name, value) {

		let mrptotal = 0;
		let sales_from_godown = 0;
		let subtotal = 0;
		let totalSum = 0;

		const data_arry_final = [...forms]
		console.log(data_arry_final, "aruns")
		data_arry_final?.map((item) => {


			totalSum = calculateSumOfTot(item.rack_data);

			if (item.sales_from_godown > 0) {
				totalSum = parseFloat(totalSum) + parseFloat(item.sales_from_godown)
			}

			if (item.mrp) {
				subtotal = parseFloat(subtotal) + (parseFloat(parseFloat(totalSum) * parseFloat(item.mrp)))
			} else {
				subtotal = subtotal;
			}
			item.subtotal = subtotal;

			totalSum = 0;


		})

		// console.log(inputValues.discount,"disss")

		var discontamount = inputValues.discount;



		let total = subtotal

		if (discontamount) {
			total = total - discontamount
		}

		let rounfoffamount = Roundoff(discontamount, total)

		var dis = total.toFixed(2)
		const amt_aft_discound = Math.round(dis);

		setInputValues({
			...inputValues,
			subtotal: subtotal,
			total: total,
			amt_aft_discound: amt_aft_discound,
			roundoff: rounfoffamount,
			//discount: value,
			//discount: inputValues.discount
			//op_date: op_date,
		});

	}

	console.log(inputValues, "discccccccccccccccc")

	const selectedracks = (formIndex) => {

		var elss = [];

		var els = document.getElementsByClassName('rack' + formIndex);

		for (var i = 0; i < els.length; i++) {
			if (els[i].value != 0) {
				elss[i] = parseInt(els[i].value);
			}
		}

		const updatedForms = [...forms];
		updatedForms[formIndex].chek_rk = elss;
		setForms(updatedForms);

	}

	const selectedremovedracks = (formIndex, selectedrack_data) => {

		// console.log(selectedrack_data,"selectedrack_data")
		var rack_selected = []

		for (var i = 0; i < selectedrack_data.length; i++) {
			rack_selected[i] = parseInt(selectedrack_data[i].rack_id)
		}

		const updatedForms = [...forms];
		updatedForms[formIndex].chek_rk = rack_selected;
		setForms(updatedForms);

		// console.log(rack_selected,"rack_selected")


	}

	const Roundoff = (discount, total) => {
		// console.log("nooooo")
		var discount = discount;
		var currentValue = total;

		const rounded = Math.round(currentValue);

		if (currentValue % 1 != 0) {
			var dc
			if (rounded > currentValue) {
				var d = rounded - currentValue
				// console.log(d)
				var dec = d.toFixed(2)

				dc = dec
			}
			else {
				var d = currentValue - rounded
				// console.log(d)
				var dec = d.toFixed(2)

				dc = dec
			}

		}
		else {
			dc = 0
		}
		return dc;
	}

	function calculateSumOfTot(data) {
		let sum = 0;
		for (const obj of data) {
			if (obj.rack_q > 0) {
				sum = parseFloat(sum) + parseFloat(obj.rack_q);
			} else {
				sum = sum
			}

		}
		return sum;
	}

	function handleYes() {
		const requestOptions = {      //---employee listing---//
			method: 'post',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({
				id: approval_id,
				status: approval_status
			})
		};
		fetch(`${API_URL}/nonfuel-product-direct_approve`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				ListPackedOil(pages.current_page);
				toggleModal2()
				return responseData;
			})
			.catch(e => {
				console.log(e)

			});

	}


	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={27} operation={1} />
					<div className="content-area">
						<ManagerLoginHeader props={props} page_title="" />
						<div class="container pb-3 tab-view">
							<div class="row  mt-3 form-container">
							<div className="col-md-9">

								
								{permissions.includes("Customer Attendant") ?
									<div className="form-wrap mt-5">
										<div className="text-center">
											You don't have permission to access this page
										</div></div> : permission.status == 3 ?
										<div className="form-wrap mt-5">
											<div className="text-center">
												{permission.message}
											</div></div> :
										<>
<TabHeader progress={14} />

											<div>
												{Loading ? ListPackedOil :
													// <div class="loader text-primary">Loading...</div>
													<Loader />
												}
												<div class="form-wrap ">

													<div class="tab-content" id="myTabContent">
														<div class="tab-pane  fade show active" id="packedoil" role="tabpanel" aria-labelledby="packedoil-tab">
															<div class="">
																<div class="row mb-3">
																	<div class="col-md">
																		<div class="row">

																			<div class="col-md col pl-0">
																				<h3 class="table-title">Non fuel Product Direct Sales Employee</h3> <h4>{moment(localStorage.getItem("op_date")).format("DD-MM-YYYY")}</h4>
																			</div>

																		</div>
																	</div>
																	<div className="col-md-4">
																		<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Add Non fuel Product</a>
																	</div>
																	<div class="col-md-4">
																		<div class="input-group mb-3 search">
																			<span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
																			</span>
																			<input type="text" className="form-control" placeholder="Search  " value={search.search_keyword} onChange={handleSearch} />
																			<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>
																		</div>
																	</div>
																</div>
									


																<div class="table-widget">
																	<table class="table border">
																	<thead>
																			<tr>
																				<th>Sales Type</th>
																				<th>Customer Name</th>
																				<th>Description</th>
																				<th>Operational Date</th>
																				<th>Product Name</th>
																				<th>Storage</th>
																				<th>Quantity (L)</th>
																				<th>Selling Price</th>
																                <th >Sales Price</th>
																				<th>Sales Value</th>
																				
																				
																			   
																				<th>Sub Total</th>
																				<th>Discount</th>
																				<th>Total Stock</th>
																				<th>Action</th>

																			</tr>
																		</thead>
																		<tbody>
												{
														list_data.map((pro,proindex) => (
																					
														<React.Fragment key={proindex}>
															{console.log(pro,"pro")}
															{console.log(pro.metadata,"metas")}	
																			
															{pro.metadata.map((submeta,submetaindex)=>(
																<tr key={submetaindex}>
																	{
																	submetaindex === 0 ? (
																	
																		<td rowSpan={pro.metadata.length}>{pro.customer_id== null ? "cash sales" : "credit sales"}</td>):null
																	
																	}
																	{
																	submetaindex === 0 ? (
																	
																		<td rowSpan={pro.metadata.length}>{pro.cust_name== null ? "--" : pro.cust_name}</td>):null
																	
																	}
																	{
																	submetaindex === 0 ? (
																	
																		<td rowSpan={pro.metadata.length}>{pro.note?pro.note:null}</td>):null
																	
																	}
																	{
																	submetaindex === 0 ? (
																	
																		<td rowSpan={pro.metadata.length}>{moment(pro.op_date).format("DD-MM-YYYY")}</td>):null
																	
																	}
																	
																	<td>{submeta.product_name}</td>
																	<td>{submeta.racks_name}</td>
																	<td>{submeta.quantity}</td>
																	<td>{submeta.selling_price }</td>
																	<td>{submeta.sales_price}</td>
																	<td>{submeta.sales_value}</td>
																	
																	
																	{
																	submetaindex === 0 ? (
																	
																		<td rowSpan={pro.metadata.length}>{pro.subtotal}</td>):null
																	
																	}
																	{
																	submetaindex === 0 ? (
																	
																		<td rowSpan={pro.metadata.length}>{pro.discount}</td>):null
																	
																	}
																	{
																	submetaindex === 0 ? (
																	
																		<td rowSpan={pro.metadata.length}>{pro.total_stock}</td>):null
																	
																	}
																	{
																	submetaindex === 0 ? (
																	
																		<td class="py-2" rowSpan={pro.metadata.length}>
																		<a href="#" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" onClick={() => handleEdit(pro.id, pro.status)} >
																		{pro.status == 3 || pro.status == 4 ? 
																		<i class="fa fa-eye me-2" aria-hidden="true"></i> 
																		: <i class="fa fa-pencil-square-o me-2" aria-hidden="true"></i>}</a>
																		{pro.status < 4 ? <a href="#" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" onClick={() => delete_packedOil(pro.id)}><i class="fa fa-trash-o me-2" aria-hidden="true"></i></a> : ''}
																		{permissions.includes("Deny Approval") == true ?
																			<>
																				{pro.status < 4 ? <a href="#" className="text-secondary" onClick={() => handleManagerAproval(pro.id, pro.status)}>{(pro.status == 3) ? <i className="text-success fa fa-check" aria-hidden="true"></i> : <i className="text-warning fa fa-check" aria-hidden="true"></i>}</a> : <a><i className="btn btn-success btn-xs" aria-hidden="true">Day Close</i></a>}
																			</>
																			: ''} 
																			</td>):null
																	
																	}

																	
																	
														

																</tr>
															))}

																						</React.Fragment>
																				))}

																		</tbody>

																	</table>
																	{Loading ? <PaginationComp
																		pages={pages}
																		handlePagination={handlePagination}
																	/> : ""
																	}

																</div>
											
															</div>
														</div>
													</div>
												</div>
											</div></>}
										
								</div>
								<div className="col-md-3">
									<SummarySheet summary={summary} />
								</div>

						</div>
						
						</div>
						

					</div>
				</div>
			</div>

{console.log(forms,"latest")}

			{isShowing ? (<FuelProductModal
				isShowing={isShowing}
				hide={toggle}
				rack_error={rack_error}
				rack_error_single={rack_error_single}
				handleInputs={handleInputs}
				handleInput={handleInput}
				// handleSelect={handleSelect}
				inputValues={inputValues}
				fields={fields}
				errors={errors}
				// handleQuantity={handleQuantity}
				handleDelete={handleDelete}
				// handleAdd={handleAdd}
				// handleRemove={handleRemove}
				// chek_rk={chek_rk}
				success={success}
				err={err}
				er={er}
				handle={handle}
				double={double}
				startDate={startDate}
				handleDate={handleDate}
				sales_details={sales_details}
				rack_details={rack_details}
				selling_price={selling_price}
				dropdownData={dropdownData}
				product={product}
				// handleRack={handleRack}
				selected_options={selected_options}
				handleChange={handleChange}
				handleChangeType={handleChangeType}
				handleSubmit={handleSubmit}
				forms={forms}
				handleSelectProductChange={handleSelectProductChange}
				getRack={getRack}
				selected_products={selected_products}
				product_error={product_error}
				handleRemoveForm={handleRemoveForm}
				handleSelectBoxChange={handleSelectBoxChange}

				handleAddSelectBox={handleAddSelectBox}
				handlemrpChange={handlemrpChange}
				godown_error={godown_error}
				mrp_error={mrp_error}
				handleInputChange={handleInputChange}
				handleAddForm={handleAddForm}
				handlediscount={handlediscount}
				handleRemoveSelectBox={handleRemoveSelectBox}


			/>) : null
			}
			{isShow ? (<NonFuelModal
				isShowing={isShowing}
				hide={toggleModal}
				rack_error={rack_error}
				rack_error_single={rack_error_single}
				handleInputs={handleInputs}
				handleInput={handleInput}
				// handleSelect={handleSelect}
				inputValues={inputValues}
				fields={fields}
				errors={errors}
				// handleQuantity={handleQuantity}
				handleDelete={handleDelete}
				// handleAdd={handleAdd}
				// handleRemove={handleRemove}
				// chek_rk={chek_rk}
				success={success}
				err={err}
				er={er}
				handle={handle}
				double={double}
				startDate={startDate}
				handleDate={handleDate}
				sales_details={sales_details}
				rack_details={rack_details}
				selling_price={selling_price}
				dropdownData={dropdownData}
				product={product}
				// handleRack={handleRack}
				selected_options={selected_options}
				handleChange={handleChange}
				handleChangeType={handleChangeType}
				handleeditSubmit={handleeditSubmit}
				forms={forms}
				handleSelectProductChange={handleSelectProductChange}
				getRack={getRack}
				selected_products={selected_products}
				product_error={product_error}
				handleRemoveForm={handleRemoveForm}
				handleSelectBoxChange={handleSelectBoxChange}

				handleAddSelectBox={handleAddSelectBox}
				handlemrpChange={handlemrpChange}
				godown_error={godown_error}
				mrp_error={mrp_error}
				handleInputChange={handleInputChange}
				handleAddForm={handleAddForm}
				handlediscount={handlediscount}
				handleRemoveSelectBox={handleRemoveSelectBox}
				edit_list={edit_list}
			/>) : null
			}

			<Modal
				isOpen={isOpen}
				onRequestClose={toggling}
				contentLabel="My dialog"
				className="mymodal custommodal"
				overlayClassName="myoverlay"
			>
				<div class="modal-content">
					<div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">Non Fuel Product Direct Sales</h5><button type="button" onClick={toggling} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
					<div class=" modal-body  modal-body"><p>Are you sure you want to delete this non fuel direct sale</p></div>
					<div class="justify-content-end  modal-footer"><div class="d-flex">
						<button type="button" class="btn btn-secondary me-2" onClick={toggling}>No</button>
						{double == true ? <button type="submit" class="btn btn-primary" onClick={deletePackedOil}>Yes</button> : <button type="submit" class="btn btn-primary" disabled onClick={deletePackedOil}>Yes</button>}
					</div>
					</div>
				</div>
			</Modal>

			{isApprove ? (<ApprovalModal
				isApprove={isApprove}
				hide={toggleModal2}
				handleYes={handleYes}
				approval_status={approval_status}
			/>) : null
			}
		</>
	)
}


export default NonFuelProductConfigEmployee