import React, { useState } from 'react';
import LoginHeader from "../../../layouts/LoginHeader.component";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import ManagerLoginHeader from '../ManagerLoginHeader.component'
import NonFuelEditPurchase from './NonFuelEditPurchase.component';
import NonFuelProductPurchaseModal from './NonFuelProductPurchaseModal.component';
import Loader from '../../Employee/Loader.component'
import ManagerApprovalConfig from '../ManagerApprovalConfig.component';
import PaginationComponent from '../../../layouts/PaginationComponent';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { convertAmountToWords, prevNonNumericalWithDec, preventNonNumericalInput } from "../../../layouts/Operational_Sidebar_Progress.component";
const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);
const NonFuelPurchaseModal = (props) => ReactDOM.createPortal(
	<NonFuelProductPurchaseModal  {...props} />, document.body
);
const NonFuelEdit = (props) => ReactDOM.createPortal(
	<NonFuelEditPurchase {...props} />, document.body
);
const ApprovalModal = (props) => ReactDOM.createPortal(
	<ManagerApprovalConfig  {...props} />, document.body
);
const NonFuelProductPurchaseConfig = (props) => {
	let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");
	const location = useLocation();
	const [approval_id, approvalId] = useState([])
	const [approval_status, approvalStatus] = useState([])
	const [delete_id, deleteId] = useState([])
	const [inputValues, setInputValues] = useState({ product: [''] });
	const [input, setInput] = useState({});
	const [edit_list, setProductList] = useState([])
	const [double, setDouble] = useState(true);
	const [fields, setFields] = useState(['']);
	const [fuel_config, SetFuel] = useState([])
	const history = useHistory()
	const [search, setSearch] = useState('')
	const [chek_rk, setFields2] = useState(['']);
	const [list_data, setList] = useState([])
	const [startDate, setDate] = useState([])
	const [c_Date,c_setDate]=useState([])
	const [multiple, setMultiple] = useState([])
	const [Values, setValues] = useState([])
	const [product_data, setProduct] = useState([])
	const [vendor_data, setVendor] = useState([])
	const [errors, setErrors] = useState([])
	const [err, setErr] = useState([])
	const [er, setEr] = useState([])
	const [errr, setErrr] = useState([])
	const [errrpp, setErrrpp] = useState([])
	const [errrppp, setErrrppp] = useState([])
	const [gst, setGst] = useState([])
	const [mrp, setMrp] = useState([])
	const [success, setSuccess] = useState([])
	const [purchase, setPurchase] = useState([])
	const [loading, setLoading] = useState(false)
	const [permission, setPermission] = useState([])
	const[day_closing_date,setDayclosedate]=useState({date:location?.state||""}) 
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
	//---On Load---//		
	useEffect(() => {
		check_url();
		is_day_closed();

	}, []);

	//------------------------url
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {

				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					ListNonfuelProductPurchase(pages.current_page);
					getProduct()
					getVendor()

				}
				else {

					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	//-------------------end
	//Vendor name dropdown api integration//
	function getVendor() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/get_vendors", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datas = []

				responseData.forEach(elem => {
					datas.push([elem.id, elem.name])
				})

				setVendor(datas);
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	//Product name dropdown api integration//



	function ListNonfuelProductPurchase(page) {
		const requestOptions = {      //--- listing---//
			method: 'get',
			// data: {
			// 	op_date:28/4/2018,
			// 	search_keyword:search
			// },
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			// body: JSON.stringify({
			// 	op_date:28/4/2018,
			// 	search_keyword:search
			// })
		};
		fetch(`${API_URL}/nf-product-purchase?search_keyword=${search}&page=${page}&day_close_date=${day_closing_date?.date}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (responseData.status == 3) {
					setPermission({ ...permission, status: responseData.status, message: responseData.message })
				}
				setList(responseData.data.data)
				setDate(responseData.start_date)
				// responseData.get_nf_purchase.map((elem)=>{
				// 	setProductList(elem)
				// })



				setpages({
					...pages,
					current_page: responseData.data.current_page,
					last_page: responseData.data.last_page,
					per_pages: responseData.data.per_page,
					total_pages: responseData.data.total,

				})
				setLoading(true)
				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListNonfuelProductPurchase(number)
	}
	function edit_nonfuelproduct(id, status) {
		const requestOptions = {
			method: 'get',
			data: {
				id: id,

			},
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/nf-product-purchase/" + id + "/edit", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				let product = []
				responseData.nf_products.forEach((elem, index) => {
					product.push({  product_id: elem.product_id,
						            price: elem.price,
									quantity: elem.quantity,
									amount: elem.amount,
									current_stock: elem.current_stock,
									pp_inc_gstanddisc: elem.pp_inc_gstanddisc,
									avg_pp: elem.avg_pp,
									diff_pp: elem.diff_pp,
									gst: elem.gst,
									pp_per_piece_exc_gst: elem.pp_per_piece_exc_gst,
									gst_amount: elem.gst_amount,
									mrp: elem.mrp,
									cgst: elem.cgst,
									sgst: elem.sgst,
									gst_return: elem.return_gst,
								 })

				})
				setValues(product)
				SetFuel(product)
				setInput({
					vendor_id: responseData.nfpurchase.vendor_id,
					id: responseData.nfpurchase.id ? responseData.nfpurchase.id : '',
					invoice_number: responseData.nfpurchase.invoice_number,
					invoice_date: responseData.nfpurchase.invoice_date,
					invoice_amount: responseData.nfpurchase.invoice_amount,
					note: responseData.nfpurchase.note,
					purchase_type: responseData.nfpurchase.purchase_type,
					round_off: responseData.nfpurchase.round_off,
					discount: responseData.nfpurchase.discount,
					net_amount: responseData.nfpurchase.net_amount,
					amount_after_discount: responseData.nfpurchase.amount_after_discount,
					i_gst: responseData.nfpurchase.i_gst,
					s_gst: responseData.nfpurchase.s_gst,
					c_gst: responseData.nfpurchase.c_gst,
					op_date: responseData.nfpurchase.op_date,
					product: product

				})

				getProduct()
				getVendor()
				toggling();

				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}

	function is_day_closed() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/is_day_closed", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				console.log(responseData,"kok")
				c_setDate(responseData)

			})
			.catch(e => {
				console.log(e);

			});

	}
	function getPrice(i, id) {

		const requestOptions = {
			method: 'post',
			body: JSON.stringify({ id: id }),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};

		fetch(API_URL + "/get_nf_pp_price", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var product_list = []
				var currentValue = 0

				var op = responseData[0].purchase_price;
				var current_stock = responseData[0].total_stock;

				setPurchase({ ...purchase, price: responseData[0].purchase_price })


				var s = document.getElementById("purchase-price" + i);
				s.value = op;

				var cs = document.getElementById("current_stock" + i);
				cs.value = current_stock;

				var q = document.getElementById("quantity" + i).value;
				var pp_incgstaftdisc = document.getElementById("pp_inc_gst_aft_disc" + i).value;
				
				// var mul = q * op
				var mul = q * pp_incgstaftdisc


				// var mul = q * op
				// var amount = document.getElementById("amount" + i)
				// amount.value = mul

				var mul = q * pp_incgstaftdisc

				var amount = document.getElementById("amount" + i)
				amount.value = mul

				setMultiple(mul)
				var list = []
				var product = []
				list = [...fuel_config]
				var currentValue = 0

				for (var j = 0; j < list.length; j++) {
					console.log(document.getElementById("amount" + j).value)
					currentValue += Number(document.getElementById("amount" + j).value)
					product.push({
						product_id: document.getElementById("id" + j).value,
						price: document.getElementById("purchase-price" + j).value,
						quantity: document.getElementById("quantity" + j).value,
						amount: document.getElementById("amount" + j).value,
						current_stock: document.getElementById("current_stock" + i).value,
					pp_inc_gstanddisc: document.getElementById("pp_inc_gst_aft_disc" + i).value,
					avg_pp: document.getElementById("avg_purchase_price" + i).value,
					diff_pp: document.getElementById("diff_purchase_price" + i).value,
					gst: document.getElementById("gst" + i).value,
					pp_per_piece_exc_gst: document.getElementById("pp_per_piece_exc_gst" + i).value,
					gst_amount: document.getElementById("gst_amount" + i).value,
					mrp: document.getElementById("mrp" + i).value,
					cgst: document.getElementById("cgst" + i).value,
					sgst: document.getElementById("sgst" + i).value,
					gst_return: document.getElementById("gst_return" + i).value,


					})
				}
				console.log(currentValue)
				const nt_amnt = document.getElementById("net_amount")
				nt_amnt.value = currentValue
				if (document.getElementById("discount")?.value) {

					const discount = (document.getElementById("net_amount").value - document.getElementById("discount").value)
					const di = document.getElementById("amount_after_discount")
					di.value = discount.toFixed(2)
					var dis = discount.toFixed(2)
					const rounded = Math.round(dis);

					if (dis % 1 != 0) {
						var dc
						if (rounded > dis) {
							var d = rounded - dis
							console.log(d)
							var dec = d.toFixed(2)

							dc = dec
						}
						else {
							var d = dis - rounded
							console.log(d)
							var dec = d.toFixed(2)

							dc = dec
						}

					}
					else {
						dc = 0
					}

					setInput({
						...input, net_amount: currentValue.toFixed(2), amount_after_discount: discount.toFixed(2), round_off: dc,
						invoice_amount: rounded
					})
				}
				else {
					const rounded = Math.round(currentValue);

					if (currentValue % 1 != 0) {
						var dc
						if (rounded > currentValue) {
							var d = rounded - currentValue
							console.log(d)
							var dec = d.toFixed(2)

							dc = dec
						}
						else {
							var d = currentValue - rounded
							console.log(d)
							var dec = d.toFixed(2)

							dc = dec
						}

					}
					else {
						dc = 0
					}
					setInput({
						...input, round_off: dc,
						invoice_amount: rounded, net_amount: currentValue.toFixed(2)
					})
				}

				setFields(product_list);
				setValues(product)
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	function getProductPrice(i, id) {

		const requestOptions = {
			method: 'post',
			body: JSON.stringify({ id: id }),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};

		fetch(API_URL + "/get_nf_pp_price", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var product_list = []
				var currentValue = 0

				var op = responseData[0].purchase_price;

				var current_stock = responseData[0].total_stock;

				setPurchase({ ...purchase, price: responseData[0].purchase_price })


				var s = document.getElementById("purchase-price" + i);
				s.value = op;

				var cs = document.getElementById("current_stock" + i);
				cs.value = current_stock;

				var q = document.getElementById("quantity" + i).value;
				var pp_incgstaftdisc = document.getElementById("pp_inc_gst_aft_disc" + i).value;
				
				// var mul = q * op
				var mul = q * pp_incgstaftdisc

				var amount = document.getElementById("amount" + i)
				amount.value = mul

				setMultiple(mul)
				var list = []
				var product = []
				list = [...fields]
				var currentValue = 0

				/************** */
                 
				if((document.getElementById("purchase-price" + i).value) && (document.getElementById("pp_inc_gst_aft_disc" + i).value))
			{ console.log("okkkkkkkkkkkk")
				const old_pp = parseFloat(document.getElementById("purchase-price" + i).value)
				const new_pp = parseFloat(document.getElementById("pp_inc_gst_aft_disc" + i).value)
                var diff_pp = ""
				if(old_pp >= new_pp)
				{
					 diff_pp = old_pp - new_pp
				}else{
					 diff_pp = new_pp - old_pp
				}

				const diffrence = document.getElementById("diff_purchase_price" + i)
				diffrence.value = diff_pp.toFixed(2)

			}
			console.log("okkkkkkkkkkkk")

			if((document.getElementById("quantity" + i).value) && (document.getElementById("pp_inc_gst_aft_disc" + i).value))
			{
				const total_stock = parseFloat(document.getElementById("quantity" + i).value) + parseFloat(document.getElementById("current_stock" + i).value)
				const mulavg1 = document.getElementById("quantity" + i).value * document.getElementById("pp_inc_gst_aft_disc" + i).value
			    const mulavg2 = document.getElementById("current_stock" + i).value * document.getElementById("purchase-price" + i).value 

				const totalmulavg = parseFloat(mulavg1) + parseFloat(mulavg2)
                // const avgpp = totalmulavg/total_stock
				const avgpp = 0
				console.log(avgpp,"avggggggggggggg")
				const avg_pp = document.getElementById("avg_purchase_price" + i)
				avg_pp.value = avgpp.toFixed(2)
				
			}

			if(document.getElementById("gst" + i).value && document.getElementById("avg_purchase_price" + i).value)
			{

				console.log("gstttttttttttttttt")
				/******gstamount********/

				var total_gst = (document.getElementById("gst" + i).value)
				var avgppforgst = document.getElementById("avg_purchase_price" + i).value
				var ppforgst = document.getElementById("pp_inc_gst_aft_disc" + i).value
				
				//var pp_ex_gst = avgppforgst * (100/(100+(parseFloat(total_gst))))
				var pp_ex_gst = ppforgst * (100/(100+(parseFloat(total_gst))))
				const pp_ex_gsts = document.getElementById("pp_per_piece_exc_gst" + i)
				const pp_ex_gst_per_piece = pp_ex_gst
				pp_ex_gsts.value = pp_ex_gst.toFixed(2)

				var gst_amount_final = (pp_ex_gst_per_piece * ((total_gst)/100))

				var split_gst = (gst_amount_final)/2


                const gst_amount_f = document.getElementById("gst_amount" + i)
				const cgst_amount = document.getElementById("cgst" + i)
				const sgst_amount = document.getElementById("sgst" + i)
				
				gst_amount_f.value = gst_amount_final
				cgst_amount.value = split_gst.toFixed(2)
				sgst_amount.value = split_gst.toFixed(2)			
			
			}

			if(!document.getElementById("gst" + i).value || !document.getElementById("avg_purchase_price" + i).value)
			{
				const cgst_amount = document.getElementById("cgst" + i)
				const sgst_amount = document.getElementById("sgst" + i)
				const pp_ex_gsts = document.getElementById("pp_per_piece_exc_gst" + i)
				const gst_amount_f = document.getElementById("gst_amount" + i)
				
				cgst_amount.value = ""
				sgst_amount.value = ""
				pp_ex_gsts.value = ""
				gst_amount_f.value = ""
			}

			if(document.getElementById("gst" + i).value && document.getElementById("mrp" + i).value && document.getElementById("pp_per_piece_exc_gst" + i).value)
			{
			   const total_gsts = parseFloat(document.getElementById("gst" + i).value)
			   const mrp = parseFloat(document.getElementById("mrp" + i).value)
			   const pp_per_piece_exc_gst = parseFloat(document.getElementById("pp_per_piece_exc_gst" + i).value)
			   
			   var gstreturn = ((mrp * (100/(100+total_gsts))).toFixed(2)) - (pp_per_piece_exc_gst)
			   gstreturn = ((gstreturn).toFixed(2))*(total_gsts/100)

			   const return_gst_field = document.getElementById("gst_return" + i)
			   return_gst_field.value = gstreturn.toFixed(2)


			   
			}
			else{
			  const gst_return = document.getElementById("gst_return" + i)
			  gst_return.value = ""
			}



				/******************/ 

				for (var j = 0; j < list.length; j++) {
					console.log(document.getElementById("amount" + j).value)
					currentValue += Number(document.getElementById("amount" + j).value)

				}
				console.log(currentValue)
				const nt_amnt = document.getElementById("net_amount")
				nt_amnt.value = currentValue
				if (document.getElementById("discount")?.value) {

					const discount = (document.getElementById("net_amount").value - document.getElementById("discount").value)
					const di = document.getElementById("amount_after_discount")
					di.value = discount.toFixed(2)
					var dis = discount.toFixed(2)
					const rounded = Math.round(dis);

					if (dis % 1 != 0) {
						var dc
						if (rounded > dis) {
							var d = rounded - dis
							console.log(d)
							var dec = d.toFixed(2)

							dc = dec
						}
						else {
							var d = dis - rounded
							console.log(d)
							var dec = d.toFixed(2)

							dc = dec
						}

					}
					else {
						dc = 0
					}

					setInput({
						...input, net_amount: currentValue.toFixed(2), amount_after_discount: discount.toFixed(2), round_off: dc,
						invoice_amount: rounded
					})
				}
				else {
					const rounded = Math.round(currentValue);

					if (currentValue % 1 != 0) {
						var dc
						if (rounded > currentValue) {
							var d = rounded - currentValue
							console.log(d)
							var dec = d.toFixed(2)

							dc = dec
						}
						else {
							var d = currentValue - rounded
							console.log(d)
							var dec = d.toFixed(2)

							dc = dec
						}

					}
					else {
						dc = 0
					}
					setInput({
						...input, round_off: dc,
						invoice_amount: rounded, net_amount: currentValue.toFixed(2)
					})
				}

				// setFields(product_list);

				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			if(c_Date == 1){
				swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false ? "success":"warning")
			}else{

			if (isShowing) {
				setInputValues({ product: [''] })
				setInput({})
				setErrors({})
				setErr([])
				setEr([])
				setErrr([])
				setErrrpp([])
				setErrrppp([])
				setGst([])
				setMrp([])
				setSuccess({})
				setFields([''])
				setFields2([''])


			}
			if (!isShowing) {
				setDouble(true)
				setErrors({})
				getVendor()
				getProduct()


			}
			setIsShowing(!isShowing);
		}
	}
		return {
			isShowing,
			toggle,
		}
	};
	function getProduct() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/get_nf_products", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				var datas = []

				responseData.forEach(elem => {
					datas.push([elem.id, elem.product_name])
				})

				setProduct(datas);
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	const { isShowing, toggle } = UseModal();
	const UseEditModal = () => {

		const [isClose, setClose] = useState(false);

		function toggling() {
			getProduct()
			getVendor()
			if (isClose) {
				setInputValues({ product: [''] })
				setInput([])
				setErrors([])
				setErr([])
				setEr([])
				setErrr([])
				setErrrpp([])
				setErrrppp([])
				setGst([])
				setMrp([])
				setSuccess({})
				setFields([''])

			}
			if (!isClose) {

				setErrors({})

			}
			setClose(!isClose);
		}

		return {
			isClose,
			toggling,
		}
	};
	const { isClose, toggling } = UseEditModal();
	//---Delete popup function---//
	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}
	const handleInputs = (e) => {
		setInput({
			...input,
			[e.target.name]: e.target.value
		});

	};
	const handleEdit = (id, status) => {
		if(c_Date == 1 && status < 3){
			swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false ? "success":"warning")
		}else{
		console.log(id, "d")
		setProductList({
			id: id,
			status: status
		})
		edit_nonfuelproduct(id, status)
	};
}
	//---onchange functions---//
	function handleChange(i, event) {
		const values = [...fields];
		values[i] = event.target.value;
		setFields(values);
		setInputValues({ ...inputValues, values })
	}
	function handleAdd() {
		const values = [...fields];
		values.push('');
		setFields(values);
		disable_rk()


	}
	function handleEditAdd() {

		const values = [...Values];
		values.push('');
		setValues(values);
		SetFuel(values)
		disable_rk()

	}
	function handleRemove(i) {
		const values = [...fields];
		
		if (i != 0) {
			values.splice(i, 1);
			console.log(i)
			console.log(values)
			setInputValues({ ...inputValues, product: values })
			let product = []
			const purchase = document.getElementById("purchase-price" + i).value
			const quantity = document.getElementById("quantity" + i).value

			var amount = (document.getElementById("pp_inc_gst_aft_disc" + i).value * document.getElementById("quantity" + i).value)
			const q = document.getElementById("amount" + i)
			q.value = amount
			var currentValue = 0
			for (var i = 0; i < values.length; i++) {
				console.log("zczxc")
				var multiple = document.getElementById("pp_inc_gst_aft_disc" + i).value * document.getElementById("quantity" + i).value
				currentValue += multiple
				product.push({
					id: values[i],
					price: document.getElementById("purchase-price" + i).value,
					quantity: document.getElementById("quantity" + i).value,
					amount: multiple,
					current_stock: document.getElementById("current_stock" + i).value,
					pp_inc_gstanddisc: document.getElementById("pp_inc_gst_aft_disc" + i).value,
					avg_pp: document.getElementById("avg_purchase_price" + i).value,
					diff_pp: document.getElementById("diff_purchase_price" + i).value,
					gst: document.getElementById("gst" + i).value,
					pp_per_piece_exc_gst: document.getElementById("pp_per_piece_exc_gst" + i).value,
					gst_amount: document.getElementById("gst_amount" + i).value,
					mrp: document.getElementById("mrp" + i).value,
					cgst: document.getElementById("cgst" + i).value,
					sgst: document.getElementById("sgst" + i).value,
					gst_return: document.getElementById("gst_return" + i).value,


				})
				
			}
			setFields(values);
			if (document.getElementById("discount")?.value) {
				const discount = (currentValue - document.getElementById("discount").value)
				const di = document.getElementById("amount_after_discount")
				di.value = discount.toFixed(2)
				var dis = discount.toFixed(2)
				const rounded = Math.round(dis);

				if (dis % 1 != 0) {
					var dc
					if (rounded > dis) {
						var d = rounded - dis
						console.log(d)
						var dec = d.toFixed(2)

						dc = dec
					}
					else {
						var d = dis - rounded
						console.log(d)
						var dec = d.toFixed(2)

						dc = dec
					}

				}
				else {
					dc = 0
				}


				setInput({
					...input, amount_after_discount: discount.toFixed(2), product, round_off: dc,
					invoice_amount: rounded, net_amount: currentValue.toFixed(2)
				})

			}
			else {
				const rounded = Math.round(currentValue);

				if (currentValue % 1 != 0) {
					var dc
					if (rounded > currentValue) {
						var d = rounded - currentValue
						console.log(d)
						var dec = d.toFixed(2)

						dc = dec
					}
					else {
						var d = currentValue - rounded
						console.log(d)
						var dec = d.toFixed(2)

						dc = dec
					}

				}
				else {
					dc = 0
				}
				setInput({
					...input, round_off: dc,
					invoice_amount: rounded, product, net_amount: currentValue.toFixed(2)
				})
				// }
			}
		}

	}
	function handleEditRemove(i) {
		var i = Values.length - 1
		if (i != 0) {
			const values = [...Values];
			values.splice(i, 1);
			let product = []
			const purchase = document.getElementById("purchase-price" + i).value
			const quantity = document.getElementById("quantity" + i).value
			const amount = (document.getElementById("pp_inc_gst_aft_disc" + i).value * document.getElementById("quantity" + i).value)
			const q = document.getElementById("amount" + i)
			q.value = amount
			var currentValue = 0
			for (var i = 0; i < values.length; i++) {
				const multiple = document.getElementById("pp_inc_gst_aft_disc" + i).value * document.getElementById("quantity" + i).value
				currentValue += multiple
				product.push({
					product_id: document.getElementById("id" + i).value,
					price: document.getElementById("purchase-price" + i).value,
					quantity: document.getElementById("quantity" + i).value,
					amount: multiple,
					current_stock: document.getElementById("current_stock" + i).value,
					pp_inc_gstanddisc: document.getElementById("pp_inc_gst_aft_disc" + i).value,
					avg_pp: document.getElementById("avg_purchase_price" + i).value,
					diff_pp: document.getElementById("diff_purchase_price" + i).value,
					gst: document.getElementById("gst" + i).value,
					pp_per_piece_exc_gst: document.getElementById("pp_per_piece_exc_gst" + i).value,
					gst_amount: document.getElementById("gst_amount" + i).value,
					mrp: document.getElementById("mrp" + i).value,
					cgst: document.getElementById("cgst" + i).value,
					sgst: document.getElementById("sgst" + i).value,
					gst_return: document.getElementById("gst_return" + i).value,


				})
			}
			const nt_amnt = document.getElementById("net_amount")
			nt_amnt.value = currentValue
			if (document.getElementById("discount")?.value) {

				const discount = (document.getElementById("net_amount").value - document.getElementById("discount").value)
				const di = document.getElementById("amount_after_discount")
				di.value = discount.toFixed(2)
				var dis = discount.toFixed(2)
				const rounded = Math.round(dis);

				if (dis % 1 != 0) {
					var dc
					if (rounded > dis) {
						var d = rounded - dis
						console.log(d)
						var dec = d.toFixed(2)

						dc = dec
					}
					else {
						var d = dis - rounded
						console.log(d)
						var dec = d.toFixed(2)

						dc = dec
					}

				}
				else {
					dc = 0
				}

				setInput({
					...input, net_amount: currentValue.toFixed(2), amount_after_discount: discount.toFixed(2), round_off: dc,
					invoice_amount: rounded
				})
			}
			else {
				const rounded = Math.round(currentValue);

				if (currentValue % 1 != 0) {
					var dc
					if (rounded > currentValue) {
						var d = rounded - currentValue
						console.log(d)
						var dec = d.toFixed(2)

						dc = dec
					}
					else {
						var d = currentValue - rounded
						console.log(d)
						var dec = d.toFixed(2)

						dc = dec
					}

				}
				else {
					dc = 0
				}
				setInput({
					...input, round_off: dc,
					invoice_amount: rounded, net_amount: currentValue.toFixed(2)
				})
			}

			setFields(values);
			setValues(product)

		}
		disable_rk()

	}
	const handleProducts = (i, e) => {
		let product_list = []
		product_list = [...fields];

		product_list[i] = e.target.value
		setFields(product_list);

		getProductPrice(i, e.target.value);
		disable_rk()
		

	};
	const handleProductsEdit = (i, e) => {

		let product_list = []
		product_list = [...Values];
		setValues(product_list)
		getPrice(i, e.target.value);
		for (var j = 0; j < product_list.length; j++) {
			product_list[j] = e.target.value
		}


		disable_rk()

	};
	const handleFieldProductsEdit = (i, e) => {

		let product_list = []
		product_list = [...fields];

		product_list[i] = e.target.value
		// console.log(product_list[i])
		//    setFields(product_list);
		//    setInput({ ...input,  product_list })
		setValues(product_list)

		getPrice(e.target.value, i);
		disable_rk()

	};
	function disable_rk() {
		var elss = [];
		var els = document.getElementsByClassName('rackids');
		for (var i = 0; i < els.length; i++) {
			if (els[i].value != 0) {
				elss[i] = parseInt(els[i].value);
			}
		}

		setFields2(elss);
	}
	const handleDiscount = (e) => {
		const discount = (document.getElementById("net_amount").value - e.target.value)
		const di = document.getElementById("amount_after_discount")
		di.value = discount.toFixed(2)
		var dis = discount.toFixed(2)
		const rounded = Math.round(dis);

		if (dis % 1 != 0) {
			var dc
			if (rounded > dis) {
				var d = rounded - dis
				console.log(d)
				var dec = d.toFixed(2)

				dc = dec
			}
			else {
				var d = dis - rounded
				console.log(d)
				var dec = d.toFixed(2)

				dc = dec
			}

		}
		else {
			dc = 0
		}
		setInput({
			...input,
			[e.target.name]: e.target.value,
			amount_after_discount: discount.toFixed(2),
			round_off: dc,
			invoice_amount: rounded
		})


	};
	const handleQuantity = (i, e) => {
	
		let product_list = []
		var product = []
		product_list = [...fields]
		if (product_list[i]) {
			const purchase = document.getElementById("purchase-price" + i).value
			const quantity = document.getElementById("quantity" + i).value
			const pp_inc_gst_aft_disc = document.getElementById("pp_inc_gst_aft_disc" + i).value


			if(e.target.name == "total_pp_inc_gst_aft_disc"){
			
				const pp = (document.getElementById("total_pp_inc_gst_aft_disc" + i).value / document.getElementById("quantity" + i).value)
                const purchase_price = document.getElementById("pp_inc_gst_aft_disc" + i)
				purchase_price.value = pp.toFixed(2)

				/*********/ 
				const amount = (document.getElementById("total_pp_inc_gst_aft_disc" + i).value)
				const q = document.getElementById("amount" + i)
				q.value = amount
				/*********/ 
			}else{
				const total_pp_inc_gst_aft_disc = (document.getElementById("pp_inc_gst_aft_disc" + i).value * document.getElementById("quantity" + i).value)
				const sum_pp_inc_gst_aft_disc = document.getElementById("total_pp_inc_gst_aft_disc" + i)
				sum_pp_inc_gst_aft_disc.value = total_pp_inc_gst_aft_disc.toFixed(2)
				
				const amount = (document.getElementById("purchase-price" + i).value * document.getElementById("quantity" + i).value)
				const q = document.getElementById("amount" + i)
				
				q.value = total_pp_inc_gst_aft_disc.toFixed(2)
				// q.value = amount.toFixed(2)
			}
			if((document.getElementById("quantity" + i).value) && !(document.getElementById("pp_inc_gst_aft_disc" + i).value))
			{
				const avg_pp = document.getElementById("avg_purchase_price" + i)
				avg_pp.value = ""
			}
			
             
			if((document.getElementById("purchase-price" + i).value) && (document.getElementById("pp_inc_gst_aft_disc" + i).value))
			{ console.log("okkkkkkkkkkkk")
				const old_pp = parseFloat(document.getElementById("purchase-price" + i).value)
				const new_pp = parseFloat(document.getElementById("pp_inc_gst_aft_disc" + i).value)
                var diff_pp = ""
				if(old_pp >= new_pp)
				{
					 diff_pp = old_pp - new_pp
				}else{
					 diff_pp = new_pp - old_pp
				}

				const diffrence = document.getElementById("diff_purchase_price" + i)
				diffrence.value = diff_pp.toFixed(2)

			}
			console.log("okkkkkkkkkkkk")

			if((document.getElementById("quantity" + i).value) && (document.getElementById("pp_inc_gst_aft_disc" + i).value))
			{
				const total_stock = parseFloat(document.getElementById("quantity" + i).value) + parseFloat(document.getElementById("current_stock" + i).value)
				const mulavg1 = document.getElementById("quantity" + i).value * document.getElementById("pp_inc_gst_aft_disc" + i).value
			    const mulavg2 = document.getElementById("current_stock" + i).value * document.getElementById("purchase-price" + i).value 

				const totalmulavg = parseFloat(mulavg1) + parseFloat(mulavg2)
                // const avgpp = totalmulavg/total_stock
				const avgpp = 0
				console.log(avgpp,"avggggggggggggg")
				const avg_pp = document.getElementById("avg_purchase_price" + i)
				avg_pp.value = avgpp.toFixed(2)
				
			}

			if(document.getElementById("gst" + i).value && document.getElementById("avg_purchase_price" + i).value)
			{

				console.log("gstttttttttttttttt")
				/******gstamount********/

				var total_gst = (document.getElementById("gst" + i).value)
				var avgppforgst = document.getElementById("avg_purchase_price" + i).value
				var ppforgst = document.getElementById("pp_inc_gst_aft_disc" + i).value
				
				//var pp_ex_gst = avgppforgst * (100/(100+(parseFloat(total_gst))))
				var pp_ex_gst = ppforgst * (100/(100+(parseFloat(total_gst))))
				const pp_ex_gsts = document.getElementById("pp_per_piece_exc_gst" + i)
				const pp_ex_gst_per_piece = pp_ex_gst
				pp_ex_gsts.value = pp_ex_gst.toFixed(2)

				var gst_amount_final = (pp_ex_gst_per_piece * ((total_gst)/100)).toFixed(2)

				var split_gst = (gst_amount_final)/2


                const gst_amount_f = document.getElementById("gst_amount" + i)
				const cgst_amount = document.getElementById("cgst" + i)
				const sgst_amount = document.getElementById("sgst" + i)
				
				gst_amount_f.value = gst_amount_final
				cgst_amount.value = split_gst.toFixed(2)
				sgst_amount.value = split_gst.toFixed(2)			
			
			}

			if(!document.getElementById("gst" + i).value || !document.getElementById("avg_purchase_price" + i).value)
			{
				const cgst_amount = document.getElementById("cgst" + i)
				const sgst_amount = document.getElementById("sgst" + i)
				const pp_ex_gsts = document.getElementById("pp_per_piece_exc_gst" + i)
				const gst_amount_f = document.getElementById("gst_amount" + i)
				
				cgst_amount.value = ""
				sgst_amount.value = ""
				pp_ex_gsts.value = ""
				gst_amount_f.value = ""
			}
			 
			if(document.getElementById("gst" + i).value && document.getElementById("mrp" + i).value && document.getElementById("pp_per_piece_exc_gst" + i).value)
              {
				 const total_gsts = parseFloat(document.getElementById("gst" + i).value)
                 const mrp = parseFloat(document.getElementById("mrp" + i).value)
				 const pp_per_piece_exc_gst = parseFloat(document.getElementById("pp_per_piece_exc_gst" + i).value)
                 
				 var gstreturn = ((mrp * (100/(100+total_gsts))).toFixed(2)) - (pp_per_piece_exc_gst)
				 console.log(pp_per_piece_exc_gst,"ppexcgst")
				 console.log(gstreturn,"ans11111")
				 gstreturn = ((gstreturn).toFixed(2))*(total_gsts/100)

				 const return_gst_field = document.getElementById("gst_return" + i)
				 return_gst_field.value = gstreturn.toFixed(2)


				 
			  }
			  else{
				const gst_return = document.getElementById("gst_return" + i)
				gst_return.value = ""
			  }
			
			var currentValue = 0
			for (var i = 0; i < product_list.length; i++) {
				console.log("dfdgs")
				// var multiple = document.getElementById("purchase-price" + i).value * document.getElementById("quantity" + i).value
				var multiple = document.getElementById("pp_inc_gst_aft_disc" + i).value * document.getElementById("quantity" + i).value
				var mul = multiple.toFixed(2)
				currentValue += Number(mul)
				product.push({
					id: product_list[i],
					price: document.getElementById("purchase-price" + i).value,
					quantity: document.getElementById("quantity" + i).value,
					amount: mul,
					current_stock: document.getElementById("current_stock" + i).value,
					pp_inc_gstanddisc: document.getElementById("pp_inc_gst_aft_disc" + i).value,
					avg_pp: document.getElementById("avg_purchase_price" + i).value,
					diff_pp: document.getElementById("diff_purchase_price" + i).value,
					gst: document.getElementById("gst" + i).value,
					pp_per_piece_exc_gst: document.getElementById("pp_per_piece_exc_gst" + i).value,
					gst_amount: document.getElementById("gst_amount" + i).value,
					mrp: document.getElementById("mrp" + i).value,
					cgst: document.getElementById("cgst" + i).value,
					sgst: document.getElementById("sgst" + i).value,
					gst_return: document.getElementById("gst_return" + i).value,


				})
			}
			const nt_amnt = document.getElementById("net_amount")
			nt_amnt.value = currentValue

			setFields(product_list);
			if (document.getElementById("discount")?.value) {
				console.log(currentValue, "dfd")
				var discount = (currentValue - document.getElementById("discount").value)
				var di = document.getElementById("amount_after_discount")
				di.value = discount.toFixed(2)
				var dis = discount.toFixed(2)
				const rounded = Math.round(dis);

				if (dis % 1 != 0) {
					var dc
					if (rounded > dis) {
						var d = rounded - dis
						console.log(d)
						var dec = d.toFixed(2)

						dc = dec
					}
					else {
						var d = dis - rounded
						console.log(d)
						var dec = d.toFixed(2)

						dc = dec
					}

				}
				else {
					dc = 0
				}
				setInput({
					...input, product, amount_after_discount: discount.toFixed(2), net_amount: currentValue.toFixed(2), round_off: dc,
					invoice_amount: rounded
				})


			}
			else {
				const rounded = Math.round(currentValue);

				if (currentValue % 1 != 0) {
					var dc
					if (rounded > currentValue) {
						var d = rounded - currentValue
						console.log(d)
						var dec = d.toFixed(2)

						dc = dec
					}
					else {
						var d = currentValue - rounded
						console.log(d)
						var dec = d.toFixed(2)

						dc = dec
					}

				}
				else {
					dc = 0
				}
				setInput({
					...input, product, round_off: dc,
					invoice_amount: rounded, net_amount: currentValue.toFixed(2)
				})
			}

		}
	};
	const handleFieldQuantityEdit = (i, e) => {

		let product_list = []
		let product = []
		product_list = [...fields]

		if (product_list[i]) {

			const purchase = document.getElementById("purchase-price" + i).value
			const quantity = document.getElementById("quantity" + i).value
			const amount = (document.getElementById("pp_inc_gst_aft_disc" + i).value * document.getElementById("quantity" + i).value)
			const q = document.getElementById("amount" + i)
			q.value = amount
			var currentValue = 0
			for (var i = 0; i < product_list.length; i++) {
				const multiple = document.getElementById("pp_inc_gst_aft_disc" + i).value * document.getElementById("quantity" + i).value
				currentValue += multiple
				product.push({
					product_id: document.getElementById("id" + i).value,
					price: document.getElementById("purchase-price" + i).value,
					quantity: document.getElementById("quantity" + i).value,
					amount: multiple,
					current_stock: document.getElementById("current_stock" + i).value,
					pp_inc_gstanddisc: document.getElementById("pp_inc_gst_aft_disc" + i).value,
					avg_pp: document.getElementById("avg_purchase_price" + i).value,
					diff_pp: document.getElementById("diff_purchase_price" + i).value,
					gst: document.getElementById("gst" + i).value,
					pp_per_piece_exc_gst: document.getElementById("pp_per_piece_exc_gst" + i).value,
					gst_amount: document.getElementById("gst_amount" + i).value,
					mrp: document.getElementById("mrp" + i).value,
					cgst: document.getElementById("cgst" + i).value,
					sgst: document.getElementById("sgst" + i).value,
					gst_return: document.getElementById("gst_return" + i).value,


				})
			}
			const nt_amnt = document.getElementById("net_amount")
			nt_amnt.value = currentValue.toFixed(2)
			setInput({ ...input, net_amount: nt_amnt.value })
			setFields(product_list);
			setValues(product)
			// setInputValues( { ...inputValues,product:product} )

		}


	}
	const handleQuantityEdit = (i, e) => {
		console.log("dsg")
		let product_list = []
		let product = []
		product_list = [...Values]

		if (product_list[i]) {

			const purchase = document.getElementById("purchase-price" + i).value
			const quantity = document.getElementById("quantity" + i).value
			const amount = (document.getElementById("purchase-price" + i).value * document.getElementById("quantity" + i).value)
			const q = document.getElementById("amount" + i)
			q.value = amount.toFixed(2)

			/********************/ 

						if(e.target.name == "total_pp_inc_gst_aft_disc"){
			
				const pp = (document.getElementById("total_pp_inc_gst_aft_disc" + i).value / document.getElementById("quantity" + i).value)
                const purchase_price = document.getElementById("pp_inc_gst_aft_disc" + i)
				purchase_price.value = pp.toFixed(2)

				/*********/ 
				const amount = (document.getElementById("total_pp_inc_gst_aft_disc" + i).value)
				const q = document.getElementById("amount" + i)
				q.value = amount
				/*********/ 
			}else{
				const total_pp_inc_gst_aft_disc = (document.getElementById("pp_inc_gst_aft_disc" + i).value * document.getElementById("quantity" + i).value)
				const sum_pp_inc_gst_aft_disc = document.getElementById("total_pp_inc_gst_aft_disc" + i)
				sum_pp_inc_gst_aft_disc.value = total_pp_inc_gst_aft_disc.toFixed(2)
				
				const amount = (document.getElementById("purchase-price" + i).value * document.getElementById("quantity" + i).value)
				const q = document.getElementById("amount" + i)
				
				q.value = total_pp_inc_gst_aft_disc.toFixed(2)
				// q.value = amount.toFixed(2)
			}
			if((document.getElementById("quantity" + i).value) && !(document.getElementById("pp_inc_gst_aft_disc" + i).value))
			{
				const avg_pp = document.getElementById("avg_purchase_price" + i)
				avg_pp.value = ""
			}
			
             
			if((document.getElementById("purchase-price" + i).value) && (document.getElementById("pp_inc_gst_aft_disc" + i).value))
			{ console.log("okkkkkkkkkkkk")
				const old_pp = parseFloat(document.getElementById("purchase-price" + i).value)
				const new_pp = parseFloat(document.getElementById("pp_inc_gst_aft_disc" + i).value)
                var diff_pp = ""
				if(old_pp >= new_pp)
				{
					 diff_pp = old_pp - new_pp
				}else{
					 diff_pp = new_pp - old_pp
				}

				const diffrence = document.getElementById("diff_purchase_price" + i)
				diffrence.value = diff_pp.toFixed(2)

			}
			console.log("okkkkkkkkkkkk")

			if((document.getElementById("quantity" + i).value) && (document.getElementById("pp_inc_gst_aft_disc" + i).value))
			{
				const total_stock = parseFloat(document.getElementById("quantity" + i).value) + parseFloat(document.getElementById("current_stock" + i).value)
				const mulavg1 = document.getElementById("quantity" + i).value * document.getElementById("pp_inc_gst_aft_disc" + i).value
			    const mulavg2 = document.getElementById("current_stock" + i).value * document.getElementById("purchase-price" + i).value 

				const totalmulavg = parseFloat(mulavg1) + parseFloat(mulavg2)
                // const avgpp = totalmulavg/total_stock
				const avgpp = 0
				console.log(avgpp,"avggggggggggggg")
				const avg_pp = document.getElementById("avg_purchase_price" + i)
				avg_pp.value = avgpp.toFixed(2)
				
			}

			if(document.getElementById("gst" + i).value && document.getElementById("avg_purchase_price" + i).value)
			{

				console.log("edit gstttttttttttttttt")
				/******gstamount********/

				var total_gst = (document.getElementById("gst" + i).value)
				var avgppforgst = document.getElementById("avg_purchase_price" + i).value
				var ppforgst = document.getElementById("pp_inc_gst_aft_disc" + i).value
				
				//var pp_ex_gst = avgppforgst * (100/(100+(parseFloat(total_gst))))
				var pp_ex_gst = ppforgst * (100/(100+(parseFloat(total_gst))))
				const pp_ex_gsts = document.getElementById("pp_per_piece_exc_gst" + i)
				const pp_ex_gst_per_piece = pp_ex_gst
				pp_ex_gsts.value = pp_ex_gst.toFixed(2)

				var gst_amount_final = (pp_ex_gst_per_piece * ((total_gst)/100)).toFixed(2)

				var split_gst = (gst_amount_final)/2


                const gst_amount_f = document.getElementById("gst_amount" + i)
				const cgst_amount = document.getElementById("cgst" + i)
				const sgst_amount = document.getElementById("sgst" + i)
				
				gst_amount_f.value = gst_amount_final
				cgst_amount.value = split_gst.toFixed(2)
				sgst_amount.value = split_gst.toFixed(2)			
			
			}

			if(!document.getElementById("gst" + i).value || !document.getElementById("avg_purchase_price" + i).value)
			{
				const cgst_amount = document.getElementById("cgst" + i)
				const sgst_amount = document.getElementById("sgst" + i)
				const pp_ex_gsts = document.getElementById("pp_per_piece_exc_gst" + i)
				const gst_amount_f = document.getElementById("gst_amount" + i)
				
				cgst_amount.value = ""
				sgst_amount.value = ""
				pp_ex_gsts.value = ""
				gst_amount_f.value = ""
			}
			 
			if(document.getElementById("gst" + i).value && document.getElementById("mrp" + i).value && document.getElementById("pp_per_piece_exc_gst" + i).value)
              {
				 const total_gsts = parseFloat(document.getElementById("gst" + i).value)
                 const mrp = parseFloat(document.getElementById("mrp" + i).value)
				 const pp_per_piece_exc_gst = parseFloat(document.getElementById("pp_per_piece_exc_gst" + i).value)
                 
				 var gstreturn = ((mrp * (100/(100+total_gsts))).toFixed(2)) - (pp_per_piece_exc_gst)
				 gstreturn = ((gstreturn).toFixed(2))*(total_gsts/100)

				 const return_gst_field = document.getElementById("gst_return" + i)
				 return_gst_field.value = gstreturn.toFixed(2)


				 
			  }
			  else{
				const gst_return = document.getElementById("gst_return" + i)
				gst_return.value = ""
			  }


			/******************/ 
			var currentValue = 0
			for (var i = 0; i < product_list.length; i++) {
				var multiple = document.getElementById("pp_inc_gst_aft_disc" + i).value * document.getElementById("quantity" + i).value
				currentValue += multiple
				console.log(document.getElementById("id" + i).value, "xx")
				product.push({
					product_id: document.getElementById("id" + i).value,
					price: document.getElementById("purchase-price" + i).value,
					quantity: document.getElementById("quantity" + i).value,
					amount: multiple.toFixed(2),
					current_stock: document.getElementById("current_stock" + i).value,
					pp_inc_gstanddisc: document.getElementById("pp_inc_gst_aft_disc" + i).value,
					avg_pp: document.getElementById("avg_purchase_price" + i).value,
					diff_pp: document.getElementById("diff_purchase_price" + i).value,
					gst: document.getElementById("gst" + i).value,
					pp_per_piece_exc_gst: document.getElementById("pp_per_piece_exc_gst" + i).value,
					gst_amount: document.getElementById("gst_amount" + i).value,
					mrp: document.getElementById("mrp" + i).value,
					cgst: document.getElementById("cgst" + i).value,
					sgst: document.getElementById("sgst" + i).value,
					gst_return: document.getElementById("gst_return" + i).value,



				})
			}
			console.log(currentValue, "hhh")
			const nt_amnt = document.getElementById("net_amount")
			nt_amnt.value = currentValue.toFixed(2)
			setInput({ ...input, net_amount: nt_amnt.value })
			setValues(product)
			setFields(product_list);
			if (document.getElementById("discount")?.value) {
				console.log(currentValue, "vv")
				const discount = (currentValue - document.getElementById("discount").value)
				const di = document.getElementById("amount_after_discount")
				di.value = discount.toFixed(2)
				var dis = discount.toFixed(2)
				const rounded = Math.round(dis);

				if (dis % 1 != 0) {
					var dc
					if (rounded > dis) {
						var d = rounded - dis
						console.log(d)
						var dec = d.toFixed(2)

						dc = dec
					}
					else {
						var d = dis - rounded
						console.log(d)
						var dec = d.toFixed(2)

						dc = dec
					}

				}
				else {
					dc = 0
				}

				setInput({
					...input, amount_after_discount: discount.toFixed(2), net_amount: currentValue.toFixed(2), round_off: dc,
					invoice_amount: rounded
				})

			}
			else {
				const rounded = Math.round(currentValue);

				if (currentValue % 1 != 0) {
					var dc
					if (rounded > currentValue) {
						var d = rounded - currentValue
						console.log(d)
						var dec = d.toFixed(2)

						dc = dec
					}
					else {
						var d = currentValue - rounded
						console.log(d)
						var dec = d.toFixed(2)

						dc = dec
					}

				}
				else {
					dc = 0
				}
				setInput({
					...input, round_off: dc,
					invoice_amount: rounded, net_amount: currentValue.toFixed(2)
				})
			}

		}


	}
	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		event.preventDefault();
		setDouble(false);
		if (validateForm())

		sendNonFuelProductPurchase()

	}
	function validateForm() {
		let errors = []
		let formIsValid = true

		if (!input.vendor_id) {
			formIsValid = false;
			setDouble(true)
			errors["vendor_id"] = "Vendor is required"
		}
		if (document.getElementById("m_op_date").value == '') {
			formIsValid = false;
			setDouble(true)
			errors["invoice_date"] = "Date is required"
		}
		if (!input.invoice_number) {
			formIsValid = false;
			setDouble(true)
			errors["invoice_number"] = "Invoice number is required"
		}
		if (!input.purchase_type) {
			formIsValid = false;
			setDouble(true)
			errors["purchase_type"] = "Purchase Type is required"
		}
		var values = [...fields]
		var er = []
		var err = []
		var errr = []
		var errrpp = []
		var errrppp = []
		var gst = []
		var mrp = []

		for (var i = 0; i < values.length; i++) {
			if (document.getElementById("id" + i).value == "Select") {
				formIsValid = false;
				er[i] = "Product is requied"
				setDouble(true);
			}
			if (document.getElementById("quantity" + i).value == "") {
				formIsValid = false;
				err[i] = "Quantity is requied"
				setDouble(true);
			}
			console.log(document.getElementById("purchase-price" + i)?.value)
			if (document.getElementById("purchase-price" + i).value == "") {
				formIsValid = false;
				errr[i] = "Purchase price is requied"
				setDouble(true);
			}
			

			if (document.getElementById("purchase-price" + i)?.value <= 0) {
				console.log("ds")
				formIsValid = false;
				errr[i] = "Purchase price should be greater than zero"
				setDouble(true);
			}

			if (document.getElementById("pp_inc_gst_aft_disc" + i).value == "") {
				formIsValid = false;
				errrpp[i] = "Purchase price inc gst after discount is requied"
				setDouble(true);
			}

			if (document.getElementById("pp_inc_gst_aft_disc" + i).value <= 0) {
				formIsValid = false;
				errrpp[i] = "Purchase price inc gst after discount should be greater than zero"
				setDouble(true);
			}

			if (document.getElementById("total_pp_inc_gst_aft_disc" + i).value == "") {
				formIsValid = false;
				errrppp[i] = "Total Purchase price inc gst after discount is requied"
				setDouble(true);
			}

			if (document.getElementById("total_pp_inc_gst_aft_disc" + i).value <= 0) {
				formIsValid = false;
				errrppp[i] = "Total Purchase price inc gst after discount should be greater than zero"
				setDouble(true);
			}

			if (document.getElementById("gst" + i).value == "") {
				formIsValid = false;
				gst[i] = "GST is requied"
				setDouble(true);
			}

			if (document.getElementById("gst" + i).value <= 0) {
				
			}

			if (document.getElementById("mrp" + i).value == "") {
				formIsValid = false;
				mrp[i] = "MRP is requied"
				setDouble(true);
			}

			if (document.getElementById("mrp" + i).value <= 0) {
				formIsValid = false;
				mrp[i] = "MRP should be greater than zero"
				setDouble(true);
			}

		}
		setErrors(errors)
		setErr(er)
		setEr(err)
		setErrr(errr)
		setErrrpp(errrpp)
		setErrrppp(errrppp)
		setGst(gst)
		setMrp(mrp)
		return formIsValid;


	}
	const handleEditSubmit = (event) => {
		event.preventDefault();

		if (validateForm1())
			sendEditNonFuelProductPurchase()
	}
	function validateForm1() {
		let errors = []
		let formIsValid = true

		if (!input.vendor_id) {
			formIsValid = false;
			setDouble(true)
			errors["vendor_id"] = "Vendor is required"
		}
		if (document.getElementById("m_op_date").value == '') {
			formIsValid = false;
			setDouble(true)
			errors["invoice_date"] = "Date is required"
		}
		if (!input.invoice_number) {
			formIsValid = false;
			setDouble(true)
			errors["invoice_number"] = "invoice_number is required"
		}

		var value = [...Values]
		var er = []
		var err = []
		for (var i = 0; i < value.length; i++) {
			if (document.getElementById("id" + i).value == "Select") {
				formIsValid = false;
				er[i] = "Product is requied"
				setDouble(true);
			}
			if (document.getElementById("quantity" + i).value == "") {
				formIsValid = false;
				err[i] = "Quantity is requied"
				setDouble(true);
			}
			if (document.getElementById("purchase-price" + i).value == "") {
				formIsValid = false;
				errr[i] = "Purchase price is requied"
				setDouble(true);
			}
			else if (document.getElementById("purchase-price" + i).value <= 0) {
				formIsValid = false;
				errr[i] = "Purchase price should be greater than zero"
				setDouble(true);
			}
			if (document.getElementById("pp_inc_gst_aft_disc" + i).value == "") {
				formIsValid = false;
				errrpp[i] = "Purchase price inc gst after discount is requied"
				setDouble(true);
			}

			if (document.getElementById("pp_inc_gst_aft_disc" + i).value <= 0) {
				formIsValid = false;
				errrpp[i] = "Purchase price inc gst after discount should be greater than zero"
				setDouble(true);
			}

			if (document.getElementById("total_pp_inc_gst_aft_disc" + i).value == "") {
				formIsValid = false;
				errrppp[i] = "Total Purchase price inc gst after discount is requied"
				setDouble(true);
			}

			if (document.getElementById("total_pp_inc_gst_aft_disc" + i).value <= 0) {
				formIsValid = false;
				errrppp[i] = "Total Purchase price inc gst after discount should be greater than zero"
				setDouble(true);
			}

			if (document.getElementById("gst" + i).value == "") {
				formIsValid = false;
				gst[i] = "GST is requied"
				setDouble(true);
			}

			if (document.getElementById("gst" + i).value <= 0) {
				
			}

			if (document.getElementById("mrp" + i).value == "") {
				formIsValid = false;
				mrp[i] = "MRP is requied"
				setDouble(true);
			}

			if (document.getElementById("mrp" + i).value <= 0) {
				formIsValid = false;
				mrp[i] = "MRP should be greater than zero"
				setDouble(true);
			}

		}
		setErrors(errors)
		setErr(er)
		setEr(err)
		return formIsValid;


	}
	//---Create ---//


	function
		sendNonFuelProductPurchase() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				...input,
				invoice_date: document.getElementById("m_op_date").value,
			})
		};

		fetch(API_URL + "/nf-product-purchase", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				let errors = []
				if (responseData.status == false) {
					errors["vendor_id"] = responseData.response.vendor_id
					errors["invoice_number"] = responseData.response.invoice_number
					errors["invoice_date"] = responseData.response.invoice_date
					errors["purchase_type"] = responseData.response.purchase_type
					setDouble(true)

				}
				if (responseData.status == 3) {
					swal("", responseData.message, responseData.status ? "warning" : "success")
					setDouble(true)
				}
				if (responseData.status == 4) {
					swal("", responseData.message, responseData.status ? "warning" : "success")
					setDouble(true)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					// history.push("/")
					setErrors([''])
					setInputValues([])
					toggle();
					setDouble(true)
					ListNonfuelProductPurchase(pages.current_page);

				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {
				swal("Something went wrong", "", "warning")
				console.log(e)
				setDouble(true)
			});
	}

	function sendEditNonFuelProductPurchase() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				vendor_id: input.vendor_id,
				id: input.id,
				invoice_number: input.invoice_number,
				invoice_date: document.getElementById("m_op_date").value,
				invoice_amount: input.invoice_amount,
				note: input.note,
				purchase_type: input.purchase_type,
				round_off: input.round_off,
				discount: input.discount,
				net_amount: input.net_amount,
				amount_after_discount: input.amount_after_discount,
				i_gst: input.i_gst,
				s_gst: input.s_gst,
				c_gst: input.c_gst,
				op_date: input.op_date,
				product: Values
			})
		};
		fetch(API_URL + "/nf-product-purchase", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				let errors = []
				if (responseData.status == false) {
					errors["vendor_id"] = responseData.response.vendor_id
					errors["invoice_number"] = responseData.response.invoice_number
					errors["purchase_type"] = responseData.response.purchase_type


				}
				if (responseData.status == 3) {
					swal("", responseData.message, responseData.status ? "warning" : "warning")

				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					// history.push("/")
					setErrors([''])
					setInputValues([])
					toggling();
					ListNonfuelProductPurchase(pages.current_page);

				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {
				console.log(e)
			});
	}

	//---Delete ---//	
	function delete_nonfuel(id) {
		if( c_Date == 1){
			swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false?"success":"warning")
		}else{

		deleteId(id)

		toggleModal()
	}
	}
	function deleteNonFuel() {

		setDouble(false)
		const requestOptions = {
			method: 'DELETE',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify(delete_id)
		};
		fetch(API_URL + "/nf-product-purchase/" + delete_id, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				ListNonfuelProductPurchase(pages.current_page);
				toggleModal()
				setDouble(true)
				return responseData;


			})

			.catch(err => {
				swal("Something went wrong", "", "warning")
				console.log(err)
			})
	}

	/*****approval******/
	const UseApproveModal = () => {
		const [isApprove, setApprove] = useState(false);
		function toggleModal2() {
			setApprove(!isApprove);
		}
		return {
			isApprove,
			toggleModal2,
		}
	};
	const { isApprove, toggleModal2 } = UseApproveModal();
	function handleManagerAproval(id, status) {

		if(c_Date == 1){
			swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false ? "success":"warning")
		}else{

		approvalId(id)
		approvalStatus(status)
		toggleModal2()
		}
	}
	
	function handleYes() {
		const requestOptions = {      //---employee listing---//
			method: 'post',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({
				id: approval_id,
				status: approval_status
			})
		};
		fetch(`${API_URL}/nf_product_purchase_approve`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				ListNonfuelProductPurchase(pages.current_page);
				toggleModal2()
				return responseData;
			})
			.catch(e => {

				console.log(e)
			});
	}


	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={8} operation={1} />
					<div className="content-area">
						<ManagerLoginHeader props={props} page_title="" />
						<div className="container pb-3">
							<div className="row mt-md-5 mt-3 form-container">
								{permission.status == 3 ?
									<div className="form-wrap mt-5">
										<div className="text-center">
											{permission.message}
										</div></div> :
									<>

										<div>
											<div className="form-wrap ">
												{loading ? ListNonfuelProductPurchase : <Loader />}
												<div className="tab-content" id="myTabContent">
													<div className="tab-pane fade show active" id="creditsales" role="tabpanel" aria-labelledby="creditsales-tab">
														<div className="">
															<div className="row mb-3">
																<div className="col-md">
																	<div className="row">

																		<div className="col-md col pl-0 col-12">
																			<h3 className="table-title"> Non fuel product purchase </h3>
																			<h4> purchased product will update only after approval</h4>
																		</div>

																	</div>
																</div>
																<div className="col-md-4">
																	<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Add Non fuel product purchase</a>
																</div>
																<div className="col-md-4">
																	<div className="input-group mb-3 search">
																		<span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
																		</span>
																		<input type="text" className="form-control" placeholder="Search " value={search.search_keyword} onChange={handleSearch} />
																		<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>
																	</div>
																</div>
															</div>


															<div className="table-widget">
																<table className="table border col-12">
																	<thead>
																		<tr>

																			<th> Vendor</th>
																			<th>Invoice Number</th>
																			<th className="col-md-1">Invoice Date</th>
																			<th className="col-md-1">Product Name</th>
																			<th>Net Total </th>
																			{/* <th>Total Bill Amount Discount </th>
																			<th>Amount After Discount</th> */}
																			<th>Round Off </th>
																			<th>Invoice Amount </th>
																			<th>Transaction Type</th>
																			<th>CGST</th>
																			<th>SGST</th>
																			<th>IGST</th>
																			<th className="col-md-1">Actions </th>
																		</tr>
																	</thead>
																	<tbody>
																		
																		{list_data.map((obj) => (
																			<tr>

																				<td>{obj.get_vendor.name}</td>
																				<td>{obj.invoice_number}<br /></td>
																				<td>{moment(obj.invoice_date).format("DD-MM-YYYY")}</td>
																				<td>{obj.product_name}</td>
																				<td>{obj.net_amount}</td>
																				{/* <td>{obj.discount}</td>
																				<td>{obj.amount_after_discount}</td> */}
																				<td>{obj.round_off}</td>
																				<td>{obj.invoice_amount}</td>
																				<td>{obj.purchase_type == 1 ? "Credit" : "Cash"}</td>
																			    <td>{obj.get_nf_purchase_product[0]?obj.get_nf_purchase_product[0].cgst:0}</td>
																				<td>{obj.get_nf_purchase_product[0]?obj.get_nf_purchase_product[0].sgst:0}</td>
																				<td>{obj.get_nf_purchase_product[0]?obj.get_nf_purchase_product[0].igst:0}</td>
																				<td className="py-2">
																					<a href="#" className="text-secondary"  >{obj.status == 3 || obj.status == 4 ? <i className="fa fa-eye pe-2" aria-hidden="true" onClick={() => handleEdit(obj.id, obj.status)}></i> : <i className="fa fa-pencil-square-o pe-2" aria-hidden="true" onClick={() => handleEdit(obj.id, obj.status)}></i>}</a>
																					{obj.status != 3 && obj.status != 4 ? <a href="#" className="text-secondary pe-2"><i className="fa fa-trash-o " aria-hidden="true" onClick={() => delete_nonfuel(obj.id)}></i></a> : ''}
																					{permissions.includes("Deny Approval") == true ?
																						<>
																							{obj.status != 3 && obj.status != 4 ? <a href="#" className="text-secondary " onClick={() => handleManagerAproval(obj.id, obj.status)}>{(obj.status == 3) ? <i class="text-success fa fa-check" aria-hidden="true"></i> : <i class="text-warning fa fa-check" aria-hidden="true"></i>}</a> : (obj.status == 3) ? <a><i className="text-success fa fa-check me-2" aria-hidden="true" title="Approved"></i></a> : (obj.status == 4) ? <a><i className="text-success fa fa-check me-2" aria-hidden="true" title="Day Closed"></i></a> : ''}
																						</>
																						: ''}
																				</td>
																			</tr>
																		))}
																	</tbody>
																</table>
																{loading ? <PaginationComp
																	pages={pages}
																	handlePagination={handlePagination}
																/> : ""
																}
															</div>
														</div>
													</div>
												</div>
											</div>


										</div>
									</>}
							</div>


						</div>
					</div>


				</div>
			</div>


			{isShowing ? (<NonFuelPurchaseModal
				isShowing={isShowing}
				hide={toggle}
				handleInputs={handleInputs}
				// inputValues={inputValues}
				input={input}
				fields={fields}
				handleDiscount={handleDiscount}
				handleQuantity={handleQuantity}
				handleProducts={handleProducts}
				preventNonNumericalInput={preventNonNumericalInput}
				product_data={product_data}
				startDate={startDate}
				vendor_data={vendor_data}
				handleFieldQuantityEdit={handleFieldQuantityEdit}
				double={double}
				success={success}
				chek_rk={chek_rk}
				err={err}
				er={er}
				errr={errr}
				errrpp={errrpp}
				errrppp={errrppp}
				gst={gst}
				mrp={mrp}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				handleAdd={handleAdd}
				prevNonNumericalWithDec={prevNonNumericalWithDec}
				handleRemove={handleRemove}
				errors={errors}
			/>) : null
			}
			{isClose ? (<NonFuelEdit
				isOpen={isClose}
				er={er}
				err={err}
				preventNonNumericalInput={preventNonNumericalInput}
				prevNonNumericalWithDec={prevNonNumericalWithDec}
				hide={toggling}
				handleInputs={handleInputs}
				inputValues={inputValues}
				edit_list={edit_list}
				Values={Values}
				errr={errr}
				errrpp={errrpp}
				errrppp={errrppp}
				gst={gst}
				mrp={mrp}
				input={input}
				fields={fields}
				chek_rk={chek_rk}
				startDate={startDate}
				handleEditAdd={handleEditAdd}
				handleEditRemove={handleEditRemove}
				handleDiscount={handleDiscount}
				handleFieldProductsEdit={handleFieldProductsEdit}
				handleQuantityEdit={handleQuantityEdit}
				handleProductsEdit={handleProductsEdit}
				product_data={product_data}
				vendor_data={vendor_data}
				success={success}
				purchase={purchase}
				handleEditSubmit={handleEditSubmit}
				handleChange={handleChange}
				errors={errors}
			/>) : null
			}

			<Modal
				isOpen={isOpen}
				onRequestClose={toggleModal}
				contentLabel="My dialog"
				className="mymodal custommodal"
				overlayClassName="myoverlay"
			>
				<div className="modal-content">
					<div className="modal-title modal-header"><h5 className="modal-title" id="exampleModalLongTitle">Non Fuel Product Purchase</h5><button type="button" onClick={toggleModal} className="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
					<div className=" modal-body  modal-body"><p>Are you sure you want to delete this non fuel product purchase</p></div>
					<div className="justify-content-end  modal-footer"><div className="d-flex">
						<button type="button" className="btn btn-secondary me-2" onClick={toggleModal}>No</button>
						{double == true ? <button type="submit" className="btn btn-primary" onClick={deleteNonFuel}>Yes</button> : <button type="submit" className="btn btn-primary" disabled onClick={deleteNonFuel}>Yes</button>}
					</div>
					</div>
				</div>
			</Modal>
			{isApprove ? (<ApprovalModal
				isApprove={isApprove}
				hide={toggleModal2}
				handleYes={handleYes}
				approval_status={approval_status}
				aprove={1}
			/>) : null
			}
		</>
	)
}








export default NonFuelProductPurchaseConfig