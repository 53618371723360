import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import { Link, useLocation } from "react-router-dom";
import moment from 'moment';

const NonFuelProductPurchaseModal = ({ preventNonNumericalInput,prevNonNumericalWithDec, isShowing, errr, errrpp,errrppp,gst,mrp, product_data, err, startDate, hide, success, double, chek_rk, er, setVehicle, vendor_data, errors, inputValues, handleQuantity, handleProducts, input, handleDiscount, handleAdd, handleRemove, handleChange, fields, dropdownData, handleSubmit, handleInputs }) => {
  var today = new Date().toISOString().split('T')[0];
  const location_details = useLocation()
  return (
    <React.Fragment>

      <div class="container pb-3">


        <div class="row mb-3 justify-content-center">
          <div className="modal-overlay" />
          <div className="modal-wrapper  modal-style" aria-modal aria-hidden tabIndex={-1} role="dialog">
            <div className="modal  modalxxl col-12 ">
              <div class="modal-header mb-3">
                <h5 class=" text-center" id="staticBackdropLabel">non fuel product purchase</h5>
                <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>


              <div >
                <div class="row mb-3 justify-content-center py-3">

                  <div class=" col-md-6 pe-0 ps-4">
                    <div class="row g-3 justify-content-center mb-2">
                      <div class="col-4">
                        <label class="col-form-label">Select Vendor<sup class="star">*</sup></label>
                      </div>
                      <div class="col-6">
                        <div class="select-container">
                          <select id="" class="form-control " name="vendor_id" value={input.vendor_id} onChange={handleInputs}>
                            <option>Select</option>
                            {vendor_data.map((data) => {
                              return <option value={data[0]}>{data[1]}</option>
                            })
                            }
                          </select>


                        </div> <div className="errorMsg">{errors.vendor_id}</div>
                        <Link to={location => ({ ...location, backpage: location_details.pathname, pathname: "/vendor-config/nf-purchase" })} class="link-text"><a href="">Redirect to Vendor configuration</a></Link>
                      </div>
                      <div class="col-2"></div>
                    </div>
                    <div class="row g-3 justify-content-center mb-2">
                      <div class="col-4">
                        <label class="col-form-label">Invoice Number<sup class="star">*</sup></label>
                      </div>
                      <div class="col-6">
                        <input type="text" class="form-control hide-arrow" name="invoice_number" value={input.invoice_number} onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} onChange={handleInputs}></input>
                        <div className="errorMsg">{errors.invoice_number}</div>

                      </div>
                      <div class="col-2"></div>
                    </div>
                    <div class="row g-3 justify-content-center mb-2">
                      <div class="col-4">
                        <label class="col-form-label">Invoice Date<sup class="star">*</sup></label>
                      </div>
                      <div class="col-6">
                        <input type="date" class="form-control " name="invoice_date" id={"m_op_date"} value={input.invoice_date == undefined ? (localStorage.getItem("m_op_date") < startDate ? (startDate>today?" ":startDate) :localStorage.getItem("m_op_date")) :input.invoice_date} min={startDate} max={today} onChange={handleInputs} disabled></input>
                        <div className="errorMsg">{errors.invoice_date}</div>
                      </div>
                      <div class="col-2"></div>
                    </div>

                    {fields.map((obj, idx) => {

                      return (
                        <>
                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">Product Name<sup class="star">*</sup></label>
                            </div>
                            <div class="col-6">
                              <div class="select-container">
                                <select class="form-control rackids  product " value={obj}  name="product_id" id={"id" + idx} onChange={(event) => handleProducts(idx, event)}>
                                  <option>Select</option>

                                  {product_data.map((data) => {
                                    return <option class={chek_rk.includes(data[0]) == true ? "d-none" : ''} value={data[0]}>{data[1]}</option>
                                  })
                                  }
                                </select>

                              </div>
                              <div className="errorMsg">{err[idx]}</div>
                            </div>
                            <div className="col-2">
                              <button className="btn-add   me-2" onClick={handleAdd}>+</button>
                              <button className="btn-add   " onClick={() => handleRemove(idx)}>-</button>

                            </div>
                          </div>
                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">Number of Purchase Quantity11<sup class="star">*</sup></label>
                            </div>
                            <div class="col-6">
                              <input type="number" class="form-control hide-arrow " onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} name="quantity" id={"quantity" + idx} onChange={(event) => handleQuantity(idx, event)}></input>
                              <div className="errorMsg">{er[idx]}</div>
                            </div>

                            <div class="col-2"></div>
                          </div>
                        {/* pp/piece inc gst aft discount */}
                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">Purchase Price/piece inc gst after discount<sup class="star">*</sup></label>

                            </div>
                            <div class="col-6">
                              <input type="number" class="form-control hide-arrow" name="pp_inc_gst_aft_disc" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} id={"pp_inc_gst_aft_disc" + idx} onChange={(event) => handleQuantity(idx, event)}></input>
                              <div className="errorMsg">{errrpp[idx]}</div>
                            </div>
                            <div class="col-2"></div>
                          </div>
                        {/*  */}

                        {/* total amount pp inc gst aft disc */}
                            
                        <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">Total Purchase Price inc gst aft disc<sup class="star">*</sup></label>

                            </div>
                            <div class="col-6">
                              <input type="number" class="form-control hide-arrow" name="total_pp_inc_gst_aft_disc" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} id={"total_pp_inc_gst_aft_disc" + idx} onChange={(event) => handleQuantity(idx, event)}></input>
                              <div className="errorMsg">{errrppp[idx]}</div>
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              {/* <label class="col-form-label">Current Stock</label> */}

                            </div>
                            <div class="col-6">
                              <input hidden type="number" readOnly class="form-control hide-arrow" name="current_stock" id={"current_stock" + idx}></input>
                              {/* <div className="errorMsg">{errr[idx]}</div> */}
                            </div>
                            <div class="col-2"></div>
                          </div>


                          {/* *************** */}

                          {/* prev pur price */}

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              {/* <label class="col-form-label">Previous Purchase Price inc gst</label> */}

                            </div>
                            <div class="col-6">
                              <input hidden readOnly type="number" class="form-control hide-arrow" name="price"  id={"purchase-price" + idx} ></input>
                              <div className="errorMsg">{errr[idx]}</div>
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              {/* <label class="col-form-label">average Purchase Price inc gst</label> */}

                            </div>
                            <div class="col-6">
                              <input hidden readOnly type="number" class="form-control hide-arrow" name="avg_purchase_price"  id={"avg_purchase_price" + idx} ></input>
                              {/* <div className="errorMsg">{errr[idx]}</div> */}
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              {/* <label class="col-form-label">Diffrence in Purchase Price</label> */}

                            </div>
                            <div class="col-6">
                              <input hidden readOnly type="number" class="form-control hide-arrow" name="diff_purchase_price"  id={"diff_purchase_price" + idx} ></input>
                              {/* <div className="errorMsg">{errr[idx]}</div> */}
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">GST<sup class="star">*</sup></label>

                            </div>
                            <div class="col-6">
                              <input  type="number" class="form-control hide-arrow" name="gst"  id={"gst" + idx} onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  onChange={(event) => handleQuantity(idx, event)}></input>
                              <div className="errorMsg">{gst[idx]}</div>
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">Purchase price/piece exc gst</label>

                            </div>
                            <div class="col-6">
                              <input readOnly  type="number" class="form-control hide-arrow" name="pp_per_piece_exc_gst"  id={"pp_per_piece_exc_gst" + idx} onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  onChange={(event) => handleQuantity(idx, event)}></input>
                              {/* <div className="errorMsg">{errr[idx]}</div> */}
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">gstamount</label>

                            </div>
                            <div class="col-6">
                              <input readOnly  type="number" class="form-control hide-arrow" name="gstamount"  id={"gst_amount" + idx} onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  onChange={(event) => handleQuantity(idx, event)}></input>
                              {/* <div className="errorMsg">{errr[idx]}</div> */}
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">CGST</label>

                            </div>
                            <div class="col-6">
                              <input readOnly type="number" class="form-control hide-arrow" name="cgst"  id={"cgst" + idx} ></input>
                              {/* <div className="errorMsg">{errr[idx]}</div> */}
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">SGST</label>

                            </div>
                            <div class="col-6">
                              <input readOnly type="number" class="form-control hide-arrow" name="sgst"  id={"sgst" + idx} ></input>
                              {/* <div className="errorMsg">{errr[idx]}</div> */}
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">Mrp/piece<sup class="star">*</sup></label>

                            </div>
                            <div class="col-6">
                              <input  type="number" class="form-control hide-arrow" name="mrp"  id={"mrp" + idx} onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  onChange={(event) => handleQuantity(idx, event)}></input>
                              <div className="errorMsg">{mrp[idx]}</div>
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">GST return</label>

                            </div>
                            <div class="col-6">
                              <input readOnly  type="number" class="form-control hide-arrow" name="gst_return"  id={"gst_return" + idx} onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  onChange={(event) => handleQuantity(idx, event)}></input>
                              {/* <div className="errorMsg">{errr[idx]}</div> */}
                            </div>
                            <div class="col-2"></div>
                          </div>



                          {/* ************** */}
                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              {/* <label class="col-form-label">Total Purchase Price </label> */}
                            </div>
                            <div class="col-6">
                              <input hidden type="text" class="form-control " name="amount" id={"amount" + idx} readOnly onChange={handleInputs}></input>
                            </div>
                            <div class="col-2"></div>
                          </div>
                        </>

                      );
                    })}
                  </div>
                  <div class="col-md-6">
                    <div class="row g-3 justify-content-center mb-2">
                      <div class="col-5   ">
                        <label class="col-form-label ">Net Total</label>
                      </div>
                      <div class="col-6">
                        <input type="number" class="form-control hide-arrow" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} name="net_amount" id={"net_amount"} readOnly value={input.net_amount}></input>
                      </div>

                    </div>
                    <div class="row g-3 justify-content-center mb-2">
                      <div class="col-5">
                        <label class="col-form-label">Total Bill Amount Discount</label>
                      </div>
                      <div class="col-6">
                        <input type="number" class="form-control hide-arrow " name="discount" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} id={"discount"} value={input.discount} onChange={handleDiscount} ></input>
                      </div>

                    </div>
                    <div class="row g-3 justify-content-center mb-2">
                      <div class="col-5">
                        <label class="col-form-label">Amount After Discount</label>
                      </div>
                      <div class="col-6">
                        <input type="text" class="form-control" name="amount_after_discount" readOnly id={"amount_after_discount"} value={input.amount_after_discount} onChange={handleInputs}></input>
                      </div>

                    </div>
                    <div class="row g-3 justify-content-center mb-2">
                      <div class="col-5">
                        <label class="col-form-label">Round Off</label>
                      </div>
                      <div class="col-6">
                        <input type="text" class="form-control " name="round_off" readOnly id={"round_off"} value={input.round_off} onChange={handleInputs}></input>
                      </div>

                    </div>
                    <div class="row g-3 justify-content-center mb-2">
                      <div class="col-5">
                        <label class="col-form-label">Invoice Amount</label>
                      </div>
                      <div class="col-6">
                        <input type=" number" class="form-control" name="invoice_amount" id={"invoice_amount"} value={input.invoice_amount} readOnly onChange={handleInputs}></input>
                      </div>

                    </div>
                    <div class="row g-3 justify-content-center mb-2">
                      <div class="col-5">
                        <label class="col-form-label">Transaction Type<sup class="star">*</sup></label>
                      </div>
                      <div class="col-6">
                        <div class="select-container">
                          <select id="" class="form-control " name="purchase_type" value={input.purchase_type} onChange={handleInputs}>
                            <option >Select</option>
                            <option value="0">Cash</option>
                            <option value="1">Credit</option>
                          </select>
                          <div className="errorMsg">{errors.purchase_type}</div>
                        </div>
                      </div>

                    </div>
                    <div class="row g-3 justify-content-center mb-2">
                      <div class="col-5">
                        <label class="col-form-label">Description</label>
                      </div>
                      <div class="col-6">
                        <textarea type="text" class="form-control " name="note" value={input.note} onChange={handleInputs}></textarea>
                      </div>

                    </div>
                     {/*
                    <div class="row g-3 justify-content-center mb-2">
                      <div class="col-5">
                        <label class="col-form-label">CGST</label>
                      </div>
                      <div class="col-6">
                        <input type="number" class="form-control hide-arrow" min={0} name="c_gst" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} value={input.c_gst} onChange={handleInputs}></input>
                      </div>

                    </div>

                    <div class="row g-3 justify-content-center mb-2">
                      <div class="col-5">
                        <label class="col-form-label">SGST</label>
                      </div>
                      <div class="col-6">
                        <input type="number" class="form-control hide-arrow" name="s_gst" min={0} onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} value={input.s_gst} onChange={handleInputs}></input>
                      </div>

                    </div>
                    <div class="row g-3 justify-content-center mb-2">
                      <div class="col-5">
                        <label class="col-form-label">IGST</label>
                      </div>
                      <div class="col-6">
                        <input type="number" class="form-control hide-arrow" name="i_gst"  min={0} onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} value={input.i_gst} onChange={handleInputs}></input>
                      </div>

                    </div>
                     */}

                  </div>
                  <div class="modal-footer text-center">

                    <a href="#" class="btn btn-danger w-auto" onClick={hide}>Cancel</a>
                    {double === true ? <a href="#" class="btn btn-primary  w-auto " onClick={handleSubmit}>Save</a> : <a href="#" class="btn btn-primary  w-auto " disabled >Save</a>}
                  </div>
                </div>
                <div className="text-end">
                  <Link to="/NonFuel-product-Selling-Price-Change"> <a href="/NonFuel-product-Selling-Price-Change" class=" text-danger text-end mx-3">Changing Selling Price</a></Link></div>

              </div>
            </div>
          </div>
        </div>
      </div>



    </React.Fragment>

  );
}
export default NonFuelProductPurchaseModal