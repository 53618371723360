import { Component } from "react";
import logo from '../../img/logo.svg';
import avatar from '../../img/avatar.svg'
import dashboard from '../../img/dashboard.png'
import settings from '../../img/settings.png'
import user from '../../img/operation.png'
import operation from '../../img/operation.png'
import activity from '../../img/activity.png'
import fuelstation from '../../img/fuel-station.png'
import purchasing from '../../img/purchasing.png'
import dispenser from '../../img/dispenser.png'
import fueltruck from '../../img/fuel-truck.png'
import scale from '../../img/scale.png'
import fuel from '../../img/fuel.png'
import tank from '../../img/tank.png'
import { Link, useLocation } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import { WEB_URL } from "../../constant/API_Settings"

import { API_URL } from "../../constant/API_Settings"
const Operational_Sidebar_Progress = (progress, operation) => {

  const location_details = useLocation()
  let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");


  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)
  const [show4, setShow4] = useState(false)
  const [show5, setShow5] = useState(false)
  const [show6, setShow6] = useState(false)
  const [show7, setShow7] = useState(false)
  const [show8, setShow8] = useState(false)
  // console.log(progress)
  useEffect(() => {
    check_meta_date();

  }, []);
  function check_meta_date() {

    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      }
    };
    fetch(`${API_URL}/check_meta_date`, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        // this.setState({ op_date:responseData.op_date,userId:responseData.userId})
        setUser({ user_id: responseData.userId })


        return responseData;
      })
      .catch(e => {
      });

  }
  function toggle() {
    setShow(!show)
    setShow1(false)
    setShow2(false)
    setShow4(false)
    setShow5(false)
    setShow3(false)
    setShow6(false)
  }
  function toggle1() {
    setShow1(!show1)
    setShow(false)
    setShow2(false)
    setShow4(false)
    setShow5(false)
    setShow3(false)
    setShow6(false)
  }
  function toggle2() {
    setShow2(!show2)
    setShow(false)
    setShow1(false)
    setShow4(false)
    setShow5(false)
    setShow3(false)
    setShow6(false)
  }
  function toggle3() {
    setShow3(!show3)
    setShow(false)
    setShow1(false)
    setShow4(false)
    setShow5(false)
    setShow6(false)
  }
  function toggle4() {
    setShow3(false)
    setShow(false)
    setShow1(false)
    setShow4(!show4)
    setShow5(false)
    setShow6(false)
  }
  function toggle5() {
    setShow3(false)
    setShow(false)
    setShow1(false)
    setShow4(false)
    setShow5(!show5)
    setShow6(false)
  }
  function toggle6() {
    setShow3(false)
    setShow(false)
    setShow2(false)
    setShow1(false)
    setShow4(false)
    setShow5(false)
    setShow6(!show6)
  }
  const [user, setUser] = useState()
  const [userName, setUserName] = useState(localStorage.getItem("PROFILE_NAME"))
  return (
    <div className="left-wrap p-0">

      <div className="d-flex">
        <div className="logo-wrp">
          <a className="logo-continer text-center" href="#"><img src={logo} /></a>
        </div>
        <div className="d-md-none pe-4 ms-auto">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

            <li className="nav-item dropdown notify">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="img/bell-white.svg" width="20px;" />
                <span className="badge bg-danger">2</span>
              </a>
              <ul className="dropdown-menu notification shadow" aria-labelledby="navbarDropdown">
                <li><h4 className="notify-head">Notification 1</h4></li>
                <li><a className="dropdown-item" href="#"><strong>Lorem Ipsum</strong><span>Lorem Ipsum is simply dummy text of the printing</span><small>2 day ago</small></a></li>
                <li><a className="dropdown-item" href="#"><strong>Lorem Ipsum</strong><span>Lorem Ipsum is simply dummy text of the printing</span><small>2 day ago</small></a></li>
                <li><a className="dropdown-item text-center notify-all" href="#">show all notifications</a></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle user-head" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img className="img-profile rounded-circle" src="img/avatar.svg" />{userName}<span><i className="fa fa-angle-down font-weight-bolder" aria-hidden="true"></i></span>
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {/* <li><a className="dropdown-item" href="#">Profile</a></li>
                <li><Link to="/operational-settings"><a className="dropdown-item" href="#">Settings</a></Link></li>
                <li><hr className="dropdown-divider" /></li> */}
                <li><Link className="dropdown-item" to="/logout"><a className="dropdown-item" href="#">Logout</a></Link></li>
              </ul>
            </li>

          </ul>
        </div>
      </div>
      <div class="responsive">
        <div class="profile-pic">
          <img src={avatar} class="img-fluid" />
        </div>

      </div>

      <ul className="sidemenu">
        {permissions.includes("Dashboard") == true ?
          <li class={progress.progress == 22 ? "active" : ""} ><Link to="/dashboard"><p> <img src={dashboard} class="img-fluid " /> <span class="ms-2 text-white" >Dashboard</span></p></Link> </li>
          : ''}
        <li className={progress.operation == 1 ? "active" : ""}>
          <a onClick={toggle} href="#" ><p >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
              <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
              <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
            </svg>

            <span class="ms-2 text-white" >Operations</span></p></a>
          {show &&
            <ul className="sidesubmenu">

              {permissions.includes("Fuel Price change") || permissions.includes("FuelPricechange-create") || permissions.includes("FuelPricechange-edit") ?
                <>
                  {progress.progress == 1 ?

                    <li class=" active" >

                      <Link to={"/operational-date"}>	<a href="/operational-date" ><p class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Fuel Selling Price Change</p></a></Link>
                    </li> :
                    <li class="" >
                      <Link to={"/operational-date"}>	<a href="/operational-date" ><p class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Fuel Selling Price Change</p></a></Link></li>
                  }

                  {
                    progress.progress == 100 ?
                      <li class=" active" >
                        <Link to={"/operational-dates"}>	<a href="/operational-dates" ><p class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Operation date</p></a></Link>
                      </li> :
                      <li class="" >
                        <Link to={"/operational-dates"}>	<a href="/operational-dates" ><p class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Operation date</p></a></Link>
                      </li>
                  }
                </>


                : ''}

              {permissions.includes("Fuel Unloading") == true ?
                <li class={progress.progress == 0 ? " active" : ""}>
                  <Link to={"/op-fuelunloading"}>	<a href="/op-fuelunloading" class="hai"><p class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Fuel Purchase And Unloading</p></a></Link>
                </li>
                : ''}
              <li class={progress.progress == 25 ? " active" : ""}>
                <Link to={"/nozzle-employee-list"}>  <a href="/nozzle-employee-list" class="hai"><p
                  class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Employee Closing</p></a></Link>
              </li>
              {permissions.includes("Fuel Unloading") == true ?
                <li class={progress.progress == 27 ? " active" : ""}>
                  <Link to={"/nonfuel-product-direct"}>  <a href="/nonfuel-product-direct" class="hai"><p
                    class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Non Fuel Direct Sales</p></a></Link>
                </li>
                : ''}

                {permissions.includes("Fuel Unloading") == true ?
                <li class={progress.progress == 20 ? " active" : ""}>
                  <Link to={"/gas_cylinder_sales"}>  <a href="/gas_cylinder_sales" class="hai"><p
                    class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Gas Cylinder Sales</p></a></Link>
                </li>
                : ''}

              {permissions.includes("Fuel Unloading") == true ?
                <li class={progress.progress == 26 ? " active" : ""}>
                  <Link to={"/oil-barrel-sales"}>  <a href="/oil-barrel-sales" class="hai"><p
                    class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Oil Barrel Sales</p></a></Link>
                </li>
                : ''}
                   {permissions.includes("Fuel Unloading") == true ?
                <li class={progress.progress == 31 ? " active" : ""}>
                  <Link to={"/loose-oil-machine"}>  <a href="/loose-oil-machine" class="hai"><p
                    class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Oil-Barrel stock transfer to machine</p></a></Link>
                </li>
                : ''}

              {!permissions.includes("Customer Attendant") ?
                permissions.includes("Non Fuel Product Direct Sales") == true ?
                  <li class={progress.progress == 8 ? " active" : ""}>
                    <Link to={"/nonfuel-product-purchase"}><a href="/nonfuel-product-purchase" class="hai"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Non Fuel Purchase</p> </a></Link>
                  </li>
                  : '' : ''}
                    {!permissions.includes("Customer Attendant") ?
                permissions.includes("Non Fuel Product Direct Sales") == true ?
                  <li class={progress.progress == 29 ? " active" : ""}>
                    <Link to={"/gas_cylinder_purchase"}><a href="/gas_cylinder_purchase" class="hai"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Gas Cylinder Purchase</p> </a></Link>
                  </li>
                  : '' : ''}
{/* 
{!permissions.includes("Customer Attendant") ?
                permissions.includes("Non Fuel Product Direct Sales") == true ?
                  <li class={progress.progress == 8 ? " active" : ""}>
                    <Link to={"/gas_cylinder_sales"}><a href="/gas_cylinder_sales" class="hai"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Gas Cylinder Sales</p> </a></Link>
                  </li>
                  : '' : ''} */}

              {permissions.includes("Product Transfer To Rack") || permissions.includes("Rack Configuration") ?
                <li class={progress.progress == 2 ? " active" : ""}>
                  <Link to={"/product-transfer-rack"}>  <a href="/product-transfer-rack" class="hai"><p
                    class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Product  To Rack</p></a></Link>
                </li>
                : ''}

              {permissions.includes("DSR") == true ?
                <li class={progress.progress == 3 ? " active" : ""}>
                  <Link to={"/op-dsr-Report"}> <a href="/op-dsr-Report" class="hai"><p
                    class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>DSR</p></a></Link>
                </li>
                : ''}
              {permissions.includes("Density") == true ?
                <li class={progress.progress == 4 ? " active" : ""}>
                  <Link to={"/op-density-Report"}><a href="/op-density-Report" class="hai"><p
                    class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Density</p></a></Link>
                </li>
                : ''}
              {permissions.includes("Return Goods of Non Fuel Products") == true ?
                <li class={progress.progress == 5 ? " active" : ""} >
                  {/* <Link to={"/purchase-return-non-fuel"}>  <a href="/purchase-return-non-fuel" class="hai"><p
                    class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Purchase Return of Non Fuel</p></a></Link> */}
                </li>
                : ''}
              {permissions.includes("Non Fuel Product Price Change") == true ?
                <li class={progress.progress == 6 ? " active" : ""}>
                  <Link to={"/NonFuel-product-Selling-Price-Change"}> <a href="/NonFuel-product-Selling-Price-Change" class="hai"><p
                    class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Non Fuel Selling Price Change</p></a></Link>
                </li>
                : ''}
              {permissions.includes("Day Close") == true ?
                <li class={progress.progress == 10 ? " active" : ""}>
                  <Link to={location => ({ backpage: location_details.pathname, pathname: "/day-closing" })}> <a href="#" class="hai"><p
                    class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Day Closing</p></a></Link>
                </li>
                : ''}
              {/* {permissions.includes("Edit Fuel Configuration") == true ?
                <li class={progress.progress == 30 ? " active" : ""}>
                  <Link to={"/op-fuel-edit"}> <a href="/op-fuel-edit" class="hai"><p class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Edit Fuel Configuration</p></a></Link>
                </li>
                : ''} */}

            </ul>
          }
        </li>

        {!permissions.includes("Customer Attendant") ?
          permissions.includes("Payment Voucher") || permissions.includes("Receipt Voucher") || permissions.includes("Account to Account Transfer") ?
            <>
              <li className={progress.operation == 2 ? "active" : ""}><a onClick={toggle1} class="text-white " href="#">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-bank2" viewBox="0 0 16 16">
                    <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z" />
                  </svg>

                  <span class="ms-2" href="#">  Accounts</span></p></a>
                {show1 &&
                  <ul className="sidesubmenu">
                    {permissions.includes("Payment Voucher") == true ?
                      <li class={progress.progress == 11 ? " active" : ""}>
                        <Link to={"/payment"}>	<a href="/payment" class="hai"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Payment Voucher</p></a></Link>
                      </li>
                      : ''}
                    {permissions.includes("Receipt Voucher") == true ?
                      <li class={progress.progress == 12 ? " active" : ""}>
                        <Link to={"/receipt"}>  <a href="/receipt" class="hai"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Receipt Voucher</p></a></Link>
                      </li>
                      : ''}
                    {permissions.includes("Account to Account Transfer") == true ?
                      <li class={progress.progress == 13 ? " active" : ""}>
                        <Link to={"/account-to-account-transfer"}>  <a href="/account-to-account-transfer" class="hai"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>A/c To A/c Transfer</p></a></Link>
                      </li>
                      : ''}
                  </ul>
                }
              </li>
            </>
            : '' : ''}

        <ul>
          {
            permissions.includes("Fuel Price change") || permissions.includes("FuelPricechange-create") || permissions.includes("FuelPricechange-edit") ?
              <>
                <li><a target="_blank" href={`${WEB_URL}/other-report/${user?.user_id}/stock-gain-loss`} class="side-m"><p> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-file-earmark-spreadsheet-fill" viewBox="0 0 16 16">
                  <path d="M6 12v-2h3v2H6z" />
                  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2H3V9z" />
                </svg> <span class="ms-2 text-white" >Stock Gain or Loss</span></p></a>
                </li>
              </>
              : ''
          }
        </ul>

        {permissions.includes("All Reports") == true ?
          <li class=""><a href="#" onClick={toggle2} >
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-file-earmark-spreadsheet-fill" viewBox="0 0 16 16">
                <path d="M6 12v-2h3v2H6z" />
                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2H3V9z" />
              </svg>

              <span class="ms-2"> Reports</span></p></a>
            {show2 &&
              <ul className="sidesubmenu">
                <li>
                  <a onClick={toggle3} href="#" class="let"><p>
                    <span class="ms-3"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Operation Reports</span></p> </a>

                  {show3 || progress.operation == 3 ?
                    <ul className="sidesubmenu">

                      <li className={progress.progress == 14 ? "  active" : ""}>
                        <a target="_blank" href={`${WEB_URL}/other-report/${user.user_id}/daily-transaction-sheet`} ><p class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Daily Transaction</p></a>
                      </li>
                      {permissions.includes("Employee Worksheet") == true ?
                        <li className={progress.progress == 9 ? "  active" : ""} >
                          <Link to={"/op-worksheet"}>
                            <a href="/op-worksheet" className={progress.progress == 9 ? "active" : ""}><p class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Employee Worksheet</p></a></Link></li> : ""}
                      {/* <li>
                        <Link> <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Daily Nozzle  Sales </p></a></Link>
                      </li> */}
                      {/* <li>
                        <Link > <a href="#" class="side-m"><p class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Stock Difference System</p></a></Link>
                      </li> */}
                      <li>
                        <a target="_blank" href={`${WEB_URL}/other-report/${user.user_id}/short_excess_statement`} class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Short Statement </p></a>
                      </li>
                      <li class="">
                        <a target="_blank" href={`${WEB_URL}/other-report/${user.user_id}/stock-gain-loss`} ><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Stock Gain/Loss</p></a>
                      </li>
                      <li>
                        <Link to={"/op-fuelunloading"}>  <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Unload History </p></a></Link>
                      </li>

                      {/* <li>
                        <Link > <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Employee Sales </p></a></Link>
                      </li> */}
                      {/* <li>
                        <Link >  <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Tank Wise Sales  </p></a></Link>
                      </li> */}
                      {/* <li>
                        <Link > <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Bag Collection Statement</p></a></Link>
                      </li> */}
                      <li>
                        <a target="_blank" href={`${WEB_URL}/other-report/${user.user_id}/product_purchase_statement`} class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Non-Fuel Purchase Statement </p></a>
                      </li>
                      <li>
                        <a target="_blank" href={`${WEB_URL}/other-report/${user.user_id}/fleetcard_statement`} class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Fleet Card Statement</p></a>
                      </li>
                      <li>
                        <a target="_blank" href={`${WEB_URL}/other-report/${user.user_id}/mla_coupon_statement`} class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>MLA Coupon </p></a>
                      </li>
                      {/* <li>
                        <Link >  <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Nozzle Allocation </p></a></Link>
                      </li> */}
                      {/* <li>
                        <Link > <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Recoup  </p></a></Link>
                      </li> */}
                      <li>
                        <a target="_blank" href={`${WEB_URL}/other-report/${user.user_id}/product_sales_statement`} class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Non-Fuel Sales Statement</p></a>
                      </li>
                      {/* <li>
                        <Link ><a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i> Current Stock Statement</p></a></Link>
                      </li> */}
                      {/* <li>
                        <Link > <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Critical stock Report </p></a></Link>
                      </li> */}
                      {/* <li>
                        <Link >  <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Stock Movement Report</p> </a></Link>
                      </li>
                      <li>
                        <Link >  <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Employee Performance</p></a></Link>
                      </li>
                      <li>
                        <Link > <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Creditors Above Limits</p></a></Link>
                      </li>
                      <li>
                        <Link >  <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Declining Credit Customers</p></a></Link>
                      </li>
                      <li>
                        <Link > <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Declining Get Products</p></a></Link>
                      </li>
                      <li>
                        <Link >  <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Price Change Report</p></a></Link>
                      </li> */}
                    </ul>
                    : <a onClick={() => setShow3(show3)}></a>}
                </li>

                <li class="">
                  <a onClick={toggle4} class="text-white let " href="#" ><p>
                    <span class="ms-3">
                      <i class="fa fa-angle-double-right " aria-hidden="true"></i> Financial Reports</span></p>
                  </a>

                  {show4 || progress.operation == 4 ?

                    <ul className="sidesubmenu">
                      <li>

                        <a target="_blank" href={`${WEB_URL}/other-report/${user?.user_id}/account_statements`} class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Statement of Account</p></a>
                      </li>

                      {/* <li className={progress.progress == 26 ? "active" : ""}>
                        <Link to="/op_profit_loss" > <a href="/op_profit_loss" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>P&L Report</p></a></Link>
                      </li> */}
                      <li className={progress.progress == 28 ? "active" : ""}>
                        <Link to="/op-simplified-balance-sheet" ><a href="/op-simplified-balance-sheet" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Balance Sheet</p></a></Link>
                      </li>
                      {/* <li>
                        <Link > <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i> Product Wise Profit </p></a></Link>
                      </li> */}
                      {/* <li>
                        <a class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Vendor Statement</p></a>
                      </li> */}
                      <li>
                        <a target="_blank" href={`${WEB_URL}/other-report/${user.user_id}/bank_statement`} class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Bank Statement</p></a>
                      </li>
                      <li>
                        <a target="_blank" href={`${WEB_URL}/other-report/${user.user_id}/other_income_statement`} class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Other Income Head Report</p></a>
                      </li>
                      {/* <li>
                        <Link >  <a href="#" class="side-m"><p
                          class="ms-5"> <i class="fa fa-angle-double-right " aria-hidden="true"></i>Receipt & Payment Report</p></a></Link>
                      </li> */}
                      {/* <li>
                        <Link > <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Expense Trends</p></a></Link>
                      </li> */}
                      <li>
                        <a target="_blank" href={`${WEB_URL}/other-report/${user.user_id}/dealer_statement`} class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Dealer Statement</p></a>
                      </li>
                      <li>
                        <a target="_blank" href={`${WEB_URL}/other-report/${user.user_id}/customer_statement`} class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Customer Statement</p></a>
                      </li>
                      {/* <li>
                        <Link > <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Debtors Aging Report </p></a></Link>
                      </li> */}
                      {/* <li>
                        <Link >  <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Stock Aging Report </p></a></Link>
                      </li> */}
                      {/* <li>
                        <Link ><a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Sales Trend</p></a></Link>
                      </li> */}
                      {/* <li>
                        <Link >  <a href="#" class="side-m"><p
                          class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Impact of Price Change</p></a></Link>
                      </li> */}
                    </ul>
                    : <a onClick={() => setShow4(show4)}></a>}
                </li>

                <li class=""><a onClick={toggle5} class="text-white let" href="#" ><p>
                  <span class="ms-3"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Configuration Report</span></p></a>
                  {show5 ?
                    <ul className="sidesubmenu">
                      <li>
                        <Link to={location => ({ backpage: location_details.pathname, pathname: "/config-report/config-report" })}><a href="/config-report" class="side-m">
                          <p class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Non-Fuel Configuration Report </p></a></Link>
                      </li>
                      <li>
                        <Link to={location => ({ backpage: location_details.pathname, pathname: "/cbalance-sheet/cbalance-sheet" })}><a href="/cbalance-sheet" class="side-m">
                          <p class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i> Configuration Balance Sheet</p></a></Link>
                      </li>
                      <li>
                        <Link to={location => ({ backpage: location_details.pathname, pathname: "/fuel-config-report/fuel" })}><a href="/fuel-config-report" class="side-m">
                          <p class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Fuel Configuration Report </p></a></Link>
                      </li>
                    </ul>


                    : <a onClick={() => setShow5(show5)}></a>}
                </li>

              </ul>

            }
          </li>
          : ''}
        {permissions.includes("Add Remove Parties") == true ?
          <>
            <li class=""><a onClick={toggle6} class="text-white let" href='#' > <p>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-file-earmark-spreadsheet-fill" viewBox="0 0 16 16">
                <path d="M6 12v-2h3v2H6z" />
                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2H3V9z" />
              </svg>

              <span class="ms-2">Add/Remove/Edit Account Heads</span></p></a>
              {show6 ?
                <ul className="sidesubmenu">
                  <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/customers/credit-sales" })}> <a href="#" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Customer Settings</p></a></Link>
                  </li>
                  <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/creditor-debitor/creditor-debitor" })}> <a href="#" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Creditor/ Debtor Settings</p></a></Link>
                  </li>
                  {/* <li>
                      <Link to={"/users"}>  <a href="/users" class="side-m"><p
                         class="ms-5">Employee Settings</p></a></Link>
                    </li> */}
                  <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/vendor-config/nf-purchase" })}>  <a href="#" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Vendor Settings</p></a></Link>
                  </li>
                  <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/digital-payment/digital-payment" })}><a href="/digital-payment" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Digital Payment</p></a></Link>
                  </li>
                  <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/bank-opening/bank-opening" })}><a href="/bank-opening" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Bank Settings</p></a></Link>
                  </li>
                  <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/products/products" })}><a href="/products" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Non Fuel products</p></a></Link>
                  </li>
                  <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/loose-oil-config/loose-oil-config" })}><a href="/loose-oil-config" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Loose Oil Products</p></a></Link>
                  </li>
                  <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/Productsconfig/Productsconfig" })}><a href="/Productsconfig" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Gas Cylinder Products</p></a></Link>
                  </li>
                  <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/rack-config/rack-config" })}><a href="#" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Rack</p></a></Link>
                  </li>
                  <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/fleet-card-config/fleet-card-config" })}><a href="/fleet-card-config" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Fleetcard Configuration</p></a></Link>
                  </li>
                  <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/MLA-coupon/MLA-coupon" })}> <a href="/MLA-coupon" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>MlaCoupon Configuration</p></a></Link>
                  </li>
                  <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/owntanker-config/owntanker-config" })}> <a href="/owntanker-config" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Own Tanker Configuration</p></a></Link>
                  </li>
                  <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/tanker-income/tanker-income" })}><a href="/tanker-income" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Tanker Income</p></a></Link>
                  </li>
                  <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/tanker-expense/tanker-expense" })}><a href="/tanker-expense" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Tanker Expense</p></a></Link>
                  </li>
                  {/* <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/assets/assets" })}> <a href="#" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Machineries / Assets</p></a></Link>
                  </li> */}
                  <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/other-income/other-income" })}> <a href="/other-income" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Other Income Config</p></a></Link>
                  </li>
                  <li>
                    <Link to={location => ({ backpage: location_details.pathname, pathname: "/expenses/expenses" })}> <a href="/expenses" class="side-m"><p
                      class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Expense Head</p></a></Link>
                  </li>




                </ul>
                : <a onClick={() => setShow6(show6)}></a>}
            </li>
          </>
          : ''}



        {permissions.includes("Task And Reminders") == true ?
          <li >
            <Link to={location => ({ backpage: location_details.pathname, pathname: "/task-config/task-config" })}>
              <a >
                <p>

                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-list-task" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z" />
                    <path d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z" />
                    <path fill-rule="evenodd" d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z" />
                  </svg><span class="ms-2">TASK AND REMINDERS</span></p></a></Link>
          </li>
          : ''}

        {permissions.includes("User Management") == true ?
          <li className={progress.progress == 20 ? "active" : ""}>
            <Link to={"/user-management-op"}>
              <a href="/user-management-op">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                  </svg><span class="ms-2">User Management</span>
                </p></a></Link>
          </li>
          : ''}

        {permissions.includes("Notification Settings") == true ?
          <li className={progress.progress == 23 ? "active" : ""}>
            <Link to={"/notification-setting"}>
              <a href="/notification-setting">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                  </svg><span class="ms-2">Notification Settings</span>
                </p></a></Link>
          </li>
          : ''}

        {permissions.includes("Subscription Management") == true ?
          <li className={progress.progress == 21 ? "active" : ""}>
            <Link to={"/subscription-management"}>
              <a href="/subscription-management">
                <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-fill" viewBox="0 0 16 16">
                  <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
                </svg><span class="ms-2">Subscription Management</span></p></a></Link>
          </li>
          : ''}

        <li>
          {/* 
          <a onClick={() => setShow7(!show7)} class="text-white" href="#">
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg><span class="ms-2">About Us</span></p></a> */}
          {/* {show7 ?
            <ul className="sidesubmenu">

              <li>
                <Link>
                  <a class="dropdown-toggle let"><p>
                    <span class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>About PetroSmart</span></p></a></Link>

              </li>
              <li>
                <Link>
                  <a href="#page5" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle let"><p>
                    <span class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Privacy policies</span></p></a>
                </Link>
              </li>
            </ul>
            : <a onClick={() => setShow7(show7)}></a>} */}
        </li>

      </ul>
      {/* <a href="#" class="btm-corner"><strong><i className="fa fa-question-circle-o p-2"></i>Help</strong></a> */}
    </div>
  )


}


export default Operational_Sidebar_Progress;
export const convertAmountToWords = (e, nums) => {

  var parent = e.currentTarget.parentNode
  var selector = parent.querySelector('.amount-words')
  if (typeof selector !== 'undefined' && selector) {

    var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
    var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    var myArr = nums.split(".");
    var num = myArr[0];
    var num1 = myArr[1] ? myArr[1] : '';
    if ((num = num.toString()).length > 9) return 'overflow';
    /****************************/
    var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'And ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + ' ' : '';
    // var selector = document.getElementsByClassName('amount-words')
    /************************* */
    if (num1 != '') {
      if ((num1 = num1.toString()).length > 2) return 'overflow';
    }

    /****************************/
    var p = ('000000000' + num1).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

    var str1 = '';
    if ((p) && (num1 != '')) {
      str1 += (p[5] != 0) ? ((str1 != '') ? 'And ' : '') + (a[Number(p[5])] || b[p[5][0]] + ' ' + a[p[5][1]]) + ' ' : '';
    }
    /************************* */

    if (str1 != '') {
      if (str == '') {
        str = 'Zero';
      }
      str = str + ' Rupees And ' + str1 + ' Paise ';
    }
    else if (str != '' && num != 1 && num != 0) {
      str = str + ' Rupees';
    }
    else if (num == 0 && str1 == '') {
      str = str + ' Zero';
    }
    else if (num == 1 && str1 == '') {
      str = str + ' Rupee';
    }
    selector.innerHTML = str;
  }



}

export const preventNonNumericalInput = (e, num) => {
  console.log(num)
  console.log(e)
  e = e || window.event;
  var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
  console.log(charCode)
  var charStr = String.fromCharCode(charCode);
  var fullChar = num ? num?.toString() + charStr : charStr;
  console.log(fullChar)

  if (!fullChar.match(/^[0-9]*(\.{1})?([0-91-9][0-9])?$/)) {
    e.preventDefault();
  }


  var myArr = fullChar.toString().split('.');
  var num0 = myArr[0];
  if ((num0 = num0.toString()).length > 8)
    e.preventDefault();
}

// export const prevNonNumericalWithDec = (e, num) => {

//   var parent = e.currentTarget.parentNode
//   var selector = parent.querySelector('.ph_no')
//   if (typeof selector !== 'undefined' && selector) {
//     e = e || window.event;
//     var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
//     var charStr = String.fromCharCode(charCode);
//     var fullChar = num ? num?.toString() + charStr : charStr;
//     if (!fullChar.match(/^[0-9]+$/))
//       e.preventDefault();
//   }
//   else {
//     e = e || window.event;
//     var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
//     var charStr = String.fromCharCode(charCode);
//     var fullChar = num ? num?.toString() + charStr : charStr;
//     if (!fullChar.match(/^[0-9]+$/))
//       e.preventDefault();

//     var myArr = fullChar.toString().split('.');
//     var num0 = myArr[0];
//     if ((num0 = num0.toString()).length > 8)
//       e.preventDefault();

//   }
// }
export const prevNonNumericalWithDec = (e, num) => {
  e = e || window.event;
  const charCode = typeof e.which === "undefined" ? e.keyCode : e.which;
  const charStr = String.fromCharCode(charCode);
  const fullChar = num ? num.toString() + charStr : charStr;

  // Regular expression to allow only numbers and one decimal point
  const regex = /^[0-9]*\.?[0-9]*$/;

  // If input contains more than one decimal or doesn't match the regex, prevent input
  if (!regex.test(fullChar) || (charStr === '.' && num.includes('.'))) {
    e.preventDefault();
    return;
  }

  // Prevent input if the integer part exceeds 8 characters
  const [integerPart] = fullChar.split('.');
  if (integerPart.length > 8) {
    e.preventDefault();
  }
};

export const prevDec = (e, num) => {

  e = e || window.event;
  var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
  var charStr = String.fromCharCode(charCode);
  var fullChar = num ? num?.toString() + charStr : charStr;

  if (!fullChar.match(/^[A-Za-z0-9_-]*$/)) {
    e.preventDefault();
  }

}
//var d = document.getElementsByClassName('hide-arrow')[0];
//if(typeof d !== 'undefined')
//{

//document.getElementsByClassName('hide-arrow')[0].addEventListener('keyup', function(event){

// alert(4546);

//});
//}
